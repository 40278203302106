import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementUI from "element-ui";
import qs from "qs";
import service from "./tool/api";
import {md55} from './components/mixins/md5.js'
import "element-ui/lib/theme-chalk/index.css";

import MetaInfo from "vue-meta-info";
import Print from "vue-print-nb";
import {sxAllArr} from './shengxiao'
Vue.use(Print);
Vue.use(MetaInfo);
let colorArrs = [
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
];


Vue.prototype.colorArr = colorArrs;
let pcddColors=[
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "green",
  "green",
  "red",
  "red",
  "blue",
  "blue",
  "green",
]
Vue.prototype.pcddColors=pcddColors
Vue.prototype.checkNum = function (num) {
  if (num < 10) {
    num = "0" + num;
  }
  return num;
};
Vue.use(ElementUI);
// 请求路径

axios.defaults.baseURL = service.defaults.baseURL;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded;charset=utf-8";

    config.data = qs.stringify(config.data);
    let jspanOpen = store.state.jspanOpen;
    let currentGame = store.state.currentGame;

    if(config.url=='getlotteryhis'){
      let newArr=[]
      let gametype='';
      let candata=config.data.split('&')
      candata.forEach(item=>{
       let  xarr=item.split('=')
       if(xarr[0]!='gametype'){
        newArr.push(item)
       }else{
        gametype=xarr[1]
       }
      
      })
      config.data=newArr.join('&')
      if(gametype=='wxc'){
        config.baseURL += "/wxcgame/";
      }else{
        // config.baseURL += "/user/";
        config.baseURL += "/comgame/";
      }
    
     
    }else{
      if(config.url=='chat/getchatlist'){
       
      }else{
        let urlArr=['getopencode','getgroupodds','getopentime','setneworder','ordertuima','getitemodds','getportodds','getcommonopentime','getcommonitemodds','getcommongroupodds','getcommonportodds']
        if (currentGame.Gametype == "wxc") {
        
          let index=urlArr.findIndex(ele=>ele==config.url);
          if(index==-1){
            config.baseURL += "/user/";
          }else{
            config.baseURL += "/wxcgame/";
          }
         
        } else {
          if (config.url == "setneworder") {
            if (jspanOpen) {
              // comgamejiashi
            
              config.baseURL += "/comgame/";
            } else {
              // config.baseURL += "/dlxd/";
              config.baseURL += "/comgame/";
            }
          } else {
            let index=urlArr.findIndex(ele=>ele==config.url);
            if(index==-1){
              config.baseURL += "/user/";
            }else{
              config.baseURL += "/comgame/";
            }

             
          }
        }
      }
      
 
    }
  

    return config;
  },
  (error) => {
    //  请求失败做些什么

    return Promise.reject(error);
  }
);
let that = this;
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么

    if (
      response.data.Msg == "账户未登录" ||
      response.data.Msg == "登录已过期，请重新登录" ||
      response.data.Msg == "sid不能为空"||
      response.data.Msg=='当前IP不可用!'
    ) {
      // window.location.reload();
      store.commit("setUserInfo", {});
      store.commit("resultGoBool", false);
      sessionStorage.clear();

      let endTime = window.setInterval(function () {}, 10000);
      for (let i = 1; i <= endTime; i++) {
        window.clearInterval(i);
      }
      window.location.href = "/";
    }
    return response.data;
  },
  (err) => {
    // 响应失败的时候做点什么  比如token失效？

    return Promise.reject(err);
  }
);
Vue.prototype.fulushouxi=function(num){
  let count=Number(num);
  let str='';
 let fu=[ 1,2,3,4,5,21,22,23,24,25,41,42,43,44,45,61,62,63,64,65]
 let lu=[  6,7,8,9,10,26,27,28,29,30,46,47,48,49,50,66,67,68,69,70]
 let shou=[11,12,13,14,15,31,32,33,34,35,51,52,53,54,55,71,72,73,74,75]
 let xi=[16,17,18,19,20,36,37,38,39,40,56,57,58,59,60,76,77,78,79,80]
 let index=fu.findIndex(ele=>count==Number(ele))
 if(index!=-1){
  str='福'
  return str
 }
 let index1=lu.findIndex(ele=>count==Number(ele))
 if(index1!=-1){
  str='禄'
  return str
 }
 let index2=shou.findIndex(ele=>count==Number(ele))
 if(index2!=-1){
  str='寿'
  return str
 }
 let index3=xi.findIndex(ele=>count==Number(ele))
 if(index3!=-1){
  str='喜'
  return str
 }




}
Vue.prototype.getqianwuqiusum=function(arr){
 let sum=0
 arr.forEach((qiu,index)=>{
 if(index<5){
  sum+=Number(qiu)
 }
 })
 return sum
}
Vue.prototype.fantangameopencode=function(arr,qwe){
  let sum=0;
  let obj={
    sum:0,
    dx:'',
    ds:''
  }
  // console.log(arr)
  // ------3-----5---------------
  if(arr.length==3){
    arr.forEach(ele=>{
      sum+=Number(ele)
    })
    obj.sum=sum;
    obj.ds = sum % 2 == 0 ? "双" : "单";
    if(sum<=13){
      obj.dx='小'
    }else{
      obj.dx='大'
    }
  }
  // --------5------------------------
  if(arr.length==5){
    if(qwe==4){//时时彩类型得翻滩 有的游戏算总和是前四个数字之和
      arr.forEach((ele,index)=>{
        if(index<4){
          sum+=Number(ele)
        }
       
       
    
    })
    }else{
      arr.forEach((ele,index)=>{
      
        sum+=Number(ele)
       
    
    })
    }
   
    obj.sum=sum;
    obj.ds = sum % 2 == 0 ? "双" : "单";
    if(sum<=22){
      obj.dx='小'
    }else{
      obj.dx='大'
    }
  }
  // ---------8--------------------------
  if(arr.length==8){
    arr.forEach(ele=>{
      sum+=Number(ele)
    })
    obj.sum=sum;
    let lastNum=Number(arr[arr.length-1])
    obj.ds = lastNum % 2 == 0 ? "双" : "单";
    if(lastNum>10){
      obj.dx='大'
 
    }else{
    
      obj.dx='小'
    }
    
   
  }
  // -------10--------------------------
    if(arr.length==10){
   sum=Number(arr[0])+Number(arr[1])+Number(arr[2])
    obj.sum=sum;
    obj.ds = sum % 2 == 0 ? "双" : "单";
    if(sum>=6&&sum<=16){
      obj.dx='小'
    }else{
      obj.dx='大'
    }
    }
  //  console.log(obj.sum)
  return obj
}
Vue.prototype.$http = axios;
// 判断是否是数字
Vue.prototype.isNumber = function (val) {
  var regPos = /^\d+(\.\d+)?$/; //非负浮点数
  var regNeg =
    /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  } else {
    return false;
  }
};
// 判断年肖
Vue.prototype.getNianXiao=function(cdate){
  let nianxiaoItem={}
  let teday=cdate.substr(0,8)
  let tedaynum=Number(teday)
  
  if(tedaynum<20240210){
    // 兔年
    nianxiaoItem=sxAllArr[0]
  }else if(tedaynum>=20240210&&tedaynum<20250129){
    // 龙年
    nianxiaoItem=sxAllArr[1]
  }else if(tedaynum>=20250129&&tedaynum<20260217){
    // 蛇年
    nianxiaoItem=sxAllArr[2]
  }else if(tedaynum>=20260217&&tedaynum<20270206){
      // 马年
      nianxiaoItem=sxAllArr[3]
  }else if(tedaynum>=20270206&&tedaynum<20280126){
      // 羊年
      nianxiaoItem=sxAllArr[4]
  }else if(tedaynum>=20280126&&tedaynum<20290213){
      // 猴年
      nianxiaoItem=sxAllArr[5]
  }else if(tedaynum>=20290213&&tedaynum<20300213){
      // 鸡年
      nianxiaoItem=sxAllArr[6]
  }else if(tedaynum>=20300213&&tedaynum<20310123){
      // 狗年
      nianxiaoItem=sxAllArr[7]
  }else if(tedaynum>=20310123&&tedaynum<20320211){
      // 猪年
      nianxiaoItem=sxAllArr[8]
  }else if(tedaynum>=20320211&&tedaynum<20330131){
      // 鼠年
      nianxiaoItem=sxAllArr[9]
  }
  return nianxiaoItem
}
// 单独筛选数据
Vue.prototype.ddFilterData = function (data, arr, labelArr) {
  let arr1 = [];
  data.forEach((item) => {
    let index = arr.findIndex((num) => num == item.ResultID);
    if (index >= 0) {
      let obj = Object.assign({}, item);
      if (labelArr.length > 0) {
        obj.label = labelArr[index];
      }
      obj.money = "";
      obj.b = false;
      obj.selected = false;

      arr1.push(obj);
    }
  });
  return JSON.parse(JSON.stringify(arr1));
};
// 单独筛选数据  (乱序)
Vue.prototype.ddFilterData1 = function (data, arr, labelArr) {
  let arr1 = [];
  arr.forEach((id, index) => {
    let item = data.find((item) => item.ResultID == id);
    if (item) {
      let obj = Object.assign({}, item);
      if (labelArr.length > 0) {
        obj.label = labelArr[index];
      }
      obj.money = "";
      obj.b = false;
      obj.selected = false;

      arr1.push(obj);
    }
  });
  return JSON.parse(JSON.stringify(arr1));
};
// 筛选数据
Vue.prototype.filterData = function (data, min, max, labelArr) {
  let arr = data.filter((item) => item.ResultID >= min && item.ResultID <= max);
  arr.forEach((element, index) => {
    element.label = labelArr[index];
    element.b = false;
    element.money = "";
    element.selected = false;

    // console.log(element.recordOdds, element.Odds, "赔率没了");
  });
  return JSON.parse(JSON.stringify(arr));
};
// 获取时间
Vue.prototype.getBeforeDate = function getBeforeDate(n, time, timeFixed) {
  var n = n;
  var d = new Date();
  var year = d.getFullYear();
  var mon = d.getMonth() + 1;
  var day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    } else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  let s =
    year +
    "-" +
    (mon < 10 ? "0" + mon : mon) +
    "-" +
    (day < 10 ? "0" + day : day);
  if (time) {
    let timeStr = "";
    if (timeFixed) {
      timeStr = timeFixed;
    } else {
      let hour = d.getHours();
      let min = d.getMinutes();
      let sec = d.getSeconds();
      timeStr =
        " " +
        (hour < 10 ? "0" + hour : hour) +
        ":" +
        (min < 10 ? "0" + min : min) +
        ":" +
        (sec < 10 ? "0" + sec : sec);
    }

    s = s + timeStr;
  }

  return s;
};
//转成提交格式的日期
Vue.prototype.splitDate = (time) => {
  let arr = time.split("-");
  let str = "";
  arr.forEach((element) => {
    str += element;
  });
  return str;
};
Vue.filter('hideaccount',function hideaccount(str){
  let hidestr=''
  if(str.length<3){
    hidestr='***'+str[str.length-1]
  }else{
    hidestr='***'+str.slice(-3)
  }
  return hidestr
})
// 分割成可以显示的日期
Vue.filter("substrSome", function substrSome(str) {
  str = str + "";
  if (str > 0) {
    if (str.length > 8) {
      str = str + "";
      let year = str.substr(0, 4);
      let month = str.substr(4, 2);
      let day = str.substr(6, 2);
      let hour = str.substr(8, 2);
      let min = str.substr(10, 2);
      let ss = str.substr(12, 2);
      return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + ss;
    } else {
      str = str + "";
      let year = str.substr(0, 4);
      let month = str.substr(4, 2);
      let day = str.substr(6, 2);

      return year + "-" + month + "-" + day;
    }
  }
});
Vue.filter("substrSome1", function substrSome1(str) {
  str = str + "";
  if (str > 0) {
    if (str.length > 8) {
      str = str + "";
      let year = str.substr(0, 4);
      let month = str.substr(4, 2);
      let day = str.substr(6, 2);
      let hour = str.substr(8, 2);
      let min = str.substr(10, 2);
      let ss = str.substr(12, 2);
      return  hour + ":" + min + ":" + ss;
    } else {
      str = str + "";
      let year = str.substr(0, 4);
      let month = str.substr(4, 2);
      let day = str.substr(6, 2);

      return year + "-" + month + "-" + day;
    }
  }
});
Vue.prototype.substrSome = function (str) {
  str = str + "";
  if (str > 0) {
    if (str.length > 8) {
      str = str + "";
      let year = str.substr(0, 4);
      let month = str.substr(4, 2);
      let day = str.substr(6, 2);
      let hour = str.substr(8, 2);
      let min = str.substr(10, 2);
      let ss = str.substr(12, 2);
      return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + ss;
    } else {
      str = str + "";
      let year = str.substr(0, 4);
      let month = str.substr(4, 2);
      let day = str.substr(6, 2);

      return year + "-" + month + "-" + day;
    }
  }
};
// -------------------------------------------------------------------------------
//开奖结果 判断大小单双。。。。
// 判断七色波
function operationColor(arr) {
  let result = "";
  let colorArr = [];
  let objArr = [
    {
      label: "蓝",
      count: 0,
    },
    {
      label: "绿",
      count: 0,
    },
    {
      label: "红",
      count: 0,
    },
  ];
  let copyArr = JSON.parse(JSON.stringify(arr));
  copyArr.forEach((item) => {
    colorArr.push(colorArrs[Number(item) - 1]);
  });
  // 特码颜色
  let temaColor = colorArr.splice(6, 1);
  // 判断有没有可能为和
  let colorItem = colorArr.find((c) => c == temaColor[0]);
  if (colorItem) {
    // 不会存在和的情况
    let mergeArr = colorArr.concat(temaColor);
    mergeArr.forEach((co, index) => {
      if (index < 6) {
        if (co == "blue") {
          objArr[0].count++;
        } else if (co == "red") {
          objArr[2].count++;
        } else if (co == "green") {
          objArr[1].count++;
        }
      } else {
        if (co == "blue") {
          objArr[0].count += 1.5;
        } else if (co == "red") {
          objArr[2].count += 1.5;
        } else if (co == "green") {
          objArr[1].count += 1.5;
        }
      }
    });
    objArr.sort((a, b) => {
      return b.count - a.count;
    });

    result = objArr[0].label;
  } else {
    // 有可能存在和的情况
    colorArr.forEach((co) => {
      if (co == "blue") {
        objArr[0].count++;
      } else if (co == "red") {
        objArr[2].count++;
      } else if (co == "green") {
        objArr[1].count++;
      }
    });
    if (temaColor[0] == "blue") {
      objArr[0].count += 1.5;
    } else if (temaColor[0] == "red") {
      objArr[2].count += 1.5;
    } else if (temaColor[0] == "green") {
      objArr[1].count += 1.5;
    }
    let resArr = [];
    objArr.forEach((item) => {
      resArr.push(item.count);
    });
    let setArr = new Set(resArr);

    if ([...setArr].length == 2) {
      result = "和";
    } else {
      objArr.sort((a, b) => {
        return b.count - a.count;
      });
      result = objArr[0].label;
    }
  }

  // console.log(colorArr, "颜色数组，，，");

  return result;
}

// 六合彩
Vue.prototype.lhclutu = function (arr) {
  let shengxiao = [];
  arr.forEach((numstr) => {
    let item = this.sxArr.find((sxEle) => sxEle.ball == numstr);
    if (item) {
      shengxiao.push(item.shengxiao);
    }
  });
  let shengxiaoArr = Array.from(new Set(shengxiao));
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);
  // 单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  //  大小
  let dx = sum > 174 ? "大" : "小";
  newArr.push(dx);
  // 七色波
  let qisebo = operationColor(arr);

  newArr.push(qisebo);
  // 总肖
  let zongxiao = shengxiaoArr.length + "肖";
  newArr.push(zongxiao);
  // 特码单双
  let tmds = null;
  if (arr[arr.length - 1] == 49) {
    tmds = "和";
  } else {
    tmds = arr[arr.length - 1] % 2 == 0 ? "双" : "单";
  }
  newArr.push(tmds);
  // 特码大小
  let tmdx = "";
  if (arr[arr.length - 1] == 49) {
    tmdx = "和";
  } else if (arr[arr.length - 1] > 24) {
    tmdx = "大";
  } else {
    tmdx = "小";
  }
  newArr.push(tmdx);
  // 合单双
  let tmhds = "";
  let heshu = arr[arr.length - 1];
  let hssum = 0;
  if (heshu < 10) {
    hssum = heshu;
  } else {
    heshu = heshu + "";
    hssum = Number(heshu[0]) + Number(heshu[1]);
  }
  if (arr[arr.length - 1] == 49) {
    tmhds = "和";
  } else if (hssum % 2 == 0) {
    tmhds = "双";
  } else {
    tmhds = "单";
  }
  newArr.push(tmhds);
  // 特码合大小
  let tmhdx = "";
  if (heshu == 49) {
    tmhdx = "和";
  } else if (hssum > 6) {
    tmhdx = "大";
  } else {
    tmhdx = "小";
  }
  newArr.push(tmhdx);
  // 大小尾
  let tmdxw = "";
  if (arr[arr.length - 1] == 49) {
    tmdxw = "和";
  } else {
    let tmws =
      Number(arr[arr.length - 1]) > 9
        ? Number(arr[arr.length - 1]) + ""
        : "0" + Number(arr[arr.length - 1]);
  //  console.log(tmws,633)
    if (
      tmws[1] == 5 ||
      tmws[1] == 6 ||
      tmws[1] == 7 ||
      tmws[1] == 8 ||
      tmws[1] == 9
    ) {
      tmdxw = "大";
    } else {
      tmdxw = "小";
    }
  }

  newArr.push(tmdxw);

  return newArr;
};
let zhishu = [1, 2, 3, 5, 7];

// 排列3
Vue.prototype.pl3lutu = function (arr) {
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);
  //  跨度
  let kdArr = JSON.parse(JSON.stringify(arr));
  kdArr.sort((a, b) => {
    return b - a;
  });
  let kuadu = kdArr[0] - kdArr[2];
  newArr.push(kuadu);
  // 佰大小
  let bdx = arr[0] > 4 ? "大" : "小";
  newArr.push(bdx);
  // 佰单双
  let bds = arr[0] % 2 == 0 ? "双" : "单";
  newArr.push(bds);
  // 佰质合
  let bzh = zhishu.find((zs) => zs == arr[0]) ? "质" : "合";
  newArr.push(bzh);
  // 十大小
  let sdx = arr[1] > 4 ? "大" : "小";
  newArr.push(sdx);
  // 十单双
  let sds = arr[1] % 2 == 0 ? "双" : "单";
  newArr.push(sds);
  // 十质合
  let szh = zhishu.find((zs) => zs == arr[1]) ? "质" : "合";
  newArr.push(szh);
  // 个大小
  let gdx = arr[2] > 4 ? "大" : "小";
  newArr.push(gdx);
  // 个单双
  let gds = arr[2] % 2 == 0 ? "双" : "单";
  newArr.push(gds);
  // 个质合
  let gzh = zhishu.find((zs) => zs == arr[2]) ? "质" : "合";
  newArr.push(gzh);

  return newArr;
};
// 快乐十分
Vue.prototype.klsflutu = function (arr) {
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);
  // 单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  // 大小
  let dx = "";
  if (sum > 84) {
    dx = "大";
  } else if (sum == 84) {
    dx = "和";
  } else {
    dx = "小";
  }
  newArr.push(dx);

  return newArr;
};
// 宾果彩 录图
Vue.prototype.bgclutu = function (arr) {
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);
  // 单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  // 大小
  let dx = sum >= 203 ? "大" : "小";
  newArr.push(dx);
  let longhu = arr[0] - arr[arr.length - 1] > 0 ? "龙" : "虎";
  newArr.push(longhu);
  return newArr;
};
// 快3
Vue.prototype.k3lutu = function (arr) {
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);

  // 大小
  let dx = "";
  if (sum <= 10) {
    dx = "小";
  } else {
    dx = "大";
  }
  newArr.push(dx);
  // 单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  return newArr;
};
// 11x5
Vue.prototype.syx5lutu = function (arr) {
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);
  // 单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  // 大小
  let dx = "";
  if (sum > 30) {
    dx = "大";
  } else if (sum == 30) {
    dx = "和";
  } else {
    dx = "小";
  }
  newArr.push(dx);
  //  龙虎
  let longhu = arr[0] - arr[arr.length - 1] > 0 ? "龙" : "虎";
  newArr.push(longhu);
  // 1-5大小
  arr.forEach((ele) => {
    let str = "";
    if (ele == 11) {
      str = "和";
    } else if (ele > 5) {
      str = "大";
    } else {
      str = "小";
    }
    newArr.push(str);
  });

  return newArr;
};
// pk10
Vue.prototype.pk10lutu = function (arr) {
  let copyArr = JSON.parse(JSON.stringify(arr));
  let newArr = [];
  // 总和
  let sum = Number(copyArr[0]) + Number(copyArr[1]);
  newArr.push(sum);
  // 总和单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  // 冠亚大小
  let dx = sum > 11 ? "大" : "小";
  newArr.push(dx);
  //
  let arr1 = copyArr.splice(0, 5);
  copyArr.reverse();
  arr1.forEach((ele, index) => {
    let str = ele - copyArr[index] > 0 ? "龙" : "虎";
    newArr.push(str);
  });

  return newArr;
};
// ssc
Vue.prototype.ssclutu = function (arr) {
  let newArr = [];
  // 总和
  let sum = 0;
  arr.forEach((ele) => {
    sum += Number(ele);
  });
  newArr.push(sum);
  // 大小
  let dx = sum >= 23 ? "大" : "小";
  newArr.push(dx);
  // 单双
  let ds = sum % 2 == 0 ? "双" : "单";
  newArr.push(ds);
  //  龙虎
  let longhuNum = Number(arr[0]) - Number(arr[arr.length - 1]);
  let longhu = "";
  if (longhuNum == 0) {
    longhu = "和";
  } else if (longhuNum > 0) {
    longhu = "龙";
  } else {
    longhu = "虎";
  }
  newArr.push(longhu);
  // 前三
  let qiansan = [arr[0], arr[1], arr[2]];
  let qsResult = computedThreeBall(qiansan);
  newArr.push(qsResult);
  // 中三
  let zhongsan = [arr[1], arr[2], arr[3]];
  let zsResult = computedThreeBall(zhongsan);
  newArr.push(zsResult);
  // 后三
  let housan = [arr[2], arr[3], arr[4]];
  let hsResult = computedThreeBall(housan);
  newArr.push(hsResult);

  return newArr;
};
// 判断 豹子 顺子 对子 半顺 杂六
function computedThreeBall(sanArr) {
  let num1 = sanArr[0];
  let num2 = sanArr[1];
  let num3 = sanArr[2];
  let result = "杂六";
  // 豹子
  if (num1 == num2 && num2 == num3) {
    result = "豹子";
    return result;
  }
  // 对子
  let arr2 = JSON.parse(JSON.stringify(sanArr));
  let qcArr = [...new Set(arr2)];

  if (qcArr.length == 2) {
    result = "对子";
    return result;
  }
  // 顺子
  let arr3 = JSON.parse(JSON.stringify(sanArr));
  arr3.sort((a, b) => {
    return a - b;
  });
  if (arr3[2] - arr3[1] == 1 && arr3[1] - arr3[0] == 1) {
    result = "顺子";
    return result;
  } else if (arr3[0] == 0 && arr3[1] == 8 && arr3[2] == 9) {
    result = "顺子";
    return result;
  } else if (arr3[0] == 0 && arr3[1] == 1 && arr3[2] == 9) {
    result = "顺子";
    return result;
  }
  // 半顺
  if (
    arr3[2] - arr3[1] == 1 ||
    arr3[1] - arr3[0] == 1 ||
    arr3[2] - arr3[0] == 9
  ) {
    result = "半顺";
    return result;
  }

  return result;
}

// -------------------------------------------------------------------------------
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
