<template>
  <!-- #dfb55e     #9be1db-->
  <div class="home" id="home" :class="skin_home_class">
    <!-- <button @click="onsuofang">缩放</button> -->
    <!-- <img src="../assets/huixing.jpg" v-if="companyConfig.pcgametype==2" class="huixingLogo" alt=""> -->
    <div class="header">
      <!-- 顶部header -->
      <div class="top" v-if="hideHeader">
        <div class="logo">
          <h1 v-if="userInfo.userbrand" class="brand_title">
            {{ userInfo.userbrand }}
          </h1>
          <img v-else :src="companyConfig.qiantailogo" alt="" />
        </div>

        <div class="open-result" v-if="!currentGame.Gametype"></div>
        <!-- 宾果彩 -->
        <div class="open-result binggo" v-if="currentGame.Gametype == 'bingo'">
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <template v-for="(n, index) in kjObj.Opencode">
                  <p
                    :class="'bgcball b' + (n > 20 ? 'max' : n)"
                    v-if="index < 5"
                    :key="n + index"
                  >
                    {{ n }}
                  </p>
                </template>
              </li>
            </ul>

            <ul>
              <li class="balls_bottom">
                <p class="8"></p>
              </li>
            </ul>
          </div>
        </div>
        <!-- pc蛋蛋 -->
        <div
          class="open-result type-f3d pcddopen"
          v-if="currentGame.Gametype == 'pcdd'"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <template v-for="(n, index) in kjObj.Opencode">
                  <p
                    :class="'ball b' + Number(index)"
                    v-if="index < kjObj.Opencode.length - 1"
                    :key="n + index"
                  >{{ n }}</p>
                  <p
                    :class="pcddColors[Number(n)]+'ball1'"
                    v-if="index == kjObj.Opencode.length - 1"
                    :key="n + index" style="margin-left:10px"
                  >{{n}}</p>
                </template>
              </li>
            </ul>

            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p class="" v-for="item in kjObj.lutuArr" :key="item">
                  {{ item }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!-- 排列3 -->
        <div
          class="open-result type-f3d pcddopen"
          v-if="currentGame.Gametype == 'pl3'"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul   v-show="kjObj.settlestate != 0">
              <li>
                <template v-for="(n, index) in kjObj.Opencode">
                  <p
                    :class="'ball b' + Number(index)"
                    v-if="index < kjObj.Opencode.length - 1"
                    :key="n + index"
                  >{{ n }}</p>
                  <p
                    :class="pcddColors[Number(n)]+'ball1'"
                    v-if="index == kjObj.Opencode.length - 1"
                    :key="n + index" style="margin-left:10px"
                  >{{n}}</p>
                </template>
              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0" style="margin-left: 5px">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- 80x20  显示20球 -->
        <div class="open-result type-kl8" v-if="currentGame.Gametype == '80x20'&&lotActive!='1'">
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <template v-for="(n, i) in kjObj.Opencode">
                  <p
                    style="margin: 1px 2px"
                    :class="n > 40 ? 'senseball' : 'greyball'"
                    v-if="i < 20"
                    :key="n + i"
                  >
                    {{ n }}
                  </p>
                </template>
              </li>
            </ul>
            <!-- <ul>
              <li class="balls_bottom">
                <p class="8"></p>

              </li>
            </ul> -->
          </div>
        </div>
         <!-- 80x20  显示前5球 -->
         <div class="open-result type-sxg lgx5" v-if="currentGame.Gametype == '80x20'&&lotActive=='1'">
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls" style="width:250px">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li class="aili">
                <template v-for="(n, i) in kjObj.Opencode">
                  <p
                    style="margin: 1px 2px"
                    :class="n > 40 ? 'senseball' : 'greyball'"
                    v-if="i < 5"
                    :key="n + i"
                  >
                    {{ n }}
                  </p>
                    
                   
                </template>
                <p style="margin: 1px 2px" class="zonghehu">{{ getqianwuqiusum(kjObj.Opencode) }}</p>
                <p style="margin: 1px 2px" class="zonghehu">{{ Number(kjObj.Opencode[0])>Number(kjObj.Opencode[4])?'龙':'虎' }}</p>

              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom sda">
                <template v-for="(n, i) in kjObj.Opencode">
                <p  v-if="i < 5"  :key="n + i+'dsa'" style="margin: 1px 2px">{{ fulushouxi(n) }}</p>
                   </template>
                 <p>总和</p>
                 <p>龙虎</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- 20x8 -->
        <div
          class="open-result type-ssg spklsf"
          v-if="
            currentGame.Gametype == '20x8' &&
            currentGame.clientshowtype == '20x8'
          "
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <p
                  :class="'blueball b' + n"
                  class="no10"
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                ></p>
              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- 农场 -->
        <div
          class="open-result type-ssg lgklsf"
          v-if="
            currentGame.Gametype == '20x8' &&
            currentGame.clientshowtype == 'xync'
          "
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_ccq">
                <p
                  :class="'b' + n"
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                ></p>
              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="open-result type-lhc lhc"
          v-if="currentGame.Gametype == 'hk6'"
        >
          <div class="name">
            <!-- &&currentGame.frequency=='day'        v-if="ISOPEN"-->
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>

            <ul v-show="kjObj.settlestate != 0">
              <template v-if="kjObj.Opencode.length > 0">
                <template v-for="(n, index) in kjObj.Opencode">
                  <li
                    v-if="index <= 5 && kjObj.Opencode.length > 0"
                    :key="index + n"
                  >
                    <p :class="'greenball b' + n">n</p>
                    <span>{{ kjObj.lutuArr[index] }}</span>
                  </li>
                </template>
              </template>
              <li class="ballsjia" v-if="kjObj.Opencode.length > 0">
                <p>+</p>
                <span>&nbsp;&nbsp;</span>
              </li>
              <li v-if="kjObj.Opencode.length > 0">
                <p :class="'greenball b' + kjObj.Opencode[6]">
                  {{ kjObj.Opencode[6] }}
                </p>
                <span>{{ kjObj.lutuArr[6] }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- kuai3 -->
        <div
          class="open-result type-s3s spk3"
          v-if="currentGame.Gametype == 'k3'"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <p
                  :class="'numball b' + n"
                  class="no10"
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                ></p>
              </li>
            </ul>
            <ul>
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!--  pk10 -->
        <div
          class="open-result type-pks stx"
          v-if="currentGame.Gametype == 'pk10'"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>

          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_pk">
                <p
                  :class="'b' + n"
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                ></p>
              </li>
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 欢乐生肖 -->
        <div
          class="open-result type-ssc yz5f"
          v-if="
            currentGame.Gametype == 'ssc' &&
            currentGame.clientshowtype == 'shengxiao'
          "
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}-期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <p
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                  :class="'blueball b' + n"
                ></p>
              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!--  ssc  -->
        <div
          class="open-result type-ssc stx"
          v-if="
            currentGame.Gametype == 'ssc' && currentGame.clientshowtype == 'ssc'
          "
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul style="margin-left: 30px" v-show="kjObj.settlestate != 0">
              <li>
                <p
                  v-for="(n, i) in kjObj.Opencode"
                  class="no10"
                  :key="n + i"
                  :class="'blueball b' + n"
                ></p>
              </li>
            </ul>
            <ul style="margin-left: 30px" v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!--  fantan  -->
        <div
          class="open-result"
          :class="fantanclass[kjObj.Opencode.length]"
          v-if="currentGame.Gametype == 'fantan'"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <!-- 没有球 -->
          <div class="balls" v-if="kjObj.Opencode.length == 0">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
          </div>
          <!-- 3球 -->
          <div class="balls" v-if="kjObj.Opencode.length == 3">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <template v-for="(n, index) in kjObj.Opencode">
                  <p :class="'ball b' + Number(n)" :key="n + index"></p>
                </template>
              </li>
            </ul>

            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p class="" v-for="item in kjObj.lutuArr" :key="item">
                  {{ item }}
                </p>
              </li>
            </ul>
          </div>
          <!-- 5球 -->
          <div class="balls" v-if="kjObj.Opencode.length == 5">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 10px">
              正在获取开奖号码
            </h2>

            <ul style="margin-left: 10px" v-show="kjObj.settlestate != 0">
              <li>
                <template v-for="(n, i) in kjObj.Opencode">
                  <!-- huaxian_num     v-if="showorhidenum5(i)" -->
                <p
                 
                  class="no10"
                  :key="n + i"
                  :class="'blueball b' + n+' '+showhuanxian(i)"
                ></p>
              </template>
              </li>
            </ul>
            <ul style="margin-left: 10px" v-show="kjObj.settlestate != 0">
              <li class="balls_bottom" style="justify-content: center">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
          <!-- 8球 -->
          <div class="balls" v-if="kjObj.Opencode.length == 8">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <p
                  :class="'blueball b' + n"
                  class="no10"
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                ></p>
              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
          <!-- 10球 -->
          <div class="balls" v-if="kjObj.Opencode.length == 10">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_pk">
                <p
                  :class="'b' + n+' '+showhuanxian(i)"
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                ></p>
              </li>
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
          <h1
            v-show="kjObj.settlestate != 0"
            class="fan"
            :class="'color' + kjObj.fan"
          >
            <span class="shuzi">{{ kjObj.fan }}</span
            >番
          </h1>
        </div>

        <!--  wxc  -->
        <div
          class="open-result type-ssc stx"
          v-if="currentGame.Gametype == 'wxc'"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul v-show="kjObj.settlestate != 0">
              <li>
                <p
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                  class="no10"
                  :class="'blueball b' + n"
                ></p>
              </li>
            </ul>
            <ul v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- 11x5 -->
        <div
          v-if="currentGame.Gametype == '11x5'"
          class="open-result type-sxg lgx5"
        >
          <div class="name">
            <p>
              {{ currentGame.Gamename }}
              <span class="orange">{{ jiesuanMeiju[kjObj.settlestate] }}</span>
            </p>
            <p>第{{ kjObj.Expect }}期开奖</p>
          </div>
          <div class="balls">
            <h2 v-show="kjObj.settlestate == 0" style="margin-left: 30px">
              正在获取开奖号码
            </h2>
            <ul style="margin-left: 30px" v-show="kjObj.settlestate != 0">
              <li>
                <p
                  v-for="(n, i) in kjObj.Opencode"
                  :key="n + i"
                  :class="'blueball b' + n"
                ></p>
              </li>
            </ul>
            <ul style="margin-left: 30px" v-show="kjObj.settlestate != 0">
              <li class="balls_bottom">
                <p v-for="(lt, i) in kjObj.lutuArr" :key="lt + i">{{ lt }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- ---- -->
        <div class="ron">
          <div class="smenu">
            <div
              class="v"
              v-if="companyConfig.pcgametype == 1"
              :class="dingdang ? 'open' : 'close'"
              @click="xiugaidingdang"
            ></div>
            <!-- /baobiaochaxun -->
            <router-link to="/wjmx" href="javascript:" class="">
              未结明细
            </router-link>
            <router-link to="/jryj" href="javascript:" class="">
              今天已结
            </router-link>
            <router-link to="/baobiaochaxun" href="javascript:" class="">
              报表查询
            </router-link>
            <router-link to="/kaijiangjieguo" href="javascript:" class="nbor">
              开奖结果
            </router-link>
            <router-link to="/grzx" href="javascript:" class="">
              个人资讯
            </router-link>
            <router-link to="/xgmm" href="javascript:" class="">
              修改密码
            </router-link>
            <router-link to="/gamerule" href="javascript:" class="">
              游戏规则
            </router-link>
            <a href="javscript:;" @click="logout1">退出系统</a>
          </div>
          <div class="skin-voice" v-if="companyConfig.pcgametype == 2">
            <div class="s">
              <span
                class="brown"
                :class="skin_home_class == 'skin-brown' ? 'selected' : ''"
                @click="changeSkin('brown')"
              ></span>
              <span
                class="blue"
                :class="skin_home_class == 'skin-blue' ? 'selected' : ''"
                @click="changeSkin('blue')"
              ></span>
              <span
                class="red"
                :class="skin_home_class == 'skin-red' ? 'selected' : ''"
                @click="changeSkin('red')"
              ></span>
            </div>
            <div
              class="v"
              :class="dingdang ? 'open' : 'close'"
              @click="xiugaidingdang"
            ></div>
          </div>
          <div class="logout" @click="logout">退出</div>
        </div>
      </div>
      <!-- 菜单栏 -->
      <!-- v-if="gameData.length > 1||navData.length > 1" -->
      <div class="menu" ref="menu" >
        <div class="draw" @click="hideHeader = !hideHeader">
          <img
            v-if="hideHeader"
            src="../assets/hide.png"
            class="jt_img"
            alt=""
          />
          <img
            v-if="!hideHeader"
            src="../assets/show.png"
            class="jt_img"
            alt=""
          />
        </div>
        <div class="nav-os" ref="nav-os">
          <!-- 菜单栏 -->
          <ul class="nav">
            <li
              v-for="item in navData"
              :key="item.Cato"
              class="li"
              :class="{
                selected: currentGame.Cato == item.Cato,
                hot: item.IsHot == 1,
              }"
            >
              <a @click="setTabActive(item)"> {{ item.Gamename }} </a
              >
              <!-- <b @click.stop="cutNav(item)" v-if="item.b"></b> -->
            </li>
          </ul>
          <!-- 更多 -->
          <div class="more" v-if="routerData.length > 0">
            <div
              class="name"
              @click.stop="isMore = !isMore"
              :class="moreCurrentGame.Cato ? 'selected' : ''"
            >
              {{ moreCurrentGame.Cato ? moreCurrentGame.Gamename : "更多游戏" }}
            </div>
            <div class="mcon" v-if="isMore">
              <div class="mconbg"></div>
              <ul class="mnav scroll">
                <!-- ------- -->
                <!-- <draggable v-model="routerData" :options="{handle:'.handle'}">
      
         <li   style="display: inline-block;"
                  class="li moresel handle"
                  v-for="item in routerData"
                  :key="item.Cato"
                >
                  <router-link
                    :to="item.path"
                    @click.native="setMoreActive(item)"
                    >{{ item.Gamename }}</router-link
                  >
                  <b @click.stop="addNav(item)" v-if="item.b"></b>
                </li>
    </draggable> -->


                <!-- ------ -->
                <li 
                  class="li moresel"
                  v-for="item in routerData"
                  :key="item.Cato"
                >
                  <router-link
                    :to="item.path"
                    @click.native="setMoreActive(item)"
                    >{{ item.Gamename }}</router-link
                  >
                  <!-- <b @click.stop="addNav(item)" v-if="item.b"></b> -->
                </li>
              </ul>
              <!-- <div class="edit">
            
                <p style="margin-bottom: 3px">注：可将彩种从导航添加/删除。</p>
                <div style="position: relative; right: 10px">
                  <span v-if="isEdit" @click.stop="saveRouter">确定</span>
                  <span @click.stop="editOn" v-if="!isEdit">编辑</span>
                  <span @click.stop="cancelEdit" v-if="isEdit">取消</span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="setting" @click="set_b=!set_b"> <img width="17" height="17" src="../assets/setting.png" alt=""> 设置 </div>
        </div>
      </div>
      <div class="subnav">
        <div class="region">
          <template v-if="panArr.length > 1">
            <span
              :class="pan == item ? 'selected' : ''"
              v-for="item in panArr"
              :key="item"
              @click="changeRegion(item)"
              >{{ item }}</span
            >
          </template>
        </div>
        <!-- shijiebei -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'sjb'">
          <span>中国VS日本</span>|
          <span>蒙升到VS撒打</span>
        </div>

        <!-- lhc -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'hk6'">
          <router-link
            tag="span"
            to="/tema"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >特码</router-link
          >
          |
          <router-link
            tag="span"
            to="/liangmian"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >两面</router-link
          >
          |
          <router-link
            tag="span"
            to="/sebo"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >色波</router-link
          >|
          <router-link
            tag="span"
            to="/texiao"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >特肖</router-link
          >|
          <router-link
            tag="span"
            to="/hexiao"
            :class="lotActive == '5' ? 'selected' : ''"
            @click.native="changeLotActive('5')"
            >合肖</router-link
          >|
          <router-link
            tag="span"
            to="/tetouwei"
            :class="lotActive == '6' ? 'selected' : ''"
            @click.native="changeLotActive('6')"
            >特头尾</router-link
          >|
          <router-link
            tag="span"
            :to="{ path: '/zhengma', query: { name: '正码' } }"
            :class="lotActive == '7' ? 'selected' : ''"
            @click.native="changeLotActive('7')"
            >正码</router-link
          >
          |
          <router-link
            tag="span"
            :to="{ path: '/zhengmate', query: { name: '正码特' } }"
            :class="lotActive == '8' ? 'selected' : ''"
            @click.native="changeLotActive('8')"
            >正码特</router-link
          >|
          <router-link
            tag="span"
            :to="{ path: '/zhengma16', query: { name: '' } }"
            :class="lotActive == '9' ? 'selected' : ''"
            @click.native="changeLotActive('9')"
            >正码1-6</router-link
          >|
          <router-link
            tag="span"
            :to="{ path: '/zixuanbuzhong', query: { name: '' } }"
            :class="lotActive == '10' ? 'selected' : ''"
            @click.native="changeLotActive('10')"
            >自选不中</router-link
          >|
          <router-link
            tag="span"
            :to="{ path: '/lianxiaolianwei', query: { name: '' } }"
            :class="lotActive == '11' ? 'selected' : ''"
            @click.native="changeLotActive('11')"
            >连肖连尾</router-link
          >|
          <router-link
            tag="span"
            :to="{ path: '/lianma', query: { name: '' } }"
            :class="lotActive == '12' ? 'selected' : ''"
            @click.native="changeLotActive('12')"
            >连码</router-link
          >|
          <!-- yixiaoweishu -->
          <router-link
            tag="span"
            :to="{ path: '/yixiaoweishu', query: { name: '' } }"
            :class="lotActive == '13' ? 'selected' : ''"
            @click.native="changeLotActive('13')"
            >一肖尾数</router-link
          >|
          <router-link
            tag="span"
            :to="{ path: '/zhengxiao', query: { name: '' } }"
            :class="lotActive == '14' ? 'selected' : ''"
            @click.native="changeLotActive('14')"
            >正肖总肖</router-link
          >
        </div>
        <!-- 五星彩 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'wxc'">
          <router-link
            tag="span"
            to="/yiziding"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >一字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/erziding"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >二字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/wuweierding"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >五位二定</router-link
          >
          |
          <router-link
            tag="span"
            to="/erzidingkx"
            :class="lotActive == '12' ? 'selected' : ''"
            @click.native="changeLotActive('12')"
            >二字定快选</router-link
          >
          |

          <router-link
            tag="span"
            to="/sanziding"
            :class="lotActive == '13' ? 'selected' : ''"
            @click.native="changeLotActive('13')"
            >三字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/siziding"
            :class="lotActive == '14' ? 'selected' : ''"
            @click.native="changeLotActive('14')"
            >四字定</router-link
          >
          |

          <router-link
            tag="span"
            to="/erzixian"
            :class="lotActive == '9' ? 'selected' : ''"
            @click.native="changeLotActive('9')"
            >二字现</router-link
          >
          |
          <router-link
            tag="span"
            to="/sanzixian"
            :class="lotActive == '10' ? 'selected' : ''"
            @click.native="changeLotActive('10')"
            >三字现</router-link
          >
          |
          <router-link
            tag="span"
            to="/sizixian"
            :class="lotActive == '11' ? 'selected' : ''"
            @click.native="changeLotActive('11')"
            >四字现</router-link
          >
          |
          <router-link
            tag="span"
            to="/kuaida"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >快打</router-link
          >
          |

          <router-link
            tag="span"
            to="/kuaiyi"
            :class="lotActive == '6' ? 'selected' : ''"
            @click.native="changeLotActive('6')"
            >快译</router-link
          >
          |
          <router-link
            tag="span"
            to="/plbdb"
            :class="lotActive == '7' ? 'selected' : ''"
            @click.native="changeLotActive('7')"
            >赔率变动表</router-link
          >
          |
          <router-link
            tag="span"
            to="/txtdr"
            :class="lotActive == '8' ? 'selected' : ''"
            @click.native="changeLotActive('8')"
            >txt导入</router-link
          >
          |
          <router-link
            tag="span"
            to="/qzsm"
            :class="lotActive == '15' ? 'selected' : ''"
            @click.native="changeLotActive('15')"
            >全载说明</router-link
          >
        </div>
        <!-- ssc -->
        <div
          class="lotsnav"
          v-if="
            currentGame.Gametype == 'ssc' && currentGame.clientshowtype == 'ssc'
          "
        >
          <router-link
            tag="span"
            to="/zhenghe"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >整合</router-link
          >
          |
          <router-link
            tag="span"
            to="/longhu"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >龙虎</router-link
          >
          |
          <router-link
            tag="span"
            to="/qita"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >其他</router-link
          >
        </div>
        <!-- 番摊 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'fantan'">
          <router-link
            tag="span"
            to="/fantanzsp"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >主势盘</router-link
          >
          <router-link
          v-if="currentGame.roomeng=='a8tefantan'"
            tag="span"
            to="/fantantema"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >特码</router-link
          >
          <router-link
            tag="span"
            to="/chatgroup"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >聊天室</router-link
          >
        </div>
        <!-- 排列5 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'pl5'">
          <router-link
            tag="span"
            to="/zhenghe"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >整合</router-link
          >
          |
          <router-link
            tag="span"
            to="/longhu"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >龙虎</router-link
          >
          |
          <router-link
            tag="span"
            to="/qita"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >其他</router-link
          >
        </div>
        <!-- ssc 生肖 -->
        <div
          class="lotsnav"
          v-if="
            currentGame.Gametype == 'ssc' &&
            currentGame.clientshowtype == 'shengxiao'
          "
        >
          <router-link
            tag="span"
            to="/zhengheA"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >整合</router-link
          >
          |
          <router-link
            tag="span"
            to="/longhu"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >龙虎</router-link
          >
          |
          <router-link
            tag="span"
            to="/qita"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >其他</router-link
          >
        </div>
        <!--  -->
        <!-- 超级PK10类 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'pk10'">
          <router-link
            tag="span"
            to="/liangmian2"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >两面</router-link
          >
          |
          <router-link
            tag="span"
            to="/paiming10"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >排名1~10</router-link
          >
          |
          <router-link
            tag="span"
            to="/guanyazuhe"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >冠亚组合</router-link
          >
        </div>
        <!-- 3d -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'pl3'">
          
            <template v-if="pl3banshi_bool">
            <router-link
            tag="span"
            to="/liangmian3"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >两面</router-link
          >
          |   <router-link
            tag="span"
            to="/yizi"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >一字现</router-link
          >
          |  
          <router-link
            tag="span"
            to="/erzi"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >二字现</router-link
          >
          |
          <router-link
            tag="span"
            to="/sanzi"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >三字现</router-link
          >
          |
          <router-link
            tag="span"
            to="/yizidingwei"
            :class="lotActive == '5' ? 'selected' : ''"
            @click.native="changeLotActive('5')"
            >一字定</router-link
          >
          |
            <router-link
            tag="span"
            to="/erzidingwei"
            :class="lotActive == '6' ? 'selected' : ''"
            @click.native="changeLotActive('6')"
            >二字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/sanzidingwei"
            :class="lotActive == '7' ? 'selected' : ''"
            @click.native="changeLotActive('7')"
            >三字定</router-link
          >
          |  
          <router-link
            tag="span"
            to="/plhzkua"
            :class="lotActive == '8' ? 'selected' : ''"
            @click.native="changeLotActive('8')"
            >和值跨度</router-link
          >
           </template>
           <template v-if="!pl3banshi_bool">
          <router-link
            tag="span"
            to="/pl3ky"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >快译</router-link
          >
          |
       
          <router-link
            tag="span"
            to="/pl3kd"
            :class="lotActive == '13' ? 'selected' : ''"
            @click.native="changeLotActive('13')"
            >快打</router-link
          >
          |
       
          <router-link
            tag="span"
            to="/yzdkx"
            :class="lotActive == '8' ? 'selected' : ''"
            @click.native="changeLotActive('8')"
            >一字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/erzdkx"
            :class="lotActive == '9' ? 'selected' : ''"
            @click.native="changeLotActive('9')"
            >二字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/szdkx"
            :class="lotActive == '10' ? 'selected' : ''"
            @click.native="changeLotActive('10')"
            >三字定</router-link
          >
          |
          <router-link
            tag="span"
            to="/plhzkua"
            :class="lotActive == '113' ? 'selected' : ''"
            @click.native="changeLotActive('113')"
            >和值跨度</router-link
          >
          <!-- <router-link
            tag="span"
            to="/plkua"
            :class="lotActive == '11' ? 'selected' : ''"
            @click.native="changeLotActive('11')"
            >跨度</router-link
          >
          |
          <router-link
            tag="span"
            to="/plhezhi"
            :class="lotActive == '12' ? 'selected' : ''"
            @click.native="changeLotActive('12')"
            >和值</router-link
          >
          | -->
        </template>
        </div>
        <!-- kuai3 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'k3'">
          <router-link
            tag="span"
            to="/kuai31"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >和值红黑杂双面</router-link
          >
          |
          <router-link
            tag="span"
            to="/kuai32"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >三军二字三字组合</router-link
          >
        </div>
        <!--   -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'pcdd'">
          <router-link
            tag="span"
            to="/pcddzsp"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >主势盘</router-link
          >
          <router-link
            tag="span"
            to="/pcdddw"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >定位</router-link
          >
          <!-- <router-link
            tag="span"
            to="/pcddlts"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >聊天室</router-link
          > -->
        </div>
        <!-- 宾果采 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'bingo'">
          <router-link
            tag="span"
            to="/bgczsp"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >主势盘</router-link
          >
          |
          <router-link
            tag="span"
            to="/bgclh"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >龙虎</router-link
          >
          |
          <router-link
            tag="span"
            to="/bgcly"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >连赢</router-link
          >
        </div>
        <!-- 80x20-->
        <div class="lotsnav" v-if="currentGame.Gametype == '80x20'">
          <router-link
            tag="span"
            to="/liangmianqw"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1','qwlm')"
            >总盘</router-link
          >
          <!-- |
          <router-link
            tag="span"
            to="/qwlianying"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >前五连赢</router-link
          >
          | 
          <router-link
            tag="span"
            to="/zhbswh"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >总和、比数、五行</router-link
          >
          |
          <router-link
            tag="span"
            to="/kl8zm"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >正码</router-link
          >-->
        </div>
        <!-- 快乐十分8球 -->
        <div class="lotsnav" v-if="currentGame.Gametype == '20x8'">
          <router-link
            tag="span"
            to="/liangmian8"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >两面</router-link
          >
          |
          <router-link
            tag="span"
            to="/danhao188"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >单号1~8</router-link
          >
          |
          <router-link
            tag="span"
            to="/diyiqiu8"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >第一球</router-link
          >
          |
          <router-link
            tag="span"
            to="/dierqiu8"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >第二球</router-link
          >
          |
          <router-link
            tag="span"
            to="/disanqiu8"
            :class="lotActive == '5' ? 'selected' : ''"
            @click.native="changeLotActive('5')"
            >第三球</router-link
          >
          |
          <router-link
            tag="span"
            to="/disiqiu8"
            :class="lotActive == '6' ? 'selected' : ''"
            @click.native="changeLotActive('6')"
            >第四球</router-link
          >
          |
          <router-link
            tag="span"
            to="/diwuqiu8"
            :class="lotActive == '7' ? 'selected' : ''"
            @click.native="changeLotActive('7')"
            >第五球</router-link
          >
          |
          <router-link
            tag="span"
            to="/diliuqiu8"
            :class="lotActive == '8' ? 'selected' : ''"
            @click.native="changeLotActive('8')"
            >第六球</router-link
          >
          |
          <router-link
            tag="span"
            to="/diqiqiu8"
            :class="lotActive == '9' ? 'selected' : ''"
            @click.native="changeLotActive('9')"
            >第七球</router-link
          >
          |
          <router-link
            tag="span"
            to="/dibaqiu8"
            :class="lotActive == '10' ? 'selected' : ''"
            @click.native="changeLotActive('10')"
            >第八球</router-link
          >
          |
          <router-link
            tag="span"
            to="/lianma8"
            :class="lotActive == '11' ? 'selected' : ''"
            @click.native="changeLotActive('11')"
            >连码</router-link
          >
          |
          <router-link
            tag="span"
            to="/longhu8"
            :class="lotActive == '12' ? 'selected' : ''"
            @click.native="changeLotActive('12')"
            >龙虎</router-link
          >
        </div>
        <!-- 农场 -->
        <div class="lotsnav" v-if="currentGame.Gametype == 'nongchang'">
          <router-link
            tag="span"
            to="/liangmian4"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >两面</router-link
          >
          |
          <router-link
            tag="span"
            to="/danhao18"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >单号1~8</router-link
          >
          |
          <router-link
            tag="span"
            to="/diyiqiu"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >第一球</router-link
          >
          |
          <router-link
            tag="span"
            to="/dierqiu"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >第二球</router-link
          >
          |
          <router-link
            tag="span"
            to="/disanqiu"
            :class="lotActive == '5' ? 'selected' : ''"
            @click.native="changeLotActive('5')"
            >第三球</router-link
          >
          |
          <router-link
            tag="span"
            to="/disiqiu"
            :class="lotActive == '6' ? 'selected' : ''"
            @click.native="changeLotActive('6')"
            >第四球</router-link
          >
          |
          <router-link
            tag="span"
            to="/diwuqiu"
            :class="lotActive == '7' ? 'selected' : ''"
            @click.native="changeLotActive('7')"
            >第五球</router-link
          >
          |
          <router-link
            tag="span"
            to="/diliuqiu"
            :class="lotActive == '8' ? 'selected' : ''"
            @click.native="changeLotActive('8')"
            >第六球</router-link
          >
          |
          <router-link
            tag="span"
            to="/diqiqiu"
            :class="lotActive == '9' ? 'selected' : ''"
            @click.native="changeLotActive('9')"
            >第七球</router-link
          >
          |
          <router-link
            tag="span"
            to="/dibaqiu"
            :class="lotActive == '10' ? 'selected' : ''"
            @click.native="changeLotActive('10')"
            >第八球</router-link
          >
          |
          <router-link
            tag="span"
            to="/lianma2"
            :class="lotActive == '11' ? 'selected' : ''"
            @click.native="changeLotActive('11')"
            >连码</router-link
          >
          |
          <router-link
            tag="span"
            to="/longhu2"
            :class="lotActive == '12' ? 'selected' : ''"
            @click.native="changeLotActive('12')"
            >龙虎</router-link
          >
        </div>
        <!-- 11选5 -->
        <div class="lotsnav" v-if="currentGame.Gametype == '11x5'">
          <router-link
            tag="span"
            to="/liangmian5"
            :class="lotActive == '1' ? 'selected' : ''"
            @click.native="changeLotActive('1')"
            >两面</router-link
          >
          |
          <router-link
            tag="span"
            to="/danhao"
            :class="lotActive == '2' ? 'selected' : ''"
            @click.native="changeLotActive('2')"
            >单号</router-link
          >
          |
          <router-link
            tag="span"
            to="/yizhongyi"
            :class="lotActive == '3' ? 'selected' : ''"
            @click.native="changeLotActive('3')"
            >一中一</router-link
          >
          |
          <router-link
            tag="span"
            to="/lianma3"
            :class="lotActive == '4' ? 'selected' : ''"
            @click.native="changeLotActive('4')"
            >连码</router-link
          >
          |
          <router-link
            tag="span"
            to="/zhixuan"
            :class="lotActive == '5' ? 'selected' : ''"
            @click.native="changeLotActive('5')"
            >直选</router-link
          >
        </div>
        <!-- <div class="v"   :class="dingdang ? 'open' : 'close'" @click="xiugaidingdang"></div> -->
        <div class="btn1 a" @click="seeyanzheng" v-if="showyanzheng">
          开奖验证
        </div>
        <div class="btn2 c" @click="pl3banshi_bool=!pl3banshi_bool" v-if="currentGame.Gametype=='pl3'">
          <span>{{ pl3banshi_bool ? "地方版" : "双面版" }}</span>
        </div>
        <!-- 结果走势 -->
        <div class="btn2 a" @click="showResultGo" v-if="!IsPhone()">
          <span>{{ resultGoBool ? "关闭走势" : "结果走势" }}</span>
        </div>
      </div>
    </div>
    <!-- mainer -->
    <div class="mainer">
      <!-- 左边侧边栏   -->
      <div
        class="lefter"
        v-if="$route.name != 'wjmx' && $route.name != 'jryj' && fulaya"
      >
        <div class="top">
          <div class="user">
            <h3>账户信息</h3>
            <ul>
              <li>
                <span
                  style="
                    width: 75px;
                    min-width: 75px;
                    display: inline-block;
                    text-align: right;
                  "
                  >{{ leixing[userInfo.wallettype] }}账号：</span
                >
                {{ userInfo.isshiwan == 1 ? "试玩" : userInfo.account }}
              </li>
              <li>
                <span
                  style="
                    width: 75px;
                    min-width: 75px;
                    display: inline-block;
                    text-align: right;
                  "
                  >今日输赢：</span
                >
                {{ winlost }}
              </li>

              <li>
                <span
                  style="
                    width: 75px;
                    min-width: 75px;
                    display: inline-block;
                    text-align: right;
                  "
                  >{{
                    currentQianBao ? currentQianBao.walletnamecn : ""
                  }}：</span
                >
                {{ currentQianBao ? currentQianBao.amount : "" }}
              </li>
              <li>
                <span
                  style="
                    width: 75px;
                    min-width: 75px;
                    display: inline-block;
                    text-align: right;
                  "
                  >未结金额：</span
                >
                {{ Number(accInfo.unsettleamount).toFixed(2) }}
              </li>
              <!-- <li>未结算金额：0</li> -->
            </ul>
          </div>
          <!-- 外链 -->
          <template v-if="currentGame.Gametype != 'wxc'">
            <div
              class="btns b3 b3hover"
              v-for="item in wailian"
              :key="item.name"
              @click="goOpen(item.url.trim())"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
        <!-- 注单 -->
        <div class="notes" v-if="currentGame.Gametype != 'wxc'&&currentGame.Gametype != 'pl3'">
          <div class="note-list scroll">
            <ul v-for="(item, index) in leftArr" :key="index">
              <li>
                <em class="green"
                  >{{ item.roomcn }}
                  <span style="color: #333">{{ item.orderid }}</span>
                </em>
              </li>
              <li>
                <em class="dec"
                  >{{ item.iteminfo }} {{ JSON.parse(item.orderinfo).num }}</em
                >
                @<em class="red">{{ item.winodds }}</em>
              </li>
              <li>下注金额：{{ item.amount }}</li>
            </ul>
          </div>
        </div>
        <div
          class="print_Wrap"
          id="printArea"
          v-if="currentGame.Gametype == 'wxc'||currentGame.Gametype == 'pl3'"
        >
          <section class="danwei">单位：元</section>
          <h2 class="print_title">
            <span class="red">{{ currentGame.Gamename }}</span>
            <span class="printcount">第{{ printCount }}次打印</span>
          </h2>
          <p class="print-info-row">
            时间：{{ wxcArr[0] ? substrSome(wxcArr[0].optime) : "" }}
          </p>
          <p class="print-info-row">会员：{{ userInfo.account }}</p>

          <table class="printtable" border="">
            <thead>
              <tr>
                <th>号码</th>
                <th>赔率</th>
                <th>金额</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in wxcArr" :key="item.orderid">
                <td>{{ item.label }}</td>
                <td>
                  <span class="red">{{ item.winodds }}</span>
                </td>
                <td>{{ item.amount }}</td>
              </tr>
            </tbody>
          </table>
          <section style="line-height: 22px">
            笔数：{{ wxcArr.length }} 总金额：{{ wxcAmount }}
          </section>
          <section style="line-height: 22px">第20203期，3天内有效</section>
        </div>
        <section style="line-height: 22px" v-if="currentGame.Gametype == 'wxc'||currentGame.Gametype == 'pl3'">
          笔数：{{ wxcArr.length }} 总金额：{{ wxcAmount }}
        </section>

        <section class="print-btn-wrap" v-if="currentGame.Gametype == 'wxc'||currentGame.Gametype == 'pl3'">
          <button @click="clearaxcArr">清空</button>
          <button v-print="'printArea'" @click="dayinhaol">打印</button>
        </section>
      </div>
      <div class="lefter" v-if="$route.name == 'grzx' && zl_zb == 2">
        <h2 class="tjh2">账变</h2>
        <div class="tjli_wrap">
          <section
            class="tj_item"
            :class="{ selected: item == zbdate }"
            @click="setzbdate(item)"
            v-for="item in zb_dateArr"
            :key="item"
          >
            <span class="game">{{ item }}</span>
          </section>
        </div>
      </div>
      <!-- 未接明细 已结明细 左边 -->
      <div class="lefter" v-if="$route.name == 'wjmx' || $route.name == 'jryj'">
        <h2 v-if="$route.name == 'wjmx'" class="tjh2">未结统计</h2>
        <h2 v-if="$route.name == 'jryj'" class="tjh2">已结统计</h2>
        <div class="tjli_wrap">
          <template v-if="gameamountlist.length > 0">
            <section
              class="tj_item"
              v-for="item in gameamountlist"
              @click="settjgame(item)"
              :class="{ selected: item.roomid == hltjgame.roomid }"
              :key="item.roomcn"
            >
              <span class="game">{{ item.roomcn }}</span>
              <span class="tjjine">{{ item.amount }}</span>
            </section>
          </template>
          <section class="tj_item" v-if="gameamountlist.length == 0">
            <span>无数据</span>
          </section>
        </div>
      </div>
      <div class="bodyer">
        <div class="scroll" id="scroll" ref="scroll">
          <!-- <div > -->
          <!-- <keep-alive include="baobiaochaxun,gamebb"> -->
          <router-view></router-view>
          <!-- </keep-alive> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="marquee">
        <marquee
          onmouseout="this.start()"
          onmouseover="this.stop()"
          scrollamount="3"
        >
          <label v-html="footerNotice"></label>
        </marquee>
        <div class="more" style="color: blue" @click="gomore">更多</div>
      </div>
      <div class="notice-os" v-if="noticeBool && !IsPhone()">
        <div class="con">
          <div class="n-head">
            <img src="../assets/notice-header.png" alt="" />
          </div>
          <div class="n-close">
            <i class="el-icon-close" @click="closeNotice"></i>
          </div>
          <div class="n-text">
            <div class="n-nav scroll">
              <ul>
                <li
                  @click="noticeIndex = index + 1"
                  :class="noticeIndex == index + 1 ? 'selected' : ''"
                  v-for="(text, index) in tanchuangHeaderArr"
                  :key="text"
                >
                  {{ text }}
                </li>
              </ul>
            </div>
            <div
              class="n-txt scroll"
              v-show="noticeIndex == index + 1"
              v-for="(item, index) in tanchuangNoticeArr"
              :key="item.id"
            >
              {{ item.info }}
            </div>
          </div>
          <div class="n-fot">
            {{ noticeIndex }}/{{ tanchuangNoticeArr.length }}
          </div>
        </div>
      </div>
      <!---->
    </div>
    <submitTK />
    <audio src="../assets/kaijiang.mp3" ref="audio"></audio>
    <div class="gameseeting" v-if="set_b">
      <div class="title">设置

        <span  @click="set_b=false">X</span>
      </div>
    <ul class="set-content">
      <draggable v-model="gameData" :options="{handle:'.handle'}">
      
      <li   style="display: inline-block;"
               class="li moresel handle"
               v-for="item in gameData"
               :key="item.Cato"
             >
                {{ item.Gamename }}
           
             </li>
 </draggable>
    </ul>
<div class="set-footer">
   <p class="red tuodongp">可拖动彩种位置来改变彩种排序</p>
  <span class="queding" @click="saveGameData">确定</span>
</div>


    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from 'vuedraggable'
import submitTK from "../components/submitTK";
import {md55} from '../components/mixins/md5';
export default {
  name: "",
  props: [""],
  components: {
    submitTK,
    draggable
  },
  data() {
    return {
      pl3banshi_bool:true,
      fantanclass: {
        8: "type-ssg",
        3: "type-f3d sd6 pcddopen",
        5: "type-ssc",
        10: "type-pks fantan1",
      },
      printCount: 1,
      jiesuanMeiju: {
        0: "开奖中",
        1: "结算中",
        2: "已结算",
      },
      panArr: [],
      leixing: {
        credit: "信用",
        cash: "会员",
      },
      noticeBool: sessionStorage.getItem("noticeBool") ? false : true,
      noticeIndex: 1,
      isFirst: true,
      // 底部footer 公告
      footerNotice: "",
      // 头部显示与隐藏
      hideHeader: true,
      tabActive: 1,
      skin: "blue",

      dingdang: true,
      regionActive: "A",
      lotActive: "1",
      // 是否点击编辑
      isEdit: false,
      // 是否点击更多游戏
      isMore: false,
      // 导航栏得游戏
      navData: [],
      // 更多游戏
      routerData: [],
      // 所有游戏
      gameData: [],
      // 开奖号码
      kjObj: {
        Opencode: [],
        settlestate: "",
      },
      //一种 第二种显示开奖号码
      secondkjobj:{
        Opencode: [],
        settlestate: "",
      },
      recordCode: "",
      recordGame: "",
      recordSettleState: "",
      // 选中 更多的当前 游戏
      moreCurrentGame: {},
      // 账户资金
      accMoney: {},
      currentQianBao: {},
      showyanzheng: false,
      tanchuangNoticeArr: [],
      tanchuangHeaderArr: [],
      k3dianshu: [0, 0, 0, 0, 0, 0],
      k3time: 20220816,
      wailian: [],
      gameNum: 0,
      winlost: 0,
      zb_dateArr: [],
      set_b:false,
      huaxian_num:0,
      qiuhao_type:''
    };
  },
  watch: {
    pl3banshi_bool(val){
      let path=null;
      // this.lotActive=1
      if(val){
        path='/liangmian3'
      }else{
        path="/pl3ky"
      }
      this.$router.push(path)
    },
    //ISOPEN(val) {
    // if (val) {
    //   window.delaytime = window.setTimeout(() => {
    //    this.getOpenCode();
    //    window.clearTimeout(window.delaytime);
    //  }, 3000);
    // }
    // },

    "kjObj.Expect": {
      handler(val, oldval) {
        window.clearInterval(window.kjtime);
        // console.log(oldval, "oldval");
        if (
          oldval != "--" &&
          val !== "--" &&
          this.recordGame == this.currentGame.Gamename
        ) {
          this.$store.commit("UPDATELUTU");
          this.$store.commit("KKK");
        }
      },
    },

    // 切换盘口
    pan(val) {
      //this.getgameodds();
    },
    accInfo: {
      // immediate: true,
      deep: true,
      handler(val) {
        // console.log("用户额度", val);
        let abItem = this.qianbaoList.find(
          (item) => item.nameeng == this.currentGame.roomeng
        );

        if (this.accInfo.walletinfos) {
          this.currentQianBao = this.accInfo.walletinfos.find(
            (ele) => ele.walletnameeng == abItem.walletnameeng
          );
          this.$store.commit("SETCURRENTQIANBAO", this.currentQianBao);
        }
      },
    },
    $route: {
      deep: true,
      handler(val) {
        this.$store.commit("SETYANZHENG", false);
        let mathArr = val.matched;
        if (mathArr.length < 3) {
          this.showyanzheng = false;
        } else {
          let b = false;
          mathArr.forEach((math) => {
            let c = this.gameRouters.find((str) => str == math.path);
            if (c) {
              b = true;
            }
          });
          if (!b) {
            this.showyanzheng = false;
          } else {
            this.$nextTick(() => {
              if (this.currentGame.codetype == "calc") {
                this.showyanzheng = true;
              }
            });
          }
        }
        // console.log(val, "111", this.gameRouters);
      },
    },
    currentGame: {
      immediate: true,
      handler(val) {
        this.recordSettleState = "";
        if (val.codetype == "calc") {
          this.showyanzheng = true;
        } else {
          this.showyanzheng = false;
        }
        this.$store.commit("SETYANZHENG", false);
        // if (val.frequency == "minite") {
        //   this.$store.commit("UPDATELUTU");
        // }
        //  console.log(this.currentGame,1854)  roomeng
        this.panArr = this.currentGame.pans;
        this.$store.commit("setPanArr", this.panArr);
        let pan = this.panArr.find((pan) => pan == this.pan);
        if (!pan) {
          this.$store.commit("setPan", this.panArr[0]);
        }

        window.clearInterval(window.kjtime);
        console.log("当前选中的游戏", val);
        this.$store.commit("resetCountAdd");
        window.clearInterval(window.dataTime);
        window.dataTime = null;
        // window.clearInterval(window.time1);
        // window.clearInterval(window.time2);
        // window.clearInterval(window.time3);
        // window.clearInterval(window.time4);
        // for (let index = 1; index < 50; index++) {
        //   window.clearInterval(index);
        // }
        if (val) {
          if (val.hasOwnProperty("roomeng")) {
            //五星彩
            if (val.Gametype == "wxc") {
              this.$store.dispatch("getWXCRCL");
              this.$store.dispatch("getWXCLCL");
            } else {
              this.$store.dispatch("getRCL");
              this.$store.dispatch("getLCL");
            }

            this.kjObj = { Expect: "--" };

            // 获取开奖号码
            this.getOpenCode();
            // 获取赔率信息
            // this.getgameodds();
            // 获取账户信息
            this.getAccountMoney();

            let abItem = this.qianbaoList.find(
              (item) => item.nameeng == val.roomeng
            );

            if (this.accInfo.walletinfos) {
              this.currentQianBao = this.accInfo.walletinfos.find(
                (ele) => ele.walletnameeng == abItem.walletnameeng
              );
              this.$store.commit("SETCURRENTQIANBAO", this.currentQianBao);
            }
          }
          if (val.roomeng == "xamlhc") {
            let skin_home_class = "skin-blue";
            this.$store.commit("setSkinColor", skin_home_class);
          } else if (val.roomeng == "hk6") {
            let skin_home_class = "skin-red";
            this.$store.commit("setSkinColor", skin_home_class);
          } else if (val.roomeng == "amlh") {
            let skin_home_class = "skin-brown";
            this.$store.commit("setSkinColor", skin_home_class);
          }
        }
      },
    },
    isEdit(val) {
      if (val) {
        this.routerData.forEach((item) => {
          item.b = true;
        });
        this.navData.forEach((item) => {
          item.b = true;
        });
      } else {
        this.routerData.forEach((item) => {
          item.b = false;
        });
        this.navData.forEach((item) => {
          item.b = false;
        });
      }

      this.$forceUpdate();
    },
  },
  computed: {
    fulaya() {
      let b = true;
      if (this.$route.name == "grzx" && this.zl_zb == 2) {
        b = false;
      }
      return b;
    },
    gameamountlist() {
      let arr = JSON.parse(JSON.stringify(this.hltjlislt));
      //console.log(arr,'sdsadsa')
      return arr;
    },
    wxcAmount() {
      let count = 0;
      if (this.wxcArr.length > 0) {
        this.wxcArr.forEach((item) => {
          count += Number(item.amount);
        });
      }
      return count;
    },
    ...mapState([
      'sxAllArr',
      'qwer',
      "zbdate",
      "zl_zb",
      "hltjgame",
      "hltjlislt",
      "companyConfig",
      "openCount",
      "audio",
      "userInfo",
      "currentGame",
      "skin_home_class",
      "pan",
      "leftArr",
      "wxcArr",
      "leftName",
      "accInfo",
      "sxArr",
      "ISOPEN",
      "qianbaoList",
      "yanzheng",
      "gameRouters",
      "oddData",
      "resultGoBool",
    ]),
  },
  created() {
    this.getCompanyConfig()
    this.zb_dateArr = [];
    for (let index = 0; index < 15; index++) {
      this.zb_dateArr.push(this.getBeforeDate(index));
    }
    this.$store.commit("SETZBDATE", this.zb_dateArr[0]);

    if (this.companyConfig.expcodeurl1) {
      let arr = this.companyConfig.expcodeurl1.split("=");
      let obj = {
        name: arr[0],
        url: arr[1],
      };
      this.wailian.push(obj);
    }
    if (this.companyConfig.expcodeurl2) {
      let arr = this.companyConfig.expcodeurl2.split("=");
      let obj = {
        name: arr[0],
        url: arr[1],
      };
      this.wailian.push(obj);
    }
    if (this.companyConfig.expcodeurl3) {
      let arr = this.companyConfig.expcodeurl3.split("=");
      let obj = {
        name: arr[0],
        url: arr[1],
      };
      this.wailian.push(obj);
    }
    if (this.companyConfig.expcodeurl4) {
      let arr = this.companyConfig.expcodeurl4.split("=");
      let obj = {
        name: arr[0],
        url: arr[1],
      };
      this.wailian.push(obj);
    }
    if (this.companyConfig.expcodeurl5) {
      let arr = this.companyConfig.expcodeurl5.split("=");
      let obj = {
        name: arr[0],
        url: arr[1],
      };
      this.wailian.push(obj);
    }
    if (this.companyConfig.expcodeurl6) {
      let arr = this.companyConfig.expcodeurl6.split("=");
      let obj = {
        name: arr[0],
        url: arr[1],
      };
      this.wailian.push(obj);
    }
    // console.log("钱包list", this.qianbaoList);
    this.getAccountMoney();
    // 获取对应的生肖秋毫
    this.$store.dispatch("getSXHM");
    // console.log(sessionStorage.getItem("noticeBool"), 11111111);?
    // console.log(this.userInfo);
 
  
    // 查询公告信息  7701 (*系统讯息   7700,弹窗   7701,通告   7702)
    this.getSysInfo("gundong");
    this.getSysInfo1("tanchuang");
  },
  mounted() {
    let gameData = localStorage.getItem("gameData");
    // console.log(navData, this.routerData, "navData");
    if (gameData) {
      this.gameData = JSON.parse(gameData);
    }  
      // 获取游戏列表
      this.getGameList();
    //     window.onbeforeprint = function() {
    //   console.log("打印按钮已点击");
    // }

    // window.onafterprint = function() {
    //   console.log("打印完成");
    // }

    // this.checkScrollbar();

    window.addEventListener("resize", this.checkScrollbar);
    // 今日输赢
    this.getwinlost();
    document.body.style.zoom = "normal";
    let that = this;
    document.addEventListener("click", () => {
      that.isMore = false;
    });
    let ddnum = localStorage.getItem("dingdang");
    if (ddnum) {
      if (ddnum == "1") {
        this.dingdang = true;
      } else if (ddnum == "0") {
        this.dingdang = false;
      }
    }
    // let homes=document.getElementById('home');

    // homes.addEventListener('click',appclick, { passive: false })
    // function appclick(e) {
    //that.$refs.audio.muted = true
    //  that.$refs.audio.play()

    // homes.removeEventListener('click', appclick, false)
    //  }
    this.gameNum = 9;
    if (this.currentGame.roomeng) {
      setInterval(() => {
        this.$store.commit("setLeftArr", []);
      }, 15000);
    }

    window.fixeTime = window.setInterval(() => {
      this.getwinlost();
      if (this.currentGame.roomeng) {
        this.getOpenCode();

        // 获取账户信息
        this.getAccountMoney();
        if (this.currentGame.Gametype == "wxc") {
              this.$store.dispatch("getWXCRCL");
              this.$store.dispatch("getWXCLCL");
            } else {
              this.$store.dispatch("getRCL");
              this.$store.dispatch("getLCL");
            }
      }

      // this.getgameodds1();
    }, 5000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScrollbar);
    window.clearInterval(window.fixeTime);
  },
  methods: {
    getCompanyConfig(){
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getcompanyconfig",obj).then((res) => {
        if (res.Status) {
          let companyInfo = JSON.parse(res.Msg);
            // console.log(companyInfo,2284)
          this.$store.commit("SETCOMPANYCONFIG", companyInfo);
         
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
    fulushouxi(num){
      let count=Number(num);
      let str='';
     let fu=[ 1,2,3,4,5,21,22,23,24,25,41,42,43,44,45,61,62,63,64,65]
     let lu=[  6,7,8,9,10,26,27,28,29,30,46,47,48,49,50,66,67,68,69,70]
     let shou=[11,12,13,14,15,31,32,33,34,35,51,52,53,54,55,71,72,73,74,75]
     let xi=[16,17,18,19,20,36,37,38,39,40,56,57,58,59,60,76,77,78,79,80]
     let index=fu.findIndex(ele=>count==Number(ele))
     if(index!=-1){
      str='福'
      return str
     }
     let index1=lu.findIndex(ele=>count==Number(ele))
     if(index1!=-1){
      str='禄'
      return str
     }
     let index2=shou.findIndex(ele=>count==Number(ele))
     if(index2!=-1){
      str='寿'
      return str
     }
     let index3=xi.findIndex(ele=>count==Number(ele))
     if(index3!=-1){
      str='喜'
      return str
     }




    },
    getqianwuqiusum(arr){
     let sum=0
     arr.forEach((qiu,index)=>{
     if(index<5){
      sum+=Number(qiu)
     }
     })
     return sum
    },
    setzbdate(item) {
      this.$store.commit("SETZBDATE", item);
    },
    settjgame(item) {
      this.$store.commit("SETHLTJGAME", item);
    },
    checkScrollbar() {
      this.handleResize();
      const container = this.$refs.scroll;
      // console.log(container)
      // console.log(container.scrollHeight,container.offsetHeight)
      if (container.scrollHeight > container.offsetHeight) {
        this.$store.commit("SETSCROLLBAR", true);
      } else {
        this.$store.commit("SETSCROLLBAR", false);
      }
    },
    handleResize() {
      
      
      if (this.gameData.length <= 9) {
        this.navData = [...this.gameData];
        this.routerData = [];
      } else {
        let jiexian = 10;
        var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
// console.log("浏览器可视区宽度：" + viewportWidth);
      

        let width1 = this.$refs.menu?this.$refs.menu.offsetWidth:viewportWidth;
        let width;
     
        if(viewportWidth&&width1>1290){
          width = width1;
        }else{
          width=1290
        }
        let num1 = width-138;
        // console.log(width,num1)
        let num2 = 115;
        let result = Math.floor(num1 / num2);
      // console.log(result)
        if (result > 10) {
          jiexian = result - 1;
        } else {
          jiexian = 9;
        }
        this.navData = this.gameData.slice(0, jiexian);
        this.routerData = this.gameData.slice(jiexian);
      }
      localStorage.setItem("routerData", JSON.stringify(this.routerData));
      localStorage.setItem("navData", JSON.stringify(this.navData));

      // 处理宽度变化的逻辑
    },
    printElement() {
      let printContents = document.getElementById("printContent").innerHTML;
      //  let originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      // document.body.innerHTML = originalContents;
    },
    // 获取今日输赢
    getwinlost() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      var timestamp1 = Date.now();
      var timestamp =Math.floor(timestamp1 / 1000);
      let tokenstr=this.userInfo.Uuid+this.userInfo.Sid+timestamp+this.qwer
      // console.log(tokenstr,2264)
      let token=md55(tokenstr)
      // token='db9e6d606faaadaa6582bdc9e3156ef1'
      obj.token=token
      obj.timestamp=timestamp
      this.$http.post("gettodaywinlost", obj).then((res) => {
        if (res.Status) {
          // console.log(res, "输赢");
          this.winlost = res.Msg;
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    onsuofang() {
      //document.body.style.zoom = 1;
    },
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone =
        /mobile|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
          info
        );
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    xiugaidingdang() {
      this.dingdang = !this.dingdang;
      let num = this.dingdang ? 1 : 0;
      localStorage.setItem("dingdang", num);
    },
    seeyanzheng() {
      let b = this.yanzheng;

      if (!b) {
        let obj = {
          roomeng: this.currentGame.roomeng,
          Gametype: this.currentGame.Gametype,
          frequency: this.currentGame.frequency,
          Gamename: this.currentGame.Gamename,
          roomid: this.currentGame.Cato,
        };
        this.$store.commit("SETROOMENG", obj);
      }
      this.$store.commit("SETYANZHENG", !b);
    },
    gomore() {
      this.$router.push("/morelist");
    },
    goOpen(url) {
      window.open(url);
      // window.location.href = url;
    },
    closeNotice() {
      //this.$refs.audio.play();
      this.noticeBool = false;
      // console.log(this.noticeBool);
      sessionStorage.setItem("noticeBool", false);
    },
    showResultGo() {
      this.$store.commit("resultGoBool", !this.resultGoBool);
    },
    // 获取账户信息
    getAccountMoney() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getmoneyinfo", obj).then((res) => {
        if (res.Status) {
          let resobj = JSON.parse(res.Msg);
          // console.log(resobj, "账户信息");
          this.$store.commit("setAccInfo", resobj);
          this.accMoney = resobj;
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    getgameodds1() {
      // nameeng
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        pan: this.pan,
      };
      this.$http.post("getgameodds", obj).then((res) => {
        if (res.Status) {
          let resAArr = JSON.parse(res.Msg);

          this.$store.commit("setupdateOdds", resAArr);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    dayinhaol() {
      window.delaytime = window.setTimeout(() => {
        this.$store.commit("setclearwxcArr");
      }, 3000);
    },
    clearaxcArr() {
      this.$store.commit("setwxcArr", []);
    },
    // 获取赔率信息
    getgameodds() {
      // nameeng
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        pan: this.pan,
      };
      this.$http.post("getgameodds", obj).then((res) => {
        if (res.Status) {
          let resAArr = JSON.parse(res.Msg);
          // console.log(resAArr, "赔率信息");
          resAArr.forEach((item) => {
            item.recordOdds = item.Odds;
            // Object.defineProperty(item, "recordOdds", {
            //   writable: false,
            // });
            item.Odds = "--";
          });

          this.$store.commit("setOdd", resAArr);
          this.$nextTick(() => {
            if (this.ISOPEN) {
              this.$store.commit("OPENPAN");
            }
          });
          // }

          // if (this.ISOPEN) {
          //   this.$store.commit("OPENPAN");
          // }
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 获取开奖号码
    getOpenCode() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
      };
      this.$http.post("getopencode", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);
          let arr = [];
          let fan = "";
          if (this.currentGame.Gametype == "fantan") {
            if (obj.Opencode) {
              if (obj.Opencode.includes("+")) {
                let jiaArr = obj.Opencode.split("+");
                arr = jiaArr[0].split(",");
                fan = jiaArr[1];
              } else {
                arr = obj.Opencode ? obj.Opencode.split(",") : [];
              }
            } else {
              arr = [];
            }
          } else {
            arr = obj.Opencode ? obj.Opencode.split(",") : [];
          }

          if (arr.length > 0) {
            if (this.currentGame.Gametype == "pcdd"||this.currentGame.Gametype == "pl3") {
              let num = 0;
              arr.forEach((ele) => {
                num += Number(ele);
              });
              let setarr=new Set(arr)
              let newarr=Array.from(setarr)
              if(newarr.length==1){
                this.kjObj.xingtai='豹子'
              }else if(newarr.length==2){
                this.kjObj.xingtai='对子'
              }else if(newarr.length==3){
                this.kjObj.xingtai='杂六'
                newarr.sort((a,b)=>Number(b)-Number(a))
                if(Number(newarr[0])-Number(newarr[1])==1&&Number(newarr[1])-Number(newarr[2])==1){
                  this.kjObj.xingtai='顺子'
                }
               
              }


              arr.push(num);
              let arr1 = [];
              this.kjObj.dx = num > 13 ? "大" : "小";
              this.kjObj.ds = num % 2 == 0 ? "双" : "单";
              arr1 = [this.kjObj.dx+this.kjObj.ds,this.kjObj.xingtai];
              this.kjObj.lutuArr = arr1;
            }
            if (this.currentGame.Gametype == "80x20") {
              let sarr = arr.slice(0, 20);
              arr = sarr;
            }
          }
          this.recordCode = obj.Expect;
          this.kjObj.Expect = obj.Expect;
          this.kjObj.settlestate = obj.settlestate;
          this.kjObj.Opentime=obj.Opentime
          if (this.kjObj.settlestate != 0 && this.recordSettleState === 0) {
            this.$store.commit("KKK");
            this.$store.commit("UPDATELUTU");
            this.$store.commit("setLeftArr", []);
            if (this.dingdang) {
              this.$refs.audio.play();
            }
          }
          this.recordSettleState = obj.settlestate;

          // this.kjObj = obj;
          this.kjObj.Opencode = arr;
          // console.log( this.kjObj.Opencode,' this.kjObj.Opencode')
          this.$forceUpdate();
          this.recordGame = this.currentGame.Gamename;
          if (this.kjObj.Opencode.length > 0) {
            if (this.currentGame.Gametype == "pk10") {
              this.kjObj.lutuArr = this.pk10lutu(this.kjObj.Opencode);
            } else if (this.currentGame.Gametype == "ssc") {
              let arr = this.ssclutu(this.kjObj.Opencode);
              this.kjObj.lutuArr = arr.splice(0, 4);
            } else if (this.currentGame.Gametype == "11x5") {
              let arr = this.syx5lutu(this.kjObj.Opencode);
              let arr1 = arr.splice(0, 4);
              let arr2 = ["尾" + arr[arr.length - 1]];
              arr1.splice(3, 0, arr2[0]);
              this.kjObj.lutuArr = arr1;
            } else if (this.currentGame.Gametype == "k3") {
              let arr = this.k3lutu(this.kjObj.Opencode);
              this.kjObj.lutuArr = arr;
            } else if (this.currentGame.Gametype == "20x8") {
              let arr = this.klsflutu(this.kjObj.Opencode);
              let zwdx = "";
              if (arr[0] < 10) {
                zwdx = arr[0] >= 5 ? "尾大" : "尾小";
              } else {
                let str = arr[0] + "";
                zwdx = str[str.length - 1] >= 5 ? "尾大" : "尾小";
              }
              arr.push(zwdx);
              let longhu =
                this.kjObj.Opencode[0] -
                  this.kjObj.Opencode[this.kjObj.Opencode.length - 1] >
                0
                  ? "龙"
                  : "虎";
              arr.push(longhu);
              this.kjObj.lutuArr = arr;
            } else if (this.currentGame.Gametype == "hk6") {
              let sxArr = [];
            let nianxiaoItem={};
           
              if (this.kjObj.Opencode.length > 0) {
                // ----------------
               if(this.kjObj.Opentime){
                
                nianxiaoItem=this.getNianXiao(this.kjObj.Opentime)
                
               }
            
              
                // ------------------------
                this.kjObj.Opencode.forEach((ele) => {
                  //  let sxItem = this.sxArr.find((item) => item.ball == ele);
                  // sxArr.push(sxItem.shengxiao);
                  nianxiaoItem.sxArr.forEach(item=>{
                    let hadEle=item.nums.find(num=>Number(num)==Number(ele))
                    if(hadEle){
                      sxArr.push(item.xiao);
                    }
                  })
                 
                });
                this.kjObj.lutuArr = sxArr;
              }
            } else if (this.currentGame.Gametype == "pl3") {
              // let sum = this.pl3lutu(this.kjObj.Opencode)[0];
              
              // let dx = "";
              // let ds = "";
              // if (sum >= 14) {
              //   dx = "大";
              // } else {
              //   dx = "小";
              // }
              // ds = sum % 2 == 0 ? "双" : "单";
              // let arr1 = [sum, dx, ds];
              // this.kjObj.lutuArr = arr1;
            } else if (this.currentGame.Gametype == "fantan") {
              if (obj.Opencode.includes("+")) {
                let qiansiNum;
                if(this.currentGame.roomeng=='fantan'||this.currentGame.roomeng=='jsfantan'){
                  qiansiNum=4
                  this.huaxian_num=4
                }
                if(this.kjObj.Opencode.length==10){
                  this.huaxian_num=3
                }
                let obj1 = this.fantangameopencode(this.kjObj.Opencode,qiansiNum);
    
                this.kjObj.lutuArr = [];
                let zongshu = obj1.sum;

                // let daxiao =obj1.dx;
                let danshuang = Number(fan) % 2 == 0 ? "双" : "单";
                this.kjObj.lutuArr = [
                  zongshu,
                  // daxiao,
                  danshuang,
                  fan + "番",
                ];
                this.kjObj.fan = fan;
              } else {
                this.kjObj.lutuArr = [];
                let zongshu = 0;
                this.kjObj.Opencode.forEach((strnum) => {
                  zongshu += Number(strnum);
                });

                var remainder = Number(zongshu) % 4; // 计算余数
                remainder = remainder == 0 ? 4 : remainder;
                let daxiao = zongshu > 22 ? "大" : "小";
                let danshuang = zongshu % 2 == 0 ? "双" : "单";
                this.kjObj.lutuArr = [
                  zongshu,
                  daxiao,
                  danshuang,
                  remainder + "番",
                ];
                this.kjObj.fan = remainder;
              }
            }
          }
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    showhuanxian(i){
      let cla=''
      if(i<this.huaxian_num){
        cla='huaxian'
      }
      return cla
    },
    showorhidenum5(i){
      let b=true
      if(this.currentGame.roomeng=='fantan'||this.currentGame.roomeng=='jsfantan'){
        if(i==4){
          b=false
        }
      }
      return b
    },
    // 查询系统弹窗公告
    getSysInfo1(showtype) {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        showtype,
        gametype: "lottery",
      };
      this.$http.post("getnoticeinfo", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          // console.log(resObj, "弹窗公告");
          this.tanchuangNoticeArr = resObj.filter(
            (item) => item.showtype == "tanchuang" || item.showtype == "all"
          );
          this.tanchuangNoticeArr.sort((a,b)=>b.begintime-a.begintime)
          this.tanchuangHeaderArr = [];
          this.tanchuangNoticeArr.forEach((item) => {
            let str = item.info.substr(0, 4);
            this.tanchuangHeaderArr.push(str);
          });
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 查询系统公告
    getSysInfo(showtype) {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        showtype,
        gametype: "lottery",
      };
      this.$http.post("getnoticeinfo", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          resObj.sort((a,b)=>b.begintime-a.begintime)
          this.footerNotice = "";
          resObj.forEach((item, index) => {
            this.footerNotice += "<span class='yuanquan'>●</span>" + item.info + "<span class='kongge'></span>";
          });
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 查询游戏列表
    getGameList() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        official: 0,
      };

      this.$http.post("getgamelist", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);

          let gameArr = obj.GameInfoS;

          let routerArr = [];
          gameArr.forEach((item) => {
            item.pans = [];
            if (item.pana == 1) {
              item.pans.push("A");
            }
            if (item.panb == 1) {
              item.pans.push("B");
            }
            if (item.panc == 1) {
              item.pans.push("C");
            }
            if (item.pand == 1) {
              item.pans.push("D");
            }
            // 本地添加编辑部编辑的值
            item.b = false;

            switch (item.Gametype) {
              case "wxc":
                item.path = "/wuxingcai";
                break;
              case "hk6":
                item.path = "/liuhecai";
                break;
              case "11x5":
                item.path = "/11xuan5";
                break;
              case "k3":
                item.path = "/kuai3";
                break;
              case "ssc":
                item.path =
                  item.clientshowtype == "shengxiao"
                    ? "/huanleshengxiao"
                    : "/shishicai";
                break;
              case "pk10":
                item.path = "/pk10";
                break;
              case "20x8":
                item.path = "/klsf8";

                break;
              case "pcdd":
                item.path = "/pcdd";
                break;
              case "pl3":
                item.path = "/3d";
                break;
              case "pl5":
                item.path = "/shishicai";
                break;
              case "fantan":
                item.path = "/fantan";
                break;
              case "80x20":
                item.path = "/kl8";
                break;
              // bingo
              case "bingo":
                item.path = "/bgc";

                break;
            }
            routerArr.push(item.path);
          });
          this.$store.commit("SETGAMEROUTER", routerArr);
          // 初始化第一个someActive  先判断是否有缓存
          let currentGame = sessionStorage.getItem("currentGame");
          if (currentGame) {
            currentGame = JSON.parse(currentGame);
            this.$store.commit("selectedCurrentGame", currentGame);
            this.$router.push({
              path: currentGame.path,
            });
          } else {
            this.$store.commit("selectedCurrentGame", gameArr[0]);
            this.$router.push({
              path: gameArr[0].path,
            });
          }
          //console.log(gameArr, "游戏列表");
          this.gameData = [...gameArr];
          this.$nextTick(()=>{
            this.handleResize();
          })
         
          // let gameNum = 10;
          // if (gameArr.length <= gameNum) {
          //   this.navData = [...gameArr];
          //   this.routerData = [];
          // } else {
          //   this.navData = gameArr.slice(0, gameNum);
          //   this.routerData = gameArr.slice(gameNum);
          // }
          // localStorage.setItem("routerData", JSON.stringify(this.routerData));
          // localStorage.setItem("navData", JSON.stringify(this.navData));
          // console.log(this.navData, this.routerData, "navDataRouterData");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    logout1() {
      this.$http
        .post("exit", { uuid: this.userInfo.Uuid, sid: this.userInfo.Sid })
        .then((res) => {
          if (res.Status) {
            this.$store.commit("setUserInfo", {});
            sessionStorage.clear();

            let endTime = window.setInterval(function () {}, 10000);
            for (let i = 1; i <= endTime; i++) {
              window.clearInterval(i);
            }
            window.location.href = "/";
          } else {
            this.$message.warning(res.Msg);
          }
        });
    },
    // 退出
    logout() {
      let that = this;
      this.$confirm("您确定要退出吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "top103",
        type: "warning",
      }).then(() => {
        this.$http
          .post("exit", { uuid: this.userInfo.Uuid, sid: this.userInfo.Sid })
          .then((res) => {
            if (res.Status) {
              this.$store.commit("setUserInfo", {});
              sessionStorage.clear();

              let endTime = window.setInterval(function () {}, 10000);
              for (let i = 1; i <= endTime; i++) {
                window.clearInterval(i);
              }
              window.location.href = "/";
            } else {
              this.$message.warning(res.Msg);
            }
          });
      });
    },
    saveGameData(){
      localStorage.setItem('gameData',JSON.stringify(this.gameData))
      this.handleResize();
      this.set_b=false;
    },
    saveRouter() {
      localStorage.setItem("routerData", JSON.stringify(this.routerData));
      localStorage.setItem("navData", JSON.stringify(this.navData));
      this.isEdit = false;
    },
    // 去掉导航游戏
    cutNav(item) {
      if (this.navData.length == 1) {
        alert("最少留一个游戏");
        return;
      }
      let index = this.navData.findIndex((ele) => ele.Cato == item.Cato);
      this.navData.splice(index, 1);
      this.routerData.push(item);
    },
    // 添加游戏
    addNav(item) {
      if (this.navData.length >= this.gameNum) {
        alert("最多添加" + this.gameNum + "个游戏");
        return;
      }
      let index = this.routerData.findIndex((ele) => ele.Cato == item.Cato);
      this.routerData.splice(index, 1);
      this.navData.push(item);
    },
    // 取消编辑
    cancelEdit() {
      this.isEdit = false;
      console.log(JSON.parse(localStorage.getItem("navData")));
      this.navData = JSON.parse(localStorage.getItem("navData")) || [];
      this.routerData = JSON.parse(localStorage.getItem("routerData"));
    },
    // 编辑
    editOn() {
      this.isEdit = true;
    },
    // 切换lotActive
    changeLotActive(str,a) {
      this.lotActive = str;
      if(a){
        this.qiuhao_type=a
      }else{
        this.qiuhao_type=''
      }
      //   this.getgameodds();
    },
    // 切换region
    changeRegion(str) {
      this.$store.commit("setPan", str);
    },
    // 切换皮肤
    changeSkin(skin) {
      let skin_home_class = "skin-" + skin;
      this.$store.commit("setSkinColor", skin_home_class);
    },
    // 点击更多里面的游戏
    setMoreActive(item) {
      this.lotActive = 1;
      this.moreCurrentGame = item;
      this.moreCurrentGame = item;
      this.$store.commit("selectedCurrentGame", item);
    },
    setTabActive1(item) {
      this.$router.push(item.path);
    },
    setTabActive(item) {
      // 六福

      if (item.roomeng == "xamlhc") {
        let skin_home_class = "skin-blue";
        this.$store.commit("setSkinColor", skin_home_class);
      } else if (item.roomeng == "hk6") {
        let skin_home_class = "skin-red";
        this.$store.commit("setSkinColor", skin_home_class);
      } else if (item.roomeng == "amlh") {
        let skin_home_class = "skin-brown";
        this.$store.commit("setSkinColor", skin_home_class);
      }

      this.lotActive = 1;
      this.moreCurrentGame = {};
      // this.tabActive = num;
      // 设置当前选中的游戏

      this.$store.commit("selectedCurrentGame", item);
      this.$router.push(item.path);
    },
  },
};
</script>
<style>
.balls_bottom.sda{
  display: inline-block!important;
}
.balls_bottom.sda p{
  display: inline-block!important;
  width: 24px!important;
}
.balls_bottom.sda p:nth-of-type(4){
  margin-right: 12px!important;
}
.aili p:nth-of-type(4){
  margin-right: 20px!important;
  position: relative;
}
.aili p:nth-of-type(4):after{
  position: absolute;
    left: 30px;
    top: 1px;
    content: "+";
    color: #fff;
}
.kongge{
  display: inline-block;
  width: 80px;
}
.yuanquan{
  position: relative;
  top:1px;
  font-size: 20px;
  font-weight: bold;
}
</style>
<style scoped>

.tuodongp{
  position: absolute;
  left: 10px;
}
.set-footer .queding{
margin-right: 10px;
  display: inline-block;
  width: 60px;
  height:30px;
  line-height:30px;
  text-align: center;
  color:#fff;
  background: #409EFF;
  border-radius: 4px;
}
.set-footer{
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #e4e4e5;
  display: flex;
  justify-content: flex-end;
 align-items: center;
}
.skin-blue .gameseeting  .set-content  li {
  background: #2161b3;
}
.skin-red .gameseeting  .set-content li {
  background: #c2455c;
}

.skin-brown .gameseeting  .set-content  li {
  background: #a18361
}
.gameseeting  .set-content  li {
  margin:8px ;
   
    width: 115px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
}
.set-content{
  display: flex;
  box-sizing: border-box;
  padding-left: 5px;
}
.skin-brown .gameseeting .title {
  background: #dfb55e;
  color: #fff;
}
.skin-red .gameseeting .title {
  background: #c2455c;
  color: #fff;
}
.skin-blue .gameseeting .title {
  background: #2161b3;
  color: #fff;
}
.gameseeting .title span{
  font-size:20px
}
.gameseeting .title{
  height: 42px;
  line-height:42px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
}
.gameseeting{
  position:fixed;
  left: 50%;
  top:103px;
  margin-left:-500px;
  width: 1000px;
  height: 400px;
  background: #fff;
  z-index: 10000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.setting{
  width: 100px;
  min-width:100px;
  height: 38px;
  text-align: right;
  line-height: 38px;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home {
  position: relative;
}
.huixingLogo {
  right: 20px;
  bottom: 30px;
  position: absolute;
  width: 100px;
  height: 55px;
}
</style>
