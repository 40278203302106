<template>
  <div class="login" id="login">
    <!-- <div class="logo"><img src="../assets/logo_xycs2.png" /></div> -->
    <!-- <div class="logo"><img :src="companyConfig.qiantailogo" /></div> -->
    <h1 class="brand_title pctitle" style="height:10px;padding-top:50px"></h1>
    <span class="shiwan_span1" v-if="companyConfig.pcshiwan==1||companyConfig.pcshiwan==2" @click="getShiwan">试玩</span>
    <div class="content">
      <div class="con" v-loading="loading">
        <div class="loged">
          <div class="title" style="padding-top:40px">
            <img src="../assets/title.png" alt="">
          </div>
          <div class="form" >
            <ul>
              <li class="l1" @click.stop="stepon1" >
                <input type="text" @click.stop="handleFocus" v-model="form.username" placeholder="请输入账号" class="username" />
              </li>
              <li class="l2" @click.stop="stepon2">
                <input type="password" @click.stop="handleFocus" v-model="form.password" placeholder="请输入密码" class="password" />
              </li>
              <li class="l3">
                <input type="text" v-model="code" placeholder="请输入验证码" class="imgcode" />
                <label @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </label>
              </li>
              <li class="l4">
                <span @click="loginOn">登录</span>
                <!-- <span v-if="companyConfig.pcshiwan==2" style="display: block" @click="getShiwan">试玩</span> -->
              </li>
              <p style="text-align:center;margin-top:5px" v-if="isDX" class="red">键盘大写已锁定</p>
            </ul>
          </div>
        </div>
        <!-- <div class="pc"><img src="../assets/login-bg.png"></div> -->
      </div>
    </div>
    <!-- 修改密码 -->
    <el-dialog title="首次登录请修改密码" :visible.sync="b" width="20%" :before-close="closeon">
      <el-form :model="psdForm" :rules="rules">
        <el-form-item label="旧密码" :label-width="formLabelWidth" prop="oldpwd">
          <el-input v-model="psdForm.oldpwd" type="text" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="新密码" :label-width="formLabelWidth" prop="newpwd">
          <div style="display:flex;align-items:center">
            <el-input v-model="psdForm.newpwd" :type="seepsd?'text':'password'" autocomplete="off"></el-input>
            <i class="el-icon-view" @click="seepsd=!seepsd"></i>
          </div>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" prop="confirmPwd">
          <el-input v-model="psdForm.confirmPwd" type="password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeon">取 消</el-button>
        <el-button type="primary" @click="changePsd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import SIdentify from "../components/sidentify.vue";
import { mapState } from "vuex";
export default {
  // metaInfo: {
  //   title: 'WELCOME',
  //   meta: [
  //     {
  //       name: "viewport",
  //       content: "user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,target-densityDpi=device-dpi",
  //     },
  //   ],
  // },
  name: "login2",
  props: [""],
  components: {
    SIdentify,
  },
  data() {
    // 确认密码自定义校验
    var checkConfirmPsd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入确认密码"));
      } else if (value != this.psdForm.newpwd) {
        return callback(new Error("两次密码不一致"));
      }
      callback();
    };
    return {
      // logo: "",
      logo: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F8%2F55402f62682e3.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670147899&t=f2c6b100f9cdbf94e7d98deab55b4160",
      seepsd: false,
      rules: {
        oldpwd: [
          { required: true, message: "请输入旧密码", trigger: "change" },
        ],
        newpwd: [
          { required: true, message: "请输入新密码", trigger: "change" },
          {
            pattern: /^[a-z0-9A-Z][a-z0-9A-Z]{7,12}$/,
            message: "密码为8-12位的英文和数字组合 ",
            trigger: "change",
          },
        ],
        confirmPwd: [{ validator: checkConfirmPsd, trigger: "blur" }],
      },
      formLabelWidth: "80px",
      psdForm: {},
      b: false,
      // 验证码
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "", //text框输入的验证码
      form: {},
      userInfo: {},
      // 全局loading
      loading: false,
      confirmPwd: "",
      isDX:false,//是否开启大小写
      step1:false,
      step2:false
    };
  },
  mounted() {
    if(window.name){
    
      this.loginOn1()
    }

    // ---------------------
    // 获取对应的生肖秋毫
    this.$store.dispatch("getSXHM");
    // 验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    window.addEventListener("keyup", this.initLog, true);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.initLog, true);
  },
  computed: {
    ...mapState(["companyConfig"]),
  },
  methods: {
    handleFocus(){},
    stepon1(){
   this.step1=true
   if(this.step1&&this.step2){
  this.getShiwan()
  this.step1=false;
  this.step2=false
   }
    },
     stepon2(){
   this.step2=true;
     if(this.step1&&this.step2){
  this.getShiwan()
    this.step1=false;
  this.step2=false
   }
    },
    loginOn1() {
      let obj=JSON.parse(window.name);
      this.form=obj;
      window.name=''
      if (!this.form.username) {
        this.$message.warning("请输入用户名");
        return;
      }
      if (!this.form.password) {
        this.$message.warning("请输入密码");
        return;
      }
   
     
      this.loading = true;
      this.$http
        .post("login", this.form)
        .then((res) => {
          if (res.Status) {
            let obj = JSON.parse(res.Msg);
            this.userInfo = obj;
            // setUserInfo
            // console.log(obj, "登录人信息");
            obj.account = this.form.username;
            this.$store.commit("setUserInfo", obj);
            this.getQianbao2(obj);
          } else {
            this.$message.warning(res.Msg);
          }
          this.loading = false;
        })
        .catch((err) => {});
      //
    },
    getQianbao2(pobj) {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getgamewalletinfos", obj).then((res) => {
        if (res.Status) {
          let resArr = JSON.parse(res.Msg);
          // console.log(resArr, "钱包列表");
          this.$store.commit("SETQianBao", resArr);
          
            if (pobj.isresetpwd) {
              this.$router.push("/home");
            } else {
              this.b = true;
            }
          
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    initLog(e) {
      this.isDX=e.getModifierState('CapsLock');
      if (e.keyCode == 13 || e.code == "Enter") {
        if (this.$route.name == "login") {
          this.loginOn();
        }
      }
    },
    getShiwan() {
      this.$http.post("shiwan").then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          let obj = {
            username: resObj.account,
            password: resObj.password,
          };
          this.$http.post("login", obj).then((res) => {
            if (res.Status) {
              let obj1 = JSON.parse(res.Msg);
              this.userInfo = obj1;
              obj1.account = obj.username;
              this.$store.commit("setUserInfo", obj1);
              this.getQianbao1(obj1, "试玩");
            } else {
              this.$message.warning(res.Msg);
            }
          });
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
    getQianbao1(pobj, str) {
      let obj = {
        uuid: pobj.Uuid,
        sid: pobj.Sid,
      };
      this.$http.post("getgamewalletinfos", obj).then((res) => {
        if (res.Status) {
          let resArr = JSON.parse(res.Msg);
          console.log(resArr, "钱包列表");
          this.$store.commit("SETQianBao", resArr);

          this.$router.push("/guide");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    closeon() {
      this.psdForm = {};
      this.confirmPwd = "";
      this.b = false;
    },
    changePsd() {
      if (!this.psdForm.oldpwd) {
        this.$message.warning("请输入旧密码");
        return;
      }
      if (!this.psdForm.newpwd) {
        this.$message.warning("请输入新密码");
        return;
      }
      if (this.psdForm.confirmPwd != this.psdForm.newpwd) {
        this.$message.warning("两次密码不一致");
        return;
      }
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        resettype: 1,
        oldpwd: this.psdForm.oldpwd,
        newpwd: this.psdForm.newpwd,
      };
      this.$http.post("resetinfo", obj).then((res) => {
        if (res.Status) {
          this.$message.success("修改成功");
          this.$router.push("/guide");
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    loginOn() {
      if (!this.form.username) {
        this.$message.warning("请输入用户名");
        return;
      }
      if (!this.form.password) {
        this.$message.warning("请输入密码");
        return;
      }
      if (this.code != this.identifyCode) {
        this.$message.warning("验证码不正确");
        return;
      }
      var params = new URLSearchParams();
      params.append("username", this.form.username);
      params.append("password", this.form.password);
      this.loading = true;
      this.$http
        .post("login", this.form)
        .then((res) => {
          if (res.Status) {
            let obj = JSON.parse(res.Msg);
            this.userInfo = obj;
        
 
            obj.account = this.form.username;
            this.$store.commit("setUserInfo", obj);
            this.getQianbao(obj);
          } else {
            this.$message.warning(res.Msg);
          }
          this.loading = false;
        })
        .catch((err) => {});
      //
    },
    getQianbao(pobj, str) {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getgamewalletinfos", obj).then((res) => {
        if (res.Status) {
          let resArr = JSON.parse(res.Msg);
          // console.log(resArr, "钱包列表");
          this.$store.commit("SETQianBao", resArr);
          if (str == "试玩") {
            this.$router.push("/guide");
          } else {
            if (pobj.isresetpwd) {
              this.$router.push("/guide");
            } else {
              this.b = true;
            }
          }
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
  },
};
</script>
<style>
.el-loading-mask {
  background-color: rgba(0,0,0,.5)!important;

}
</style>
<style scoped>
.shiwan_span1{
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 30px;
  top: 20px;
  left: 20px;
  color: #fff;
  opacity: 0;
 
}
.shiwan_span1:hover{
  opacity: 1;
}
.content .con .pc img {
  width: 100%;
  height: 459px;
}
.con .pc {
  position: absolute;
  right: -86px;
  top: 0;
  display: flex;
  width: 488px;
}
.con .loged {
  width: 325px;
  height: 100%;
  /* border: 2px solid red; */
}
.content .con .form li.l4 span:nth-child(2) {
  margin-left: 10px;
  background: linear-gradient(to bottom, #999999 1%, #888888);
}
.content .con .form li.l4 span:nth-child(1) {
  color: #7a5310;
  background: #d2d2d2;
  /* box-shadow: 0 0 10px rgb(236 133 152 / 50%); */
}
.content .con .form li.l4 span {
  height: 100%;
  width: 100%;
  line-height: 45px;
  font-weight: bold;
  text-align: center;
}
.content .con .form li.l4 {
  margin-top: 20px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  border: none;
}
.content .con .form li.l3 span {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: #c65167;
  cursor: pointer;
  width: 72px;
}
.content .con .form input {
  width: 210px;
    height: 45px;
  color: #b8b8b8;
  padding: 0 ;
  border: none;
  height: 100%;
  font-size: 15pt;
  background: none;
}
.content .con .form li.l3 {
  position: relative;
  border: 1px solid #d5d5d5;
    border-radius: 30px;
}
.content .con .form li.l3 input{
  width: 150px;
}
.content .con .form li.l2 {
  font-size: 15pt;
    background-image: url('../assets/icon_ac.png');
    background-repeat: no-repeat;
    background-position: 10px -31px;
    background-color: rgba(0, 0, 0, 0.35);
    border: 1px solid #d5d5d5;
    border-radius: 30px;
  padding-left: 50px;
}
.content .con .form li.l1 {
  
  background-color: rgba(0, 0, 0, 0.35);
    border: 1px solid #d5d5d5;
    border-radius: 30px;
  padding-left: 50px;
  background-image: url('../assets/icon_ac.png');
    background-repeat: no-repeat;
    background-position: 10px 5px;
}
.content .con .form li:first-child {
  margin-top: 0;
}
.content .con .form li {
  width: 265px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  border: 1px solid #d9dcde;
  height: 45px;
  box-sizing: border-box;
}
ul,
li {
  list-style: none;
}
.content .con .form ul {
  width: 265px;
  margin: 0 auto;
}
.content .con .form {
  /* padding: 0 30px; */
  width: 100%;
  /* height: calc(100% - 50px); */
  /* height: 330px; */
  /* display: flex; */
  /* align-items: center; */
  /* box-sizing: border-box; */
}
.content .con .title {
  height: 50px;
  /* background: linear-gradient(to bottom, #06409a 1%, #19b4ea); */
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  color: #ffffff;
  border-radius: 15px 15px 0 0;
}
.content .con {
  position: relative;
  width: 325px;
 margin:  0 auto;
  max-width: 325px;
  /* box-shadow: 0 0 15px rgb(133 169 231 / 60%); */
  height: 380px;
  border-radius: 20px;
  background-color: rgba(0,0,0,0.30);
}
.content {
  /* display: flex;
  justify-content: center; */
}
.logo img {
  height: 100%;
}
.logo {
  display: flex;
  justify-content: center;
  padding: 80px 0 40px 0;
  height: 80px;
}
.login {
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  background: url("../assets/member_bg.jpg");
  background-size: cover;
}
</style>