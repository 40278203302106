<template>
  <div class="popup" v-if="xzVisible">
    <div class="popup-main show" v-loading="loading">
      <div class="popup-title">
        <span>请确认投注信息</span>
        <span class="or-tit">
            <span>{{currentGame.Gamename}}</span>
            <span>共{{dataSource.length}}注,合计:{{total}}</span>
          </span>
        <div>
          <i @click="close" class="van-icon van-icon-cross">x</i>
        </div>
      </div>
      <div class="popup-content">
        <div class="ordernote">
         

          <div class="or-head">
            <span>项目</span>
            <span>赔率</span>
            <span>下注金额</span>
          </div>
          <div class="or-list scroll">
            <ul v-for="(item, index) in dataSource" :key="index">
              <li>
                <span>{{item.title}} &nbsp;&nbsp; {{item.label}}&nbsp;&nbsp; <em></em> </span>
              </li>
              <li>{{item.Odds}}</li>
              <li>
                <input type="number" v-model="item.money" />
                <i @click="deleteaCurremt(index)" class="van-icon van-icon-cross">x</i>
              </li>
            </ul>
          </div>
          <div class="ore-unite">
            <span>统一金额设置:</span>
            <input type="text" @input="xzAmountChange" />
          </div>
        </div>
      </div>
      <div class="popup-btn">
        <span @click="submitForm" v-if="cansubmit">下注</span>
        <span @click="close">取消</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {md55} from './mixins/md5.js';
 
 
 
export default {
  name: "",
  props: ["list", "visible"],
  data() {
    return {
      // 合计 元
      total: 0,
      cansubmit: false,
      loading: false,
      token:'',
      timestamp:'',
    };
  },
    
  components: {},
  created() {},
  watch: {
    dataSource: {
      immediate: true,
      deep: true,
      handler(arr) {
        // console.log(arr, "haha");
        this.total = 0;
        arr.forEach((item) => {
          this.total += Number(item.money);
        });
      },
    },
    xzVisible(val) {
       
      if (val) {
        var timestamp1 = Date.now();
      this.timestamp =Math.floor(timestamp1 / 1000);
      let tokenstr=this.userInfo.Uuid+this.userInfo.Sid+this.timestamp+this.qwer
     
      this.token=md55(tokenstr)
        this.cansubmit = true;
        window.xztime = setTimeout(() => {
          this.cansubmit = true;
          window.clearTimeout(window.xztime);
        }, 300);
      }
    },
    xzAmount(val) {
      this.dataSource.forEach((element) => {
        element.money = val;
      });
      this.$forceUpdate();
    },
  },
  computed: {
    dataSource() {
      return [...this.xzList];
    },
    ...mapState([
      'qwer',
      "xzAmount",
      "xzList",
      "xzVisible",
      "userInfo",
      "currentGame",
      "pan",
      "ISOPEN",
      "companyConfig",
      "quick_c",
    ]),
  },

  mounted() {
    let that = this;
    document.addEventListener("keyup", (e) => {
      if (e.keyCode == 13 || e.code == "Enter") {
        if (
          that.xzList.length > 0 &&
          this.ISOPEN &&
          this.xzVisible &&
          this.cansubmit
        ) {
        
          // this.cansubmit = false;
          that.submitForm();
        }
      }
    });
  },

  methods: {
    ...mapMutations(["showOrHideXZTK", "setXZList", "resetCountAdd"]),
    //
    deleteaCurremt(index) {
      if (this.dataSource.length == 1) {
        this.$message.warning("至少保留一项");
        return;
      }
      // setXZList
      this.dataSource.splice(index, 1);
      this.setXZList([...this.dataSource]);
      this.$forceUpdate();
    },
    xzAmountChange(e) {
      let val = e.target.value;
      this.$store.commit("setXZAmount", val);
    },
    submitForm() {
      if (!this.cansubmit) {
        return;
      }
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        pan:this.pan,
        arrbet: [],
      };
      
      
      this.dataSource.forEach((item) => {
        let obj1 = {
          id: item.id,
          money:Math.trunc(Number(item.money)),
        };
        if (
          this.$route.meta == "自选不中" ||
          this.$route.meta == "连肖连尾" ||
          this.currentGame.Gametype == "pcdd" ||
          this.$route.name == "bgcly" ||
          this.$route.name == "lianma" ||
          this.$route.name == "lianma3" ||
          this.$route.name == "hexiao" ||
          this.$route.name == "zhixuan" ||
          this.$route.name == "lianma8" ||
          this.$route.name == "zhengxiao"
        ) {
          obj1.num = item.num;
          if (item.label == "特码包三") {
            obj1.num = item.num;
          }
        }else{
          if(item.num){
            obj1.num = item.num;
          }
        }

        obj.arrbet.push(obj1);
      });
      obj.arrbet = JSON.stringify(obj.arrbet);
      this.cansubmit = false;
      this.loading = true;
     
   
      obj.token=this.token
      obj.timestamp=this.timestamp
      
 
     
      // if (this.companyConfig.buhuotype == 1) {
      this.$http.post("setneworder", obj).then((res) => {
        if (res.Status) {
          // this.$confirm("注单成功！", "提示", {
          //   confirmButtonText: "确定",
          //   type: "success",
          //   customClass:'top103',
          
          //   showCancelButton: false,
          // });
          let arr = JSON.parse(res.Msg);
          // console.log(arr[0],'真的回不去了')
          this.cansubmit = false;
          this.getAccountMoney();
         
          if(this.currentGame.Gametype=='wxc'){
            arr.forEach(item=>{
            item.label=this.bianXXXX(item)
            })
            this.$store.commit('setwxcArr',[...arr])
          }else if(this.currentGame.Gametype=='pl3'){
          
            arr.forEach(item=>{
            item.label=this.bianpl3(item)
            })
              // 
            this.$store.commit('setwxcArr',[...arr])
          }else{
            this.$store.commit("setLeftName", this.currentGame.Gamename);
          this.$store.commit("setLeftArr", [...arr]);
          }
        
         
        
          this.$store.commit("resetCountAdd");
          this.$store.commit("showOrHideXZTK", false);
          if (!this.quick_c) {
            this.$store.commit("setAmount", "");
          }
        } else {
          this.$confirm(res.Msg, "提示", {
            confirmButtonText: "确定",
            type: "error",
            customClass:'top103',
            showCancelButton: false,
          });
          var timestamp1 = Date.now();
      this.timestamp =Math.floor(timestamp1 / 1000);
      let tokenstr=this.userInfo.Uuid+this.userInfo.Sid+this.timestamp+this.qwer
     
      this.token=md55(tokenstr)
        this.cansubmit = true;
          this.cansubmit = true;

        }

        this.loading = false;
      });

      // this.$message.success("还没开发完成");
    },
    // 获取账户自信
    getAccountMoney() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getmoneyinfo", obj).then((res) => {
        if (res.Status) {
          let resobj = JSON.parse(res.Msg);
          // console.log(resobj, "账户信息");
          this.$store.commit("setAccInfo", resobj);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    close() {
      this.resetCountAdd();
      this.setXZList([]);
      this.showOrHideXZTK(false);
    },
    // 排列3
    bianpl3(item){
      // console.log(item.gamedalei)
      let label=''
      let oldlabel=item.iteminfo;
      let labelArr=oldlabel.split('-');
      // console.log(labelArr)
      if(item.gamedalei=='三字定位'){
      label=labelArr[1]
      }else if(item.gamedalei=='跨度'){
      label=item.iteminfo;
      }else if(item.gamedalei=='两面'){
       label=labelArr[1]
      }else if(item.gamedalei=='总和'){
        label='和值'+labelArr[1]
      }else if(item.gamedalei=='一字定位'){
        label=labelArr[1]
      }else if(item.gamedalei=='二字定位'){
        if(labelArr[0]=='佰拾定位'){
          label=labelArr[1]+'X'
        }
        if(labelArr[0]=='佰个定位'){
          label=labelArr[1][0]+'X'+labelArr[1][1]
        }
        if(labelArr[0]=='拾个定位'){
          label='X'+labelArr[1]
        }
       
      }else if(item.gamedalei=='一字'){
        label= '独胆'+labelArr[1]
      }else if(item.gamedalei=='组选三'||item.gamedalei=='组选六'){
        label=item.iteminfo
      }else if(item.gamedalei=='复式'){
        label=oldlabel
      }
      
     
  
 
    return label
    },
    // 五星彩
    bianXXXX(item){
      // console.log(item.gamedalei)
      let label=''
      let oldlabel=item.iteminfo;
      let labelArr=oldlabel.split('-');
      // console.log(labelArr)
      if(item.gamedalei=='一字定'){
        if(labelArr[0]=='千'){
       label=labelArr[1]+'XXXX'
      }else if(labelArr[0]=='百'){
        label='X'+labelArr[1]+'XXX'
      }else if(labelArr[0]=='拾'){
        label='XX'+labelArr[1]+'XX'
      }else if(labelArr[0]=='个'){
        label='XXX'+labelArr[1]+'X'
      }else if(labelArr[0]=='五'){
        label='XXXX'+labelArr[1]
      }
      }else if(item.gamedalei=='二字定'){
        if(labelArr[0]=='千百'){
          label=labelArr[1]+'XX'
        }else if(labelArr[0]=='千拾'){
          label=labelArr[1][0]+'X'+labelArr[1][1]+'X'
        }else if(labelArr[0]=='千个'){
          label=labelArr[1][0]+'XX'+labelArr[1][1]
        }else if(labelArr[0]=='百个'){
          label='X'+labelArr[1][0]+'X'+labelArr[1][1]
        }else if(labelArr[0]=='百拾'){
          label='X'+labelArr[1][0]+labelArr[1][1]+'X'
        }else if(labelArr[0]=='拾个'){
          label='XX'+labelArr[1][0]+labelArr[1][1]
        }else if(labelArr[0]=='千五'){
          label=labelArr[1][0]+'XXX'+labelArr[1][1]
        }else if(labelArr[0]=='百五'){
          label='X'+labelArr[1][0]+'XX'+labelArr[1][1]
        }else if(labelArr[0]=='拾五'){
          label='XX'+labelArr[1][0]+'X'+labelArr[1][1]
        }else if(labelArr[0]=='个五'){
          label='XXX'+labelArr[1]
        }
      }else if(item.gamedalei=='三字定'){
        if(labelArr[0]=='千百拾'){
          label=labelArr[1]+'X'
        }else if(labelArr[0]=='千百个'){
          label=labelArr[1][0]+labelArr[1][1]+'X'+labelArr[1][2]
        }else if(labelArr[0]=='千拾个'){
          label=labelArr[1][0]+'X'+labelArr[1][1]+labelArr[1][2]
        }else if(labelArr[0]=='百拾个'){
          label='X'+labelArr[1]
        }
      }else if(item.gamedalei=='四字定'){
        label=labelArr[1]
      }else if(item.gamedalei=='二字现'){
        label=labelArr[1]
      }else if(item.gamedalei=='三字现'){
        label=labelArr[1]
      }else if(item.gamedalei=='四字现'){
        label=labelArr[1]
      }
      
     
  
 
    return label
    }
  },
};
</script>
<style scoped>
.ordernote .ore-unite input {
  width: 77px;
  height: 18px;
  padding-left: 3px;
  line-height: 18px;
  border: 1px solid #b0b0b0;
  background: url("../assets/input.gif") no-repeat 0 0;
  border-color: #ccc;
}
.ordernote .ore-unite {
  position: absolute;
  left: 0;
  bottom: 10px;
  right: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 30px;
}
.popup .popup-main .popup-btn span:nth-child(2) {
  margin-left: 10px;
  background: #fff;
  border: 1px solid #ccc;
}
.skin-brown .popup .popup-main .popup-btn span:first-child {
  background: #dfb55e;
}
.skin-red .popup .popup-main .popup-btn span:first-child {
  background: #c2455c;
}
.skin-blue .popup .popup-main .popup-btn span:first-child {
  background: #1e9fff;
}

.popup .popup-main .popup-btn span:first-child {
  color: #fff;
}
.popup .popup-main .popup-btn span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  padding: 0 15px;
  border-radius: 2px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.popup .popup-main .popup-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background: #f3f2f2;
  padding: 10px;
}
.ordernote .or-list ul li:last-child i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 18px;
  width: 22px;
  height: 22px;
  line-height: 19px;
  border-radius: 100px;
  background: #333;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}
.van-icon,
.van-icon:before {
  display: inline-block;
}
.van-icon {
  position: relative;
  font: normal normal normal 14px/1 vant-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.ordernote .or-list ul li:last-child input {
  width: 77px;
  height: 18px;
  padding-left: 3px;
  line-height: 18px;
  border: 1px solid #b0b0b0;
  background: url("../assets/input.gif") no-repeat 0 0;
}
button,
input,
textarea {
  color: inherit;
  font: inherit;
}
.ordernote .or-list ul li:first-child span {
  padding: 0 5px;
}

.ordernote .or-list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ordernote .or-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px dotted #eee;
  padding: 3px 0;
}
.ordernote .or-list {
  height: 300px;
}
.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}
.ordernote .or-head span:nth-child(3),
.ordernote .or-list ul li:nth-child(3) {
  width: 30%;
}
.ordernote .or-head span:nth-child(2),
.ordernote .or-list ul li:nth-child(2) {
  width: 15%;
  color: red;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.ordernote .or-head span:first-child,
.ordernote .or-list ul li:first-child {
  width: 60%;
}

.ordernote .or-head span {
  text-align: center;
}
.ordernote .or-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 25px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    color-stop(50%, #fff),
    color-stop(51%, #f0f0f0),
    to(#f0f0f0)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #f0f0f0 51%, #f0f0f0);
}
.ordernote .or-tit {
  height: 28px;
  line-height: 28px;
  background: #f3f2f2;
  text-align: center;
  font-weight: 700;
}
.ordernote {
  /* position: relative; */
  width: 550px;
}
</style>
