import Vue from "vue";
import Router from "vue-router";
import service from "../tool/api";
// 登录页
import Login from "../views/login";
import Login2 from "../views/login2";
// 主页
import Home from "../views/home";

import Pk10lei from "../components/pk10lei";
import D3d from "../components/3d";
import Kuai3 from "../components/kuai3";
import Xuan5 from "../components/xuan5";
import Nongchang from "../components/nongchang";
import Klsf8 from "../components/klsf8";
import Baobiao from "../components/other/baobiaochaxun";
// / 解决两次访问相同路由地址报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// console.log(window.location.host, 22, window.location);

let routeArr = new Router({
  mode: "hash",
  routes: [
    {
      path: "/demo",
      name: "demo",
      component: () => import("../views/demo"),
    },
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/guide",
      name: "guide",
      component: () => import("../views/guide"),
    },

    {
      path: "/home",
      name: "home",
      component: Home,
      redirect: "/liuhecai",
      children: [
        {
          path: "/shijiebei",
          name: "shijiebei",
          component: () => import("../components/shijiebei"),
        },
        // 六合彩
        {
          path: "/liuhecai",
          name: "liuhecai",
          redirect: "/tema",
          component: () => import("../components/liuhecai"),
          children: [
            {
              path: "/tema",
              name: "tema",
              meta: "特码",
              component: () => import("../components/liuhecai/tema"),
            },
            {
              path: "/zhengma",
              name: "zhengma",
              meta: "正码",
              component: () => import("../components/liuhecai/zhengma"),
            },
            {
              path: "/zhengmate",
              name: "zhengmate",
              meta: "正码特",
              component: () => import("../components/liuhecai/zhengmate"),
            },
            {
              path: "/liangmian",
              name: "liangmian",
              meta: "两面",
              component: () => import("../components/liuhecai/liangmian"),
            },
            {
              path: "/sebo",
              name: "sebo",
              meta: "色波",
              component: () => import("../components/liuhecai/sebo"),
            },
            {
              path: "/texiao",
              name: "texiao",
              meta: "特肖",
              component: () => import("../components/liuhecai/texiao"),
            },
            {
              path: "/hexiao",
              name: "hexiao",
              meta: "合肖",
              component: () => import("../components/liuhecai/hexiao"),
            },
            {
              path: "/tetouwei",
              name: "tetouwei",
              meta: "特头尾",
              component: () => import("../components/liuhecai/tetouwei"),
            },
            {
              path: "/zhengma16",
              name: "zhengma16",
              meta: "正码1-6",
              component: () => import("../components/liuhecai/zhengma16"),
            },
            {
              path: "/zixuanbuzhong",
              name: "zixuanbuzhong",
              meta: "自选不中",
              component: () => import("../components/liuhecai/zixuanbuzhong"),
            },
            {
              path: "/lianxiaolianwei",
              name: "lianxiaolianwei",
              meta: "连肖连尾",
              component: () => import("../components/liuhecai/lianxiaolianwei"),
            },
            {
              path: "/lianma",
              name: "lianma",
              meta: "连码",
              component: () => import("../components/liuhecai/lianma"),
            },
            {
              path: "/yixiaoweishu",
              name: "yixiaoweishu",
              meta: "一肖尾数",
              component: () => import("../components/liuhecai/yixiaoweishu"),
            },
            {
              path: "/zhengxiao",
              name: "zhengxiao",
              meta: "正肖",
              component: () => import("../components/liuhecai/zhengxiao"),
            },
          ],
        },
        // 五星彩
        {
          path: "/wuxingcai",
          name: "wuxingcai",
          redirect: "/yiziding",
          component: () => import("../components/wuxingcai"),
          children: [
            {
              path: "/yiziding",
              name: "yiziding",
              meta: "一字定",
              component: () => import("../components/wuxingcai/yiziding"),
            },
            {
              path: "/erziding",
              name: "erziding",
              meta: "二字定",
              component: () => import("../components/wuxingcai/erziding"),
            },
            {
              path: "/erzidingkx",
              name: "erzidingkx",
              meta: "二字定",
              component: () => import("../components/wuxingcai/erzidingkx"),
            },
            {
              path: "/sanziding",
              name: "sanziding",
              meta: "三字定",
              component: () => import("../components/wuxingcai/sanziding"),
            },
            {
              path: "/siziding",
              name: "siziding",
              meta: "四字定",
              component: () => import("../components/wuxingcai/siziding"),
            },
            {
              path: "/erzixian",
              name: "erzixian",
              meta: "二字现",
              component: () => import("../components/wuxingcai/erzixian"),
            },
            {
              path: "/sanzixian",
              name: "sanzixian",
              meta: "三字现",
              component: () => import("../components/wuxingcai/sanzixian"),
            },
            {
              path: "/sizixian",
              name: "sizixian",
              meta: "四字现",
              component: () => import("../components/wuxingcai/sizixian"),
            },
            {
              path: "/wuweierding",
              name: "wuweierding",
              meta: "五位二定",
              component: () => import("../components/wuxingcai/wuweierding"),
            },
            {
              path: "/kuaida",
              name: "kuaida",
              meta: "快打",
              component: () => import("../components/wuxingcai/kuaida"),
            },
            {
              path: "/kuaiyi",
              name: "kuaiyi",
              meta: "快译",
              component: () => import("../components/wuxingcai/kuaiyi"),
            },
            {
              path: "/plbdb",
              name: "plbdb",
              meta: "赔率变动表",
              component: () => import("../components/wuxingcai/plbdb"),
            },
            {
              path: "/txtdr",
              name: "txtdr",
              meta: "txt导入",
              component: () => import("../components/wuxingcai/txtdr"),
            },
            {
              path: "/qzsm",
              name: "qzsm",
              meta: "全载说明",
              component: () => import("../components/wuxingcai/qzsm"),
            },
          ],
        },
        // 时时彩
        {
          path: "/shishicai",
          name: "shishicai",
          redirect: "/zhenghe",
          component: () => import("../components/shishicai"),
          children: [
            {
              path: "/zhenghe",
              name: "zhengge",
              meta: "整合",
              component: () => import("../components/shishicai/zhenghe"),
            },
            {
              path: "/longhu",
              name: "longhu",
              meta: "龙虎",
              component: () => import("../components/shishicai/longhu"),
            },
            {
              path: "/qita",
              name: "qita",
              meta: "其他",
              component: () => import("../components/shishicai/qita"),
            },
          ],
        },
        // 番滩
        {
          path: "/fantan",
          name: "fantan",
          component: () => import("../components/fantan"),
          redirect:'/fantanzsp',
          children:[
            {
              path:'/fantanzsp',
              name:'fantanzsp',
              meta:'主势盘',
              component:()=>import("../components/fantan/index")
            },
            {
              path:'/fantantema',
              name:'fantantema',
              meta:'番摊特码',
              component:()=>import("../components/fantan/tema")
            },
            {
              path:'/chatgroup',
              name:'chatgroup',
              meta:'聊天室',
              component:()=>import("../components/fantan/chatgroup")
            }
          ]
        },
        // 快乐8
        {
          path: "/kl8",
          name: "kl8",
          redirect: "/liangmianqw",
          component: () => import("../components/kl8"),
          children: [
            {
              path: "/liangmianqw",
              name: "liangmianqw",
              meta: "两面",
              component: () => import("../components/kl8/liangmian"),
            },
            {
              path: "/qwlianying",
              name: "qwlianying",
              meta: "前五连赢",
              component: () => import("../components/kl8/lianying"),
            },
            {
              path: "/zhbswh",
              name: "zhbswh",
              meta: "总和、比数、五行",
              component: () => import("../components/kl8/zhbswh"),
            },
            {
              path: "/kl8zm",
              name: "kl8zm",
              meta: "正码",
              component: () => import("../components/kl8/zhengma"),
            },
          ],
        },
        // 宾果采
        {
          path: "/bgc",
          name: "bgc",
          redirect: "/bgczsp",
          component: () => import("../components/bgc"),
          children: [
            {
              path: "/bgczsp",
              name: "bgczsp",
              meta: "主势盘",
              component: () => import("../components/bgc/zsp"),
            },
            {
              path: "/bgclh",
              name: "bgclh",
              meta: "龙虎",
              component: () => import("../components/bgc/longhu"),
            },
            {
              path: "/bgcly",
              name: "bgcly",
              meta: "连赢",
              component: () => import("../components/bgc/lianying"),
            },
          ],
        },
        // pc蛋蛋
        {
          path: "/pcdd",
          name: "pcdd",
          redirect: "/pcddzsp",
          component: () => import("../components/pcdd"),
          children: [
            {
              path: "/pcddzsp",
              name: "pcddzsp",
              meta: "主势盘",
              component: () => import("../components/pcdd/zsp"),
            },
            {
              path: "/pcdddw",
              name: "pcdddw",
              meta: "定位",
              component: () => import("../components/pcdd/dingwei"),
            },
            {
              path: "/pcddlts",
              name: "pcddlts",
              meta: "聊天室",
              component: () => import("../components/pcdd/chatgroup"),
            },
          ],
        },
        // 超级欢乐生肖
        {
          path: "/huanleshengxiao",
          name: "huanleshengxiao",
          redirect: "/zhengheA",
          component: () => import("../components/huanleshengxiao"),
          children: [
            {
              path: "/zhengheA",
              name: "zhengheA",
              meta: "整合",
              component: () => import("../components/huanleshengxiao/zhenghe"),
            },
            {
              path: "/longhuA",
              name: "longhuA",
              meta: "龙虎",
              component: () => import("../components/huanleshengxiao/longhu"),
            },
            {
              path: "/qitaA",
              name: "qitaA",
              meta: "其他",
              component: () => import("../components/huanleshengxiao/qita"),
            },
          ],
        },
        {
          path: "/pk10",
          name: "pk10",
          redirect: "/liangmian2",
          component: Pk10lei,
          children: [
            {
              path: "/liangmian2",
              name: "liangmian2",
              meta: "两面",
              component: () => import("../components/pk10lei/liangmian"),
            },
            {
              path: "/paiming10",
              name: "paiming10",
              meta: "排名1-10",
              component: () => import("../components/pk10lei/paiming10"),
            },
            {
              path: "/guanyazuhe",
              name: "guanyazuhe",
              meta: "冠亚组合",
              component: () => import("../components/pk10lei/guanyazuhe"),
            },
          ],
        },
        {
          path: "/3d",
          name: "3d",
          redirect: "/liangmian3",
          component: D3d,
          children: [
            {
              path: "/liangmian3",
              name: "liangmian3",
              meta: "两面",
              component: () => import("../components/3d/liangmian"),
            },
            {
              path: "/yizi",
              name: "yizi",
              meta: "一字",
              component: () => import("../components/3d/yizi"),
            },
            {
              path: "/erzi",
              name: "erzi",
              meta: "二字",
              component: () => import("../components/3d/erzi"),
            },
            {
              path: "/sanzi",
              name: "sanzi",
              meta: "三字",
              component: () => import("../components/3d/sanzi"),
            },
            {
              path: "/yizidingwei",
              name: "yizidingwei",
              meta: "一字定位",
              component: () => import("../components/3d/yizidingwei"),
            },
              // 排列3 和值
              {
                path: "/plhezhi",
                name: "plhezhi",
                meta: "和值",
                component: () => import("../components/3d/hezhi"),
              },
              // 排列3 跨
              {
                path: "/plkua",
                name: "plkua",
                meta: "跨",
                component: () => import("../components/3d/kua"),
              },
               // 排列3 和值和跨 两面板
               {
                path: "/plhzkua",
                name: "plhzkua",
                meta: "和值",
                component: () => import("../components/3d/hezhikua"),
              },
            // 排列3 一字定 快选
            {
              path: "/yzdkx",
              name: "yzdkx",
              meta: "一字定位",
              component: () => import("../components/3d/yzdkx"),
            },
            // 排列3  二字定 快选
            {
              path: "/erzdkx",
              name: "erzdkx",
              meta: "二字定位",
              component: () => import("../components/3d/erzdkx"),
            },
            // 排列3 三字定 快选
            {
              path: "/szdkx",
              name: "szdkx",
              meta: "三字定位",
              component: () => import("../components/3d/szdkx"),
            },
            // 排列3 快打
            {
              path: "/pl3kd",
              name: "pl3kd",
              meta: "快打",
              component: () => import("../components/3d/kuaida"),
            },
            // 排列3 转码
            {
              path: "/pl3ky",
              name: "pl3ky",
              meta: "转码",
              component: () => import("../components/3d/zhuanma"),
            },
            // 排列3 快译
            {
              path: "/pl3kuaiyi",
              name: "pl3kuaiyi",
              meta: "快译",
              component: () => import("../components/3d/pl3kuaiyi"),
            },
            {
              path: "/erzidingwei",
              name: "erzidingwei",
              meta: "二字定位",
              component: () => import("../components/3d/erzidingwei"),
            },
            {
              path: "/sanzidingwei",
              name: "sanzidingwei",
              meta: "三字定位",
              component: () => import("../components/3d/sanzidingwei"),
            },
          ],
        },
        {
          path: "/kuai3",
          name: "kuai3",
          redirect: "/kuai31",
          component: Kuai3,
          children: [
            {
              path: "/kuai31",
              name: "kuai31",
              meta: "和值红黑杂双面",
              component: () => import("../components/kuai3/kuai31"),
            },
            {
              path: "/kuai32",
              name: "kuai32",
              meta: "三军二字三字组合",
              component: () => import("../components/kuai3/kuai32"),
            },
          ],
        },
        {
          path: "/nongchang",
          name: "nongchang",
          redirect: "/liangmian4",
          component: Nongchang,
          children: [
            {
              path: "/liangmian4",
              name: "liangmian4",
              meta: "两面",
              component: () => import("../components/nongchang/liangmian"),
            },
            {
              path: "/danhao18",
              name: "danhao18",
              meta: "单号1~8",
              component: () => import("../components/nongchang/danhao18"),
            },
            {
              path: "/diyiqiu",
              name: "diyiqiu",
              meta: "第一球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/dierqiu",
              name: "dierqiu",
              meta: "第二球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/disanqiu",
              name: "disanqiu",
              meta: "第三球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/disiqiu",
              name: "diyiqiu",
              meta: "第四球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/diwuqiu",
              name: "diwuqiu",
              meta: "第五球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/diliuqiu",
              name: "diliuqiu",
              meta: "第六球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/diqiqiu",
              name: "diqiqiu",
              meta: "第七球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/dibaqiu",
              name: "dibaqiu",
              meta: "第八球",
              component: () => import("../components/nongchang/diyiqiu"),
            },
            {
              path: "/lianma2",
              name: "lianma2",
              meta: "连码",
              component: () => import("../components/nongchang/lianma"),
            },
            {
              path: "/longhu2",
              name: "longhu2",
              meta: "龙虎",
              component: () => import("../components/nongchang/longhu"),
            },
          ],
        },
        // 快乐十分8
        {
          path: "/klsf8",
          name: "klsf8",
          redirect: "/liangmian8",
          component: Klsf8,
          children: [
            {
              path: "/liangmian8",
              name: "liangmian8",
              meta: "两面",
              component: () => import("../components/klsf8/liangmian"),
            },
            {
              path: "/danhao188",
              name: "danhao188",
              meta: "单号1~8",
              component: () => import("../components/klsf8/danhao18"),
            },
            {
              path: "/diyiqiu8",
              name: "diyiqiu8",
              meta: "第一球",
              component: () => import("../components/klsf8/diyiqiu"),
            },
            {
              path: "/dierqiu8",
              name: "dierqiu8",
              meta: "第二球",
              component: () => import("../components/klsf8/dierqiu"),
            },
            {
              path: "/disanqiu8",
              name: "disanqiu8",
              meta: "第三球",
              component: () => import("../components/klsf8/disanqiu"),
            },
            {
              path: "/disiqiu8",
              name: "diyiqiu8",
              meta: "第四球",
              component: () => import("../components/klsf8/disiqiu"),
            },
            {
              path: "/diwuqiu8",
              name: "diwuqiu8",
              meta: "第五球",
              component: () => import("../components/klsf8/diwuqiu"),
            },
            {
              path: "/diliuqiu8",
              name: "diliuqiu8",
              meta: "第六球",
              component: () => import("../components/klsf8/diliuqiu"),
            },
            {
              path: "/diqiqiu8",
              name: "diqiqiu8",
              meta: "第七球",
              component: () => import("../components/klsf8/diqiqiu"),
            },
            {
              path: "/dibaqiu8",
              name: "dibaqiu8",
              meta: "第八球",
              component: () => import("../components/klsf8/dibaqiu"),
            },
            {
              path: "/lianma8",
              name: "lianma8",
              meta: "连码",
              component: () => import("../components/klsf8/lianma"),
            },
            {
              path: "/longhu8",
              name: "longhu8",
              meta: "龙虎",
              component: () => import("../components/klsf8/longhu"),
            },
          ],
        },
        {
          path: "/11xuan5",
          name: "11xuan5",
          redirect: "/liangmian5",
          component: Xuan5,
          children: [
            {
              path: "/liangmian5",
              name: "liangmian5",
              meta: "两面",
              component: () => import("../components/11xuan5/liangmian"),
            },
            {
              path: "/danhao",
              name: "danhao",
              meta: "单号",
              component: () => import("../components/11xuan5/danhao"),
            },
            {
              path: "/yizhongyi",
              name: "yizhongyi",
              meta: "一中一",
              component: () => import("../components/11xuan5/yizhongyi"),
            },
            {
              path: "/lianma3",
              name: "lianma3",
              meta: "连码",
              component: () => import("../components/11xuan5/lianma"),
            },
            {
              path: "/zhixuan",
              name: "zhixuan",
              meta: "直选",
              component: () => import("../components/11xuan5/zhixuan"),
            },
          ],
        },
        // 报表查询
        {
          path: "/baobiaochaxun",
          name: "baobiaochaxun",
          component: Baobiao,
        },
        // 游戏报表查询
        {
          path: "/gamebb",
          name: "gamebb",
          component: () => import("../components/other/gamebaobiao"),
        },
        // 未结明细
        {
          path: "/wjmx",
          name: "wjmx",
          component: () => import("../components/other/weijiemingxi"),
        },
        // 今日已结
        {
          path: "/jryj",
          name: "jryj",
          component: () => import("../components/other/jinriyijie"),
        },
        // 报表详情
        {
          path: "/baobiao",
          name: "baobiao",
          component: () => import("../components/other/baobiao"),
        },
        // 开奖结果
        {
          path: "/kaijiangjieguo",
          name: "kaijiangjieguo",
          component: () => import("../components/other/kaijiangjieguo"),
          children: [
            {
              path: "/kjlhc",
              name: "kjlhc",
              component: () => import("../components/kaijiang/lhc"),
            },
            {
              path: "/kjpl3",
              name: "kjpl3",
              component: () => import("../components/kaijiang/3d"),
            },
            {
              path: "/kjk3",
              name: "kjk3",
              component: () => import("../components/kaijiang/kuai3"),
            },
            {
              path: "/kjpk10",
              name: "kjpk10",
              component: () => import("../components/kaijiang/pk10"),
            },
            {
              path: "/kj11x5",
              name: "kj11x5",
              component: () => import("../components/kaijiang/syxw"),
            },
            {
              path: "/kjssc",
              name: "kjssc",
              component: () => import("../components/kaijiang/ssc"),
            },
            {
              path:'/kjwxc',
              name:'kjwxc',
              component: () => import("../components/kaijiang/wxc"),
            },
            {
              path: "/kjsx",
              name: "kjsx",
              component: () => import("../components/kaijiang/sx"),
            },
            {
              path: "/kjklsf",
              name: "kjklsf",
              component: () => import("../components/kaijiang/klsf"),
            },
            {
              path: "/kjnc",
              name: "kjnc",
              component: () => import("../components/kaijiang/nongchang"),
            },
            {
              path: "/kjpcdd",
              name: "kjpcdd",
              component: () => import("../components/kaijiang/pcdd"),
            },
            {
              path: "/kjfantan",
              name: "kjfantan",
              component: () => import("../components/kaijiang/fantan"),
            },
            {
              path: "/kjkl8",
              name: "kjkl8",
              component: () => import("../components/kaijiang/kl8"),
            },
            {
              path: "/kjbgc",
              name: "kjbgc",
              component: () => import("../components/kaijiang/bgc"),
            },
          ],
        },
        // 游戏规则
        {
          path: "/gamerule",
          name: "gamerule",
          component: () => import("../components/other/gamerule"),
        },
        // 修改密码
        {
          path: "/xgmm",
          name: "xgmm",
          component: () => import("../components/other/xgmm"),
          // children: [{}],
        },
        // 个人咨询
        {
          path: "/grzx",
          name: "grzx",
          component: () => import("../components/other/grzx"),
        },
        // 更多公告
        {
          path: "/morelist",
          name: "moreList",
          component: () => import("../components/other/zixunlist"),
        },
        // ------
      ],
    },
  ],
});

Vue.use(Router);

export default routeArr;
