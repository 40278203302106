<template>
  <div id="app">
    <router-view></router-view>
    <div :class="skin_home_class">
      <div v-if="resultGoBool" class="layui-layer layui-layer-iframe layui-layer-border resultTrendIfarme" style="z-index: 900; width: 272px; height: 550px; top: 140px; right: 5px;">
        <div class="layui-layer-title" style="cursor: move;">{{currentGame.Gamename}} - 结果走势</div>
        <div class="layui-layer-content">
          <div class="iframe" style="height:507px">

            <div class="result-open scroll">
              <!-- 快乐十分 -->
              <template v-if="currentGame.Gametype=='20x8'&&currentGame.clientshowtype=='20x8'">
                <div class="title">
               
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-ssg spklsf">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li :class="Number(code)<19?'blueball b'+code:'redball b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=10?'blue':'red'" :key="code+dxi">{{Number(code)>10?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- pk10 -->
              <template v-if="currentGame.Gametype=='pk10'">
                <div class="title">
                
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-pks xjssc">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li class="balls_pk">
                          <p :class="'b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></p>
                        </li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=5?'blue':'red'" :key="code+dxi">{{Number(code)>5?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- pl3 -->
              <template v-if="currentGame.Gametype=='pl3'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-f3d pcddopen">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <!-- <li :class="'greenball b'+code" v-for="(code,i) in item.codeArr" :key="code+i">
                          {{code}}
                        </li> -->
                        <template v-for="(code,i) in item.codeArr">
                          <li  v-if="i<3" :class="'ball b'+i" :key="code+i">{{ code }}</li>
                          <li  v-if="i==3" :class="pcddColors[Number(code)]+'ball1'" :key="code+i">{{ code }}</li>
                        </template>
                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=4?'blue':'red'" :key="code+dxi">{{Number(code)>4?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <!-- <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li> -->
                        <li>{{item.codeArr[3]}}</li>
                        <li :class="fontColor[item.dx]">{{item.dx}}</li>
                        <li :class="fontColor[item.ds]">{{item.ds}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- lhc -->
              <template v-if="currentGame.Gametype=='hk6'">

                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>

                </div>
                <div class="list type-lhc lht">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li v-for="(code,i) in item.codeArr" :key="code">
                          <p :class="'blueball b'+code">17</p><span>{{item.sxArr[i]}}</span>
                        </li>

                        <!-- <li class="ballsjia">
                        <p>+</p><span>&nbsp;&nbsp;</span>
                      </li>
                      <li></li>
                      <li>
                        <p class="redball b12">12</p><span>兔</span>
                      </li> -->
                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="code in item.codeArr">
                          <li v-if="Number(code)<49" :class="Number(code)%2==0?'blue':'red'" :key="code">{{Number(code)%2==0?'双':'单'}}</li>
                          <li v-else class="green" :key="code">和</li>
                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="code in item.codeArr">
                          <li v-if="Number(code)<49" :class="Number(code)<=24?'blue':'red'" :key="code">{{Number(code)>24?'大':'小'}}</li>
                          <li v-else class="green" :key="code">和</li>
                        </template>
                      </ul>
                    </div>
                  </div>

                </div>
              </template>
              <!-- ssc  -->
              <template v-if="currentGame.Gametype=='ssc'&&currentGame.clientshowtype=='ssc'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-ssc ss8">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li :class="'lotball b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=5?'blue':'red'" :key="code+dxi">{{Number(code)>5?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- fantan -->
              <template v-if="currentGame.Gametype=='fantan'">
            

                <div class="list " :class="fantanclass[list[0].codeArr.length]">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1" style="margin-left:15px">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <!-- ---------------5 8-------------------------- -->
                    <div class="balls wd3" v-if="item.codeArr.length==5||item.codeArr.length==8">
                      <ul v-if="listTab=='haoma'" style="justify-content: flex-start;flex-wrap:wrap; padding-left:15px;box-sizing:border-box">
                        <template v-for="(code,i) in item.codeArr">
                        <li v-if="showorhidenum5(i)" :class="'lotball b'+code"  :key="code+i"></li>
                      </template>
                        <li style="margin:0 4px"></li>
                        <li > <span class="sumball bold">{{ item.sum }}</span> </li>
                        <li class="bold" style="margin:0 2px">{{ item.dx }}</li>
                        <li class="bold" style="margin:0 2px">{{ item.ds }}</li>
                        <li class="w20" :class="'color'+item.fan">{{ item.fan }}</li>
                      </ul>
                    
        
                    </div>
                    <!-- -------------10------------------------------------- -->
                    <div class="balls wd3" v-if="item.codeArr.length==10">
                      <ul v-if="listTab=='haoma'" style="justify-content: flex-start;flex-wrap:wrap; padding-left:15px;box-sizing:border-box">
                        <li class="balls_pk">
                          <p :class="'b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></p>
                        </li>
                        <li style="margin:0 4px"></li>
                        <li > <span class="sumball bold">{{ item.sum }}</span> </li>
                        <li class="bold" style="margin:0 2px">{{ item.dx }}</li>
                        <li class="bold" style="margin:0 2px">{{ item.ds }}</li>
                        <li class="w20" :class="'color'+item.fan">{{ item.fan }}</li>
                      </ul>
                   
        
                    </div>
                    <!-- -------------3---------------------- -->
                    <div class="balls wd3" v-if="item.codeArr.length==3">
                      <ul v-if="listTab=='haoma'" style="justify-content: flex-start;flex-wrap:wrap; padding-left:15px;box-sizing:border-box">
                        <template v-for="(code,i) in item.codeArr">
                          <li :class="'ball b'+code" :key="code+i"></li>
                        </template>

                        <li style="margin:0 4px"></li>
                        <li > <span class="sumball bold">{{ item.sum }}</span> </li>
                        <li class="bold" style="margin:0 2px">{{ item.dx }}</li>
                        <li class="bold" style="margin:0 2px">{{ item.ds }}</li>
                        <li class="w20" :class="'color'+item.fan">{{ item.fan }}</li>
                      </ul>
                   
        
                    </div>
                    <!-- ---------------------------------- -->
                  </div>
                </div>

              </template>
              <!-- 生肖 -->
              <template v-if="currentGame.Gametype=='ssc'&&currentGame.clientshowtype=='shengxiao'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-ssc yz5f">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li :class="'lotball b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=5?'blue':'red'" :key="code+dxi">{{Number(code)>5?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <template v-if="currentGame.Gametype=='wxc'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                 

                </div>

                <div class="list type-ssc ss8">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li :class="'lotball b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=5?'blue':'red'" :key="code+dxi">{{Number(code)>5?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- 快3  -->
              <template v-if="currentGame.Gametype=='k3'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-s3s lgk3">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li :class="'blueball b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=4?'blue':'red'" :key="code+dxi">{{Number(code)>4?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- 11选5 -->
              <template v-if="currentGame.Gametype=='11x5'">

                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>
                </div>
                <div class="list type-sxg lgx5">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li v-for="(code,i) in item.codeArr" :key="code+i">
                          <p :class="'blueball b'+code"></p>
                        </li>
                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="code in item.codeArr">
                          <li v-if="Number(code)<11" :class="Number(code)%2==0?'blue':'red'" :key="code">{{Number(code)%2==0?'双':'单'}}</li>
                          <li v-else class="green" :key="code">和</li>
                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="code in item.codeArr">
                          <li v-if="Number(code)<11" :class="Number(code)<=5?'blue':'red'" :key="code">{{Number(code)>5?'大':'小'}}</li>
                          <li v-else class="green" :key="code">和</li>
                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <template v-for="(n,index) in item.lutuResult">

                          <li v-if="index<4" :class="fontColor[n]" :key="index">{{n}}</li>

                        </template>

                      </ul>
                    </div>
                  </div>

                </div>
              </template>

              <!-- 快乐十分 -->
              <template v-if="currentGame.Gametype=='20x8'&&currentGame.clientshowtype=='xync'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list type-ssg lgklsf">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <li class="balls_ccq">
                          <p :class="'b'+code" v-for="(code,i) in item.codeArr" :key="code+i"></p>
                        </li>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=10?'blue':'red'" :key="code+dxi">{{Number(code)>10?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <li :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- pc蛋蛋 -->
              <template v-if="currentGame.Gametype=='pcdd'">
                <div class="title">
             
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span>
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list pcddopen">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'">
                        <template v-for="(code,i) in item.codeArr">
                          <li  v-if="i<3" :class="'ball b'+i" :key="code+i">{{ code }}</li>
                          <li  v-if="i==3" :class="pcddColors[Number(code)]+'ball1'" :key="code+i">{{ code }}</li>
                        </template>

                      </ul>
                      <ul v-if="listTab=='danshuang'">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<5?'blue':'red'" :key="code+dxi">{{Number(code)>=5?'大':'小'}}</li>

                        </template>
                      </ul>
                      <ul v-if="listTab=='zonghe'">
                        <!--  :class="'ball b'+code" -->
                        <li>{{item.codeArr[3]}}</li>
                        <li :class="fontColor[item.dx]">{{item.dx}}</li>
                        <li :class="fontColor[item.ds]">{{item.ds}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </template>
              <!-- 快乐8 -->
              <template v-if="currentGame.Gametype=='80x20'">
                <div class="title">
                  <!-- <em class="wd1">期数</em>
                  <em class="wd2">时间</em> -->
                  <span @click="setTab('haoma')" :class="listTab=='haoma'?'selected':''">号码</span>
                  <!-- <span @click="setTab('danshuang')" :class="listTab=='danshuang'?'selected':''">单双</span>
                  <span @click="setTab('daxiao')" :class="listTab=='daxiao'?'selected':''">大小</span> -->
                  <span @click="setTab('zonghe')" :class="listTab=='zonghe'?'selected':''">总和</span>

                </div>

                <div class="list">
                  <div class="li" v-for="item in list" :key="item.Expect">
                    <div class="cycleid wd1">{{item.Expect}}</div>
                    <div class="sdate wd2">{{item.Opentime|substrSome}}</div>
                    <div class="balls wd3">
                      <ul v-if="listTab=='haoma'" style="flex-wrap:wrap">
                        <template v-for="(code,i) in item.codeArr">
                          <li v-if="i<5" style="margin:1px" :class="Number(code)<41?'greyball':'senseball'" :key="code+i">{{code}}</li>
                        </template>

                      </ul>
                      <ul v-if="listTab=='haoma'" style="flex-wrap:wrap">
                        <template v-for="(code,i) in item.codeArr">
                          <li v-if="i<5" style="margin:1px" :key="code+i">{{ fulushouxi(code) }}</li>
                        </template>

                      </ul>
                      <!-- <ul v-if="listTab=='danshuang'" style="flex-wrap:wrap">
                        <template v-for="(code,ci) in item.codeArr">
                          <li :class="Number(code)%2==0?'blue':'red'" :key="code+ci">{{Number(code)%2==0?'双':'单'}}</li>

                        </template>

                      </ul>
                      <ul v-if="listTab=='daxiao'" style="flex-wrap:wrap">
                        <template v-for="(code,dxi) in item.codeArr">
                          <li :class="Number(code)<=40?'blue':'red'" :key="code+dxi">{{Number(code)>40?'大':'小'}}</li>

                        </template>
                      </ul> -->
                      <ul v-if="listTab=='zonghe'">
                        <!--  :class="'ball b'+code" -->
                        <li>{{getqianwuqiusum(item.codeArr)}}</li>
                        <li :class="fontColor[Number(item.codeArr[0])>Number(item.codeArr[4])?'龙':'虎' ]">{{ Number(item.codeArr[0])>Number(item.codeArr[4])?'龙':'虎' }}</li>
                     
                      </ul>
                    </div>
                  </div>
                </div>

              </template>

              <!-- ----- -->
            </div>
            <!-- --------- -->
          </div>
        </div>
        <span class="layui-layer-setwin">
          <a href="javascript:;" @click="closeResult" class="layui-layer-ico layui-layer-close layui-layer-close1">X</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// 六合彩 开7个球
// 时时彩  开5个球
// PK10类  开10个球
// 3D类  开3个球
// 快3类 开3个骰子
// 幸运农场
// 11选5
export default {
  name: "App",
  components: {},
  data() {
    return {
      fantanclass:{
        10:'type-pks xjssc',
        8:'type-ssg spklsf',
        5:'type-ssc ss8',
        4:'type-ssc ss8',
        3:'pcdd sd6'

      },
      listTab: "haoma",
      list: [],
      fontColor: {
        大: "ltred",
        小: "ltblue",
        单: "ltred",
        双: "ltblue",
        和: "ltgreen",
        质: "ltred",
        合: "ltblue",
        龙: "ltred",
        虎: "ltblue",
        蓝: "ltblue",
        红: "ltred",
        绿: "ltgreen",
      },
    };
  },
  computed: {
    ...mapState([
      "boolean",
      "num",
      "skin_home_class",
      "currentGame",
      "resultGoBool",
      "userInfo",
      "sxArr",
      "kk",
      "gameRouters",
      "urlConfig",
    ]),
  },
  watch: {
    kk() {
      this.getList();
    },
    currentGame(val) {
      if (val.Gamename) {
        this.getList();
      }
    },
    // closeResult
    $route: {
      deep: true,
      handler(val) {
        let mathArr = val.matched;
        if (mathArr.length < 3) {
          this.$store.commit("resultGoBool", false);
        } else {
          let b = false;
          mathArr.forEach((math) => {
            let c = this.gameRouters.find((str) => str == math.path);
            if (c) {
              b = true;
            }
          });
          if (!b) {
            this.$store.commit("resultGoBool", false);
          }
        }
        // console.log(val, "111", this.gameRouters);
      },
    },
  },

  beforeCreate() {},
  created() {
    
  
    // if (!this.urlConfig.skintype) {
      
      this.$http.post("geturlconfig").then((res) => {
        if (res.Status) {
          let urlInfo = JSON.parse(res.Msg);

          this.$store.commit("SETURLCONFIG", urlInfo);
          
        } else {
          this.$message.warning(res.Msg);
        }
      });
    // }
  },
  methods: {
    showorhidenum5(i){
      let b=true
      if(this.currentGame.roomeng=='fantan'||this.currentGame.roomeng=='jsfantan'){
        if(i==4){
          b=false
        }
      }
      return b
    },
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone =
        /mobile|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
          info
        );
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    setTab(str) {
      this.listTab = str;
    },
    getList() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 30,
        roomeng: this.currentGame.roomeng,
        gametype:this.currentGame.Gametype
      };

      if (this.currentGame.frequency == "day") {
        let year = new Date().getFullYear();
        obj.begindate = year-1 + "0101";
        obj.enddate = this.splitDate(this.getBeforeDate(0));
      } else {
        // 分钟级
        obj.begindate = this.splitDate(this.getBeforeDate(0));
        obj.enddate = this.splitDate(this.getBeforeDate(0));
      }
      this.$http
        .post("getlotteryhis", obj)
        .then((res) => {
          if (res.Status) {
            let resObj = JSON.parse(res.Msg);
            this.list = resObj.data;
            this.list.forEach((item) => {
              if(item.Opencode.includes("+")){
                let jiaArr=item.Opencode.split('+');
              item.codeArr=jiaArr[0].split(",")
              item.fan=jiaArr[1]
              }else{
                item.codeArr = item.Opencode.split(",");
              }
            
            });
            // 番摊
            if(this.currentGame.Gametype=='fantan'){
              this.list.forEach(item=>{
                if(item.Opencode.includes("+")){
                  let qiansiNum;
                if(this.currentGame.roomeng=='fantan'||this.currentGame.roomeng=='jsfantan'){
                  qiansiNum=4
                }
               let obj=this.fantangameopencode(item.codeArr,qiansiNum)
               item.sum=obj.sum;
               item.ds=Number(item.fan)%2==0?'双':'单'
              //  item.dx=obj.dx
                }else{
                  let sum=0;
                item.codeArr.forEach(ele=>{
                  sum+=Number(ele)
                })
                item.sum=sum;
                let yu = Number(sum) % 4;
              yu = yu == 0 ? 4 : yu;
              item.fan=yu
              item.dx=sum>22?'大':'小'
         item.ds = sum % 2 == 0 ? "双" : "单";
                }
              
              })
            }
            // 快乐8
            if (this.currentGame.Gametype == "80x20") {
              this.list.forEach((item) => {
                if(item.codeArr.length>20){
                  item.codeArr.splice(item.codeArr.length - 1);
                }
               
                let sum = 0;
                item.codeArr.forEach((ele) => {
                  sum += Number(ele);
                });
                item.sum = sum;
                item.ds = sum % 2 == 0 ? "双" : "单";
                if (sum == 810) {
                  item.dx = "和";
                } else if (sum > 810) {
                  item.dx = "大";
                } else {
                  item.dx = "小";
                }
              });
            }
            // pcdd
            if (this.currentGame.Gametype == "pcdd"||this.currentGame.Gametype == "pl3") {
              this.list.forEach((item) => {
                let sum = 0;
                item.codeArr.forEach((num) => {
                  sum += Number(num);
                });

                item.dx = sum > 13 ? "大" : "小";
                item.ds = sum % 2 == 0 ? "双" : "单";
                item.codeArr.push(sum);
              });
           
            }
            // 排列3
            // if (this.currentGame.Gametype == "pl3") {
            //   this.list.forEach((item) => {
            //     item.lutuResult = this.pl3lutu(item.codeArr);
            //   });
            // }
            // pk10
            if (this.currentGame.Gametype == "pk10") {
              this.list.forEach((item) => {
                item.lutuResult = this.pk10lutu(item.codeArr);
              });
            }
            // 11选5
            if (this.currentGame.Gametype == "11x5") {
              this.list.forEach((item) => {
                item.lutuResult = this.syx5lutu(item.codeArr);
              });
            }
            // 快乐十分
            if (this.currentGame.Gametype == "20x8") {
              this.list.forEach((item) => {
                item.lutuResult = this.klsflutu(item.codeArr);
              });
            }
            // 快3
            if (this.currentGame.Gametype == "k3") {
              this.list.forEach((item) => {
                item.lutuResult = this.k3lutu(item.codeArr);
              });
            }
            // 时时彩
            if (this.currentGame.Gametype == "ssc") {
              this.list.forEach((item) => {
                item.lutuResult = this.ssclutu(item.codeArr);
              });
            }
            // 六合彩
            if (this.currentGame.Gametype == "hk6") {
              
              // Opentime
              this.list.forEach((item) => {
          
                item.sxArr = [];
                let nianxiaoItem=this.getNianXiao(item.Opentime)
              
                item.codeArr.forEach((ele) => {
                  nianxiaoItem.sxArr.forEach(ite=>{
                    let hadEle=ite.nums.find(num=>Number(num)==Number(ele))
                    if(hadEle){
                      item.sxArr.push(ite.xiao);
                    }
                  })
                  // let sxItem = this.sxArr.find((item) => item.ball == ele);
                  // item.sxArr.push(sxItem.shengxiao);
                });
                item.lutuResult = this.lhclutu(item.codeArr);
              });
            }
            // ----------

            // console.log(this.list, "结果走势");
          } else {
            this.$message.warning(res.Msg);
          }
        })
        .catch((err) => {});
    },
    closeResult() {
      this.$store.commit("resultGoBool", false);
    },
  },
};
</script>

<style>
@import url('./assets/css/index1.css');
.tdd{
  width: 9.099%;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #b9c2cb;
  border-bottom: 1px solid #b9c2cb;;
}
.yuying{
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100% ;
  height: 120px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: 1px solid #b9c2cb;
}
.bgred1{
  position: relative;
  top: 1px;
display:inline-block;
width: 17px;
height: 17px;
color: #fff;
text-align: center;
background:#e83535;
border-radius: 50%;
box-sizing: border-box;
}
.bgblue1{
  text-align: center;
  position: relative;
  top: 1px;
  display:inline-block;
width: 17px;
height: 17px;
color: #fff;
background:#5252e2;
border-radius: 50%;
box-sizing: border-box;
}
.bgred{
  position: relative;
  top: 1px;
display:inline-block;
width: 17px;
height: 17px;
color: #fff;
/* background:#e83535; */
border: 2px solid #e83535;
border-radius: 50%;
box-sizing: border-box;
}
.bgblue{
  position: relative;
  top: 1px;
  display:inline-block;
width: 17px;
height: 17px;
color: #fff;
/* background:#5252e2; */
border: 2px solid #5252e2;
border-radius: 50%;
box-sizing: border-box;
}
.dxds_item.red span:nth-of-type(2) {
  color: #e83535;
  font-size: 17px;
}
.dxds_item.red span.yuan:nth-of-type(1) {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #fff; 
  background: #e83535;
  border-radius: 50%;
}
.dxds_item.blue span:nth-of-type(2) {
  color: #5252e2;
  font-size: 17px;
}
.dxds_item.blue span.yuan:nth-of-type(1) {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  background: #5252e2;
  border-radius: 50%;
}
 
.dxds_item > span {
  flex: 1;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dxds_item {
  height: 50%;
  width: 100%;
  display: flex;

  font-weight: bold;
}
.dxds_wrap > section.selected {
  background: #fdfdbd;
}
.dxds_wrap > section {
  height: 70px;
  width: 48%;
  background: #f7f7f7;
}
.dxds_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
}
.luzhutitle span.selected {
  color: #de6668;

  background: #fff;
}
.luzhutitle span {
  flex: 1;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.luzhutitle {
  height: 30px;
  display: flex;
  color: #fff;
  background-color: #de6668;
}
.redball1 {
    background: -webkit-linear-gradient(top, #fa7476, #ee0909);
    background: linear-gradient(180deg, #fa7476, #ee0909);
}
.yellowball1 {
    background: -webkit-linear-gradient(top, #eca625, orange);
    background: linear-gradient(180deg, #eca625, orange);
}
.greenball1 {
    background: -webkit-linear-gradient(top, #59e14b, #3ac12c);
    background: linear-gradient(180deg, #59e14b, #3ac12c);
}
.blueball1 {
    background: -webkit-linear-gradient(top, #619cff, #0a5eff);
    background: linear-gradient(180deg, #619cff, #0a5eff);
}
.blueball1, .greenball1,.redball1, .yellowball1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10rem;
    color: #fff;
    font-weight: 700;
    width: 25px;
    height: 25px;
    font-size: 14px;
    box-sizing: border-box;
    /* box-shadow: 0 0 5px white; */
}
.blue{
  color: blue;
}
.tuoduopeng,.print_Wrap,.kuaiyi_page{
  -webkit-touch-callout: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.huaxian{
  border-bottom: 3px solid white!important;
 
}
.touxiangimg{
 
 background-image: url('./assets/touxiang.png');
 background-repeat: no-repeat;
  background-size:auto 40px;
}
.open-result.type-pks.fantan1 ul li.balls_pk p {
    margin-right: 5px;
}
.pcddopen.sd6 .ball:after {
  content: none!important;
}
.w240{
  display: inline-block;
  width: 240px;
}
.skin-red .btnaa .button {
    background: #c2455c;
}
.skin-brown .btnaa .button {
    background: #dfb55e;
}
.skin-blue .btnaa .button {
    background: #2161b3;
}
.btnaa .button {
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}
.btnaa span {
    width: 60px;
}
.btnaa {
   padding-top: 10px;
    display: flex;
    justify-content: space-around;
 
    width: 170px;
}
.roadmap table td{
  font-size: 13px;
}
.tj_item span:nth-of-type(1){
  border-right: 1px solid #e4e4e5;
}
.tj_item span{
  flex: 1;
  height: 26px;
  line-height: 26px;
  text-align: center;
  font-size: 12px;
}
/* .skin-blue .tj_item.selected{
  color: #fff;
  background: #0372c3;
}
.skin-brown .tj_item.selected{
  color: #fff;
  background: #a18361;
}
.skin-red .tj_item.selected{
  color: #fff;
  background: #c2455c
} */
.tj_item.selected{
  /* color: #fff; */
  background: #f4b705
}
.tj_item{
  /* width: 100%; */
  display: flex;
  border: 1px solid #e4e4e5
}
.skin-blue .tjh2{
  background: #0372c3;
}
.skin-brown .tjh2{
  background: #a18361;
}
.skin-red .tjh2{
  background: #c2455c
}
.tjh2{
  height: 30px;
  line-height: 30px;
  text-align: center;
 color: #fff;
 font-size: 14px;
}
.tjli_wrap{
  padding: 2px;
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  background: #fff;
  box-sizing: border-box;
}
.w20{
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  text-align: center;
}
.bold{
  font-weight: bold;
}
.color1{
  color: #fff!important;
  background: #0372c3;
}
.color2{
  color: #fff!important;
  background: #028300;
}
.color3{
  color: #fff!important;
  background: #f79648;
}
.color4{
  color: #fff!important;
  background: #fd0100;
}
.sumball{
  display: inline-block;
  width: 23px;
  height: 23px;
  text-align: center;
  line-height: 23px;
  border: 1px solid red;
  border-radius: 50%;
}
 .fan{
  position: relative;
  top: -3px;
  width: 40px;
  min-width: 40px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  font-family:'Courier New', Courier, monospace
 }
 .shuzi{
  font-size: 29px;
 }
 .tx-1 {
  background-position: -2560px 0;
}

.tx-2 {
  background-position: -2600px 0;
}

.tx-3 {
  background-position: -2640px 0;
}

.tx-4 {
  background-position: -2680px 0;
}

.tx-5 {
  background-position: -2720px 0;
}

.tx-6 {
  background-position: -2760px 0;
}

.tx-7 {
  background-position: -2800px 0;
}

.tx-8 {
  background-position: -2840px 0;
}

.tx-9 {
  background-position: -2880px 0;
}

.tx-10 {
  background-position: -2920px 0;
}

.tx-11 {
  background-position: -2960px 0;
}

.tx-12 {
  background-position: -3000px 0;
}

.tx-13 {
  background-position: -3040px 0;
}

.tx-14 {
  background-position: -3080px 0;
}

.tx-15 {
  background-position: -3120px 0;
}

.tx-16 {
  background-position: -3160px 0;
}

.tx-17 {
  background-position: -3200px 0;
}

.tx-18 {
  background-position: -3240px 0;
}

.tx-19 {
  background-position: -3280px 0;
}

.tx-20 {
  background-position: -3320px 0;
}

.tx-21 {
  background-position: -3360px 0;
}

.tx-22 {
  background-position: -3400px 0;
}

.tx-23 {
  background-position: -3440px 0;
}

.tx-24 {
  background-position: -3480px 0;
}

.tx-25 {
  background-position: -3520px 0;
}

.tx-26 {
  background-position: -3560px 0;
}

.tx-27 {
  background-position: -3600px 0;
}

.tx-28 {
  background-position: -3640px 0;
}

.tx-29 {
  background-position: -3680px 0;
}

.tx-30 {
  background-position: -3720px 0;
}

.tx-31 {
  background-position: -3760px 0;
}

.tx-32 {
  background-position: -3800px 0;
}

.tx-33 {
  background-position: -3840px 0;
}

.tx-34 {
  background-position: -3880px 0;
}

.tx-35 {
  background-position: -3920px 0;
}

.tx-36 {
  background-position: -3960px 0;
}

.tx-37 {
  background-position: -4000px 0;
}

.tx-38 {
  background-position: -4040px 0;
}

.tx-39 {
  background-position: -4080px 0;
}

.tx-40 {
  background-position: -4120px 0;
}

.tx-41 {
  background-position: -4160px 0;
}

.tx-42 {
  background-position: -4200px 0;
}

.tx-43 {
  background-position: -4240px 0;
}

.tx-44 {
  background-position: -4280px 0;
}

.tx-45 {
  background-position: -4320px 0;
}

.tx-46 {
  background-position: -4360px 0;
}

.tx-47 {
  background-position: -4400px 0;
}

.tx-48 {
  background-position: -4440px 0;
}

.tx-49 {
  background-position: -4480px 0;
}

.tx-50 {
  background-position: -4520px 0;
}

.tx-51 {
  background-position: -4560px 0;
}

.tx-52 {
  background-position: -4600px 0;
}

.tx-53 {
  background-position: -4640px 0;
}

.tx-54 {
  background-position: -4680px 0;
}

.tx-55 {
  background-position: -4720px 0;
}

.tx-56 {
  background-position: -4760px 0;
}

.tx-57 {
  background-position: -4800px 0;
}

.tx-58 {
  background-position: -4840px 0;
}

.tx-59 {
  background-position: -4880px 0;
}

.tx-60 {
  background-position: -4920px 0;
}

.tx-61 {
  background-position: -4960px 0;
}

.tx-62 {
  background-position: -5000px 0;
}

.tx-63 {
  background-position: -5040px 0;
}

.tx-64 {
  background-position: -5080px 0;
}

.tx-65 {
  background-position: -5120px 0;
}

.tx-66 {
  background-position: -5160px 0;
}

.tx-67 {
  background-position: -5200px 0;
}

.tx-68 {
  background-position: -5240px 0;
}

.tx-69 {
  background-position: -5280px 0;
}

.tx-70 {
  background-position: -5320px 0;
}

.tx-71 {
  background-position: -5360px 0;
}

.tx-72 {
  background-position: -5400px 0;
}

.tx-73 {
  background-position: -5440px 0;
}

.tx-74 {
  background-position: -5480px 0;
}

.tx-75 {
  background-position: -5520px 0;
}

.tx-76 {
  background-position: -5560px 0;
}

.tx-77 {
  background-position: -5600px 0;
}

.tx-78 {
  background-position: -5640px 0;
}

.tx-79 {
  background-position: -5680px 0;
}

.tx-80 {
  background-position: -5720px 0;
}

.tx-81 {
  background-position: -5760px 0;
}

.tx-82 {
  background-position: -5800px 0;
}

.tx-83 {
  background-position: -5840px 0;
}

.tx-84 {
  background-position: -5880px 0;
}

.tx-85 {
  background-position: -5920px 0;
}

.tx-86 {
  background-position: -5960px 0;
}

.tx-87 {
  background-position: -6000px 0;
}

.tx-88 {
  background-position: -6040px 0;
}

.tx-89 {
  background-position: -6080px 0;
}

.tx-90 {
  background-position: -6120px 0;
}

.tx-91 {
  background-position: -6160px 0;
}

.tx-92 {
  background-position: -6200px 0;
}

.tx-93 {
  background-position: -6240px 0;
}

.tx-94 {
  background-position: -6280px 0;
}

.tx-95 {
  background-position: -6320px 0;
}

.tx-96 {
  background-position: -6360px 0;
}

.tx-97 {
  background-position: -6400px 0;
}

.tx-98 {
  background-position: -6440px 0;
}

.tx-99 {
  background-position: -6480px 0;
}

.tx-100 {
  background-position: -6520px 0;
}

.tx-101 {
  background-position: -6560px 0;
}

.tx-102 {
  background-position: -6600px 0;
}

.tx-103 {
  background-position: -6640px 0;
}

.tx-104 {
  background-position: -6680px 0;
}

 
</style>
  