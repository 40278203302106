let sxAllArr=[
  // {
  //   nianxiao:'虎',
  //   date:20220201,
  //   sxArr:[
  //     { xiao:'虎', nums:['01', 13, 25, 37, 49] },
  //     { xiao:'牛', nums:['02', 14, 26, 38] },
  //     { xiao:'鼠', nums:['03', 15, 27, 39]},
  //     { xiao:'猪', nums:['04', 16, 28, 40]},
  //     {xiao:'狗', nums:['05', 17, 29, 41]},
  //     { xiao:'鸡', nums:['06', 18, 30, 42] },
  //     {xiao:'猴', nums:['07', 19, 31, 43]},
  //     { xiao:'羊', nums:['08', 20, 32, 44] },
  //     { xiao:'马', nums:['09', 21, 33, 45]},
  //     { xiao:'蛇', nums:[10, 22, 34, 46] },
  //     { xiao:'龙', nums:[11, 23, 35, 47]},
  //     { xiao:'兔', nums:[12, 24, 36, 48]},
  //   ]
  //  },
   {
    nianxiao:'兔',
    date:20230122,
    sxArr:[
      { xiao:'兔', nums:['01', 13, 25, 37, 49] },
      { xiao:'虎', nums:['02', 14, 26, 38] },
      { xiao:'牛', nums:['03', 15, 27, 39]},
      { xiao:'鼠', nums:['04', 16, 28, 40]},
      { xiao:'猪', nums:['05', 17, 29, 41]},
      { xiao:'狗', nums:['06', 18, 30, 42] },
      { xiao:'鸡', nums:['07', 19, 31, 43]},
      { xiao:'猴', nums:['08', 20, 32, 44] },
      { xiao:'羊', nums:['09', 21, 33, 45]},
      { xiao:'马', nums:[10, 22, 34, 46] },
      { xiao:'蛇', nums:[11, 23, 35, 47]},
      { xiao:'龙', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'龙',
    date:20240210,
    sxArr:[
      { xiao:'龙', nums:['01', 13, 25, 37, 49] },
      { xiao:'兔', nums:['02', 14, 26, 38] },
      { xiao:'虎', nums:['03', 15, 27, 39]},
      { xiao:'牛', nums:['04', 16, 28, 40]},
      { xiao:'鼠', nums:['05', 17, 29, 41]},
      { xiao:'猪', nums:['06', 18, 30, 42] },
      { xiao:'狗', nums:['07', 19, 31, 43]},
      { xiao:'鸡', nums:['08', 20, 32, 44] },
      { xiao:'猴', nums:['09', 21, 33, 45]},
      { xiao:'羊', nums:[10, 22, 34, 46] },
      { xiao:'马', nums:[11, 23, 35, 47]},
      { xiao:'蛇', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'蛇',
    date:20250129,
    sxArr:[
      { xiao:'蛇', nums:['01', 13, 25, 37, 49] },
      { xiao:'龙', nums:['02', 14, 26, 38] },
      { xiao:'兔', nums:['03', 15, 27, 39]},
      { xiao:'虎', nums:['04', 16, 28, 40]},
      { xiao:'牛', nums:['05', 17, 29, 41]},
      { xiao:'鼠', nums:['06', 18, 30, 42] },
      { xiao:'猪', nums:['07', 19, 31, 43]},
      { xiao:'狗', nums:['08', 20, 32, 44] },
      { xiao:'鸡', nums:['09', 21, 33, 45]},
      { xiao:'猴', nums:[10, 22, 34, 46] },
      { xiao:'羊', nums:[11, 23, 35, 47]},
      { xiao:'马', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'马',
    date:20260217,
    sxArr:[
      { xiao:'马', nums:['01', 13, 25, 37, 49] },
      { xiao:'蛇', nums:['02', 14, 26, 38] },
      { xiao:'龙', nums:['03', 15, 27, 39]},
      { xiao:'兔', nums:['04', 16, 28, 40]},
      { xiao:'虎', nums:['05', 17, 29, 41]},
      { xiao:'牛', nums:['06', 18, 30, 42] },
      { xiao:'鼠', nums:['07', 19, 31, 43]},
      { xiao:'猪', nums:['08', 20, 32, 44] },
      { xiao:'狗', nums:['09', 21, 33, 45]},
      { xiao:'鸡', nums:[10, 22, 34, 46] },
      { xiao:'猴', nums:[11, 23, 35, 47]},
      { xiao:'羊', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'羊',
    date:20270206,
    sxArr:[
      { xiao:'羊', nums:['01', 13, 25, 37, 49] },
      { xiao:'马', nums:['02', 14, 26, 38] },
      { xiao:'蛇', nums:['03', 15, 27, 39]},
      { xiao:'龙', nums:['04', 16, 28, 40]},
      { xiao:'兔', nums:['05', 17, 29, 41]},
      { xiao:'虎', nums:['06', 18, 30, 42] },
      { xiao:'牛', nums:['07', 19, 31, 43]},
      { xiao:'鼠', nums:['08', 20, 32, 44] },
      { xiao:'猪', nums:['09', 21, 33, 45]},
      { xiao:'狗', nums:[10, 22, 34, 46] },
      { xiao:'鸡', nums:[11, 23, 35, 47]},
      { xiao:'猴', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'猴',
    date:20280126,
    sxArr:[
      { xiao:'猴', nums:['01', 13, 25, 37, 49] },
      { xiao:'羊', nums:['02', 14, 26, 38] },
      { xiao:'马', nums:['03', 15, 27, 39]},
      { xiao:'蛇', nums:['04', 16, 28, 40]},
      { xiao:'龙', nums:['05', 17, 29, 41]},
      { xiao:'兔', nums:['06', 18, 30, 42] },
      { xiao:'虎', nums:['07', 19, 31, 43]},
      { xiao:'牛', nums:['08', 20, 32, 44] },
      { xiao:'鼠', nums:['09', 21, 33, 45]},
      { xiao:'猪', nums:[10, 22, 34, 46] },
      { xiao:'狗', nums:[11, 23, 35, 47]},
      { xiao:'鸡', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'鸡',
    date:20290213,
    sxArr:[
      { xiao:'鸡', nums:['01', 13, 25, 37, 49] },
      { xiao:'猴', nums:['02', 14, 26, 38] },
      { xiao:'羊', nums:['03', 15, 27, 39]},
      { xiao:'马', nums:['04', 16, 28, 40]},
      { xiao:'蛇', nums:['05', 17, 29, 41]},
      { xiao:'龙', nums:['06', 18, 30, 42] },
      { xiao:'兔', nums:['07', 19, 31, 43]},
      { xiao:'虎', nums:['08', 20, 32, 44] },
      { xiao:'牛', nums:['09', 21, 33, 45]},
      { xiao:'鼠', nums:[10, 22, 34, 46] },
      { xiao:'猪', nums:[11, 23, 35, 47]},
      { xiao:'狗', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'狗',
    date:20300213,
    sxArr:[
      { xiao:'狗', nums:['01', 13, 25, 37, 49] },
      { xiao:'鸡', nums:['02', 14, 26, 38] },
      { xiao:'猴', nums:['03', 15, 27, 39]},
      { xiao:'羊', nums:['04', 16, 28, 40]},
      { xiao:'马', nums:['05', 17, 29, 41]},
      { xiao:'蛇', nums:['06', 18, 30, 42] },
      { xiao:'龙', nums:['07', 19, 31, 43]},
      { xiao:'兔', nums:['08', 20, 32, 44] },
      { xiao:'虎', nums:['09', 21, 33, 45]},
      { xiao:'牛', nums:[10, 22, 34, 46] },
      { xiao:'鼠', nums:[11, 23, 35, 47]},
      { xiao:'猪', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'猪',
    date:20310123,
    sxArr:[
      { xiao:'猪', nums:['01', 13, 25, 37, 49] },
      { xiao:'狗', nums:['02', 14, 26, 38] },
      { xiao:'鸡', nums:['03', 15, 27, 39]},
      { xiao:'猴', nums:['04', 16, 28, 40]},
      { xiao:'羊', nums:['05', 17, 29, 41]},
      { xiao:'马', nums:['06', 18, 30, 42] },
      { xiao:'蛇', nums:['07', 19, 31, 43]},
      { xiao:'龙', nums:['08', 20, 32, 44] },
      { xiao:'兔', nums:['09', 21, 33, 45]},
      { xiao:'虎', nums:[10, 22, 34, 46] },
      { xiao:'牛', nums:[11, 23, 35, 47]},
      { xiao:'鼠', nums:[12, 24, 36, 48]},
    ]
   },
   {
    nianxiao:'鼠',
    date:20320211,
    sxArr:[
      { xiao:'鼠', nums:['01', 13, 25, 37, 49] },
      { xiao:'猪', nums:['02', 14, 26, 38] },
      { xiao:'狗', nums:['03', 15, 27, 39]},
      { xiao:'鸡', nums:['04', 16, 28, 40]},
      { xiao:'猴', nums:['05', 17, 29, 41]},
      { xiao:'羊', nums:['06', 18, 30, 42] },
      { xiao:'马', nums:['07', 19, 31, 43]},
      { xiao:'蛇', nums:['08', 20, 32, 44] },
      { xiao:'龙', nums:['09', 21, 33, 45]},
      { xiao:'兔', nums:[10, 22, 34, 46] },
      { xiao:'虎', nums:[11, 23, 35, 47]},
      { xiao:'牛', nums:[12, 24, 36, 48]},
    ]
   }
]
export {
  sxAllArr
};
