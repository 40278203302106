<template>
  <div class="inside-os report-os">
    <div class="com-title">
      <span>报表查询</span>
    </div>
    <div class="report">
      <table class="com-table">
        <tr>
          <th width="25%"> 日期 </th>
          <th width="15%"> 笔数 </th>
          <th width="15%"> 下注金额 </th>
          <th width="15%"> 退水 </th>
          <th width="15%"> 结果 </th>
        </tr>
        <tr v-for="(item,index) in list1" :key="item.date">
          <td class="ctr" :class="item.yingkuijieguo>=0?'win':'lost'" v-if="index==0"> {{item.date|substrSome}} </td>
          <td class="ctr" v-if="index>0"> {{item.date|substrSome}} </td>
          <td>{{item.ordernum}}</td>
          <td>{{item.touzhujine}}</td>
          <td>{{item.zhuanshui}}</td>
          <td class="res" :class="item.yingkuijieguo>=0?'win':'lost'">
            <router-link :to="{path:'/gamebb',query:{date:item.date}}" class=""> {{item.yingkuijieguo.toFixed(2)}} </router-link>
          </td>
        </tr>
        <tr class="bot">
          <td class="ctr">总计</td>
          <td>{{heji1.ordernum}}</td>
          <td>{{heji1.touzhujine}}</td>
          <td>{{heji1.zhuanshui}}</td>
          <td class="res" :class="heji1.yingkuijieguo>=0?'win':'lost'"> {{heji1.yingkuijieguo.toFixed(2)}} </td>
        </tr>
      </table>
      <table class="com-table rt-table" v-if="list2.length>0">
        <tr>
          <th width="25%"> 日期 </th>
          <th width="15%"> 笔数 </th>
          <th width="15%"> 下注金额 </th>
          <th width="15%"> 退水 </th>
          <th width="15%"> 结果 </th>
        </tr>
        <tr v-for="item in list2" :key="item.date">

          <td class="ctr"> {{item.date|substrSome}} </td>
          <td>{{item.ordernum}}</td>
          <td>{{item.touzhujine.toFixed(2)}}</td>
          <td>{{item.zhuanshui.toFixed(2)}}</td>
          <td class="res" :class="item.yingkuijieguo>=0?'win':'lost'">
            <router-link :to="{path:'/gamebb',query:{date:item.date}}" class=""> {{item.yingkuijieguo.toFixed(2)}} </router-link>
          </td>
        </tr>
        <tr class="bot">
          <td class="ctr">总计</td>
          <td>{{heji2.ordernum}}</td>
          <td>{{heji2.touzhujine.toFixed(2)}}</td>
          <td>{{heji2.zhuanshui.toFixed(2)}}</td>
          <td class="res" :class="heji2.yingkuijieguo>=0?'win':'lost'"> {{heji2.yingkuijieguo.toFixed(2)}} </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      pages: {
        pagenum: 1,
        pagecount: 20,
      },
      tableList: [],
      list1: [],
      list2: [],
      heji1: {
        ordernum: 0,
        touzhujine: 0,
        zhuanshui: 0,
        yingkuijieguo: 0,
      },
      heji2: {
        ordernum: 0,
        touzhujine: 0,
        zhuanshui: 0,
        yingkuijieguo: 0,
      },
    };
  },

  components: {},
  created() {
    // 获取本周报表
    this.getList1();
    // 获取上周报表
    this.getList2();
  },
  computed: {
    ...mapState(["userInfo"]),
  },

  methods: {
    getList1() {
      let currentWeek = this.getCurrentWeek();
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        begindate: this.splitDate(currentWeek.startDateTime),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http
        .post("getuserlotdaystatistic", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj = JSON.parse(res.Msg);

              if (resObj.length > 0) {
                this.list1 = resObj;
                this.list1.forEach((item) => {
                  this.heji1.ordernum += Number(item.ordernum);
                  this.heji1.touzhujine += Number(item.touzhujine);
                  this.heji1.zhuanshui += Number(item.zhuanshui);
                  this.heji1.yingkuijieguo += Number(item.yingkuijieguo);
                });
              }
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    getList2() {
      let lastWeek = this.getLastWeek();

      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        begindate: this.splitDate(lastWeek.startDateTime),
        enddate: this.splitDate(lastWeek.endDateTime),
      };
      this.$http
        .post("getuserlotdaystatistic", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj = JSON.parse(res.Msg);
              console.log(resObj);
              if (resObj.length > 0) {
                this.list2 = resObj;
                this.list2.forEach((item) => {
                  this.heji2.ordernum += Number(item.ordernum);
                  this.heji2.touzhujine += Number(item.touzhujine);
                  this.heji2.zhuanshui += Number(item.zhuanshui);
                  this.heji2.yingkuijieguo += Number(item.yingkuijieguo);
                });
              }
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    // 获取上周时间段
    getLastWeek(value = null, separate = "-") {
      // 如果为null,则格式化当前时间
      if (!value) value = Number(new Date());
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (value.toString().length == 10) value *= 1000;
      value = +new Date(Number(value));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const lastWeekDate = new Date(value - 7 * one_day);
      const day = lastWeekDate.getDay() === 0 ? 7 : lastWeekDate.getDay(); // 返回1-7,7表示周日
      // 设置时间为上周那天的0点
      lastWeekDate.setHours(0, 0, 0, 0);

      //算出上周开始时间结束时间
      const week_start_time = new Date(
        lastWeekDate.getTime() - (day - 1) * one_day
      );
      const week_end_time = new Date(
        lastWeekDate.getTime() + (7 - day) * one_day
      );

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const startmonth = filters(week_start_time.getMonth() + 1);
      const startDay = filters(week_start_time.getDate());
      const endmonth = filters(week_end_time.getMonth() + 1);
      const endDay = filters(week_end_time.getDate());

      const startDateTime =
        week_start_time.getFullYear() +
        separate +
        startmonth +
        separate +
        startDay;
      const endDateTime =
        week_end_time.getFullYear() + separate + endmonth + separate + endDay;

      return {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };
    },
    // 获取本周时间
    getCurrentWeek(value = null, separate = "-") {
      // 如果为null,则格式化当前时间
      if (!value) value = Number(new Date());
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (value.toString().length == 10) value *= 1000;
      value = +new Date(Number(value));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const weekDate = new Date(value);
      const day = weekDate.getDay() === 0 ? 7 : weekDate.getDay(); // 返回1-7,7表示周日
      // 设置时间为当天的0点
      weekDate.setHours(0, 0, 0, 0);

      //算出本周开始时间结束时间
      const week_start_time = new Date(
        weekDate.getTime() - (day - 1) * one_day
      );
      const week_end_time = new Date(weekDate.getTime() + (7 - day) * one_day);

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const startmonth = filters(week_start_time.getMonth() + 1);
      const startDay = filters(week_start_time.getDate());
      const endmonth = filters(week_end_time.getMonth() + 1);
      const endDay = filters(week_end_time.getDate());

      const startDateTime =
        week_start_time.getFullYear() +
        separate +
        startmonth +
        separate +
        startDay;
      const endDateTime =
        week_end_time.getFullYear() + separate + endmonth + separate + endDay;
      return {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };
    },
  },
};
</script>
<style  scoped>
.inside-os.report-os .report .com-table.rt-table {
  border-top: none;
}
a {
  text-decoration: none;
}
.inside-os.report-os .report .com-table td.win,
.inside-os.report-os .report .com-table td.win a {
  color: #2836f4;
}
.inside-os.report-os .report .com-table td.lost,
.inside-os.report-os .report .com-table td.lost a {
  color: red;
}
.inside-os.report-os .report .com-table td.ctr {
  padding: 4px 0;
  text-align: center;
}

.inside-os.report-os .report .com-table td {
  padding: 4px 10px 4px 0;
  text-align: right;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table {
  width: 100%;
}
.inside-os.report-os .report {
  margin-top: 5px;
}
</style>