<template>
  <div style="height:100%">
    
    <!--sg版  -->
    <Login1 v-if="urlConfig.skintype==1" />
    <!-- 科技版 -->
    <Login3 v-if="urlConfig.skintype==2" />
      
    <!-- 红色赛车版 -->
    <Login2 v-if="urlConfig.skintype==3" />
      <!-- 商务演示 -->
      <Login4 v-if="urlConfig.skintype==4" />
    <!-- 摩登少女 -->
    <Login5  v-if="urlConfig.skintype==5"  />
    <!-- 上海bg -->
    <Login6  v-if="urlConfig.skintype==6" />
    <!-- 自用 -->
    <Login7 v-if="urlConfig.skintype==7" />
  
  </div>
</template>

<script>
import { mapState } from "vuex";
import Login1 from "./login1";
import Login2 from "./login2";
import Login3 from "./login3";
import Login4 from './login4.vue'
import Login5 from './login5.vue'
import Login6 from './login6.vue'
import Login7 from './login7.vue'
export default {
  name: "",
  props: [""],
  data() {
    return {
      a: "",
    };
  },
  // urlConfig
  computed: {
    ...mapState(["urlConfig"]),
  },

  components: {
    Login1,
    Login2,
    Login3,
    Login4,
    Login5,
    Login6,
    Login7
  },
  created() {
    // this.urlConfig.skintype=0
    // setTimeout(() => {
    //   this.urlConfig.skintype=6
    //  }, 3000);
  },

  mounted() {},

  methods: {},
};
</script>
<style  scoped>
 
</style>