import Vue from "vue";
import Vuex from "vuex";
import service from "../tool/api";
import { Message } from "element-ui";
 
Vue.use(Vuex);

// 数据全局管理的值
const state = {
  
  currentQianbao:{},
  bsg: 1,
  wwed: "qianwu",
  qbsg: "qianbai",
  zmtActive: "1",
  tmActive: "A",
  lhActive: "A",
  zxActive: {},
  // 所有游戏path
  gameRouters: [],
  // --------------------
  currentroomeng: "",
  currentGametype: "",
  currentFrequency: "",
  currentName: "",
  currentroomid: "",
  // -----------------
  zezhaocount: 0,
  quzezhaocount: 0,
  yanzheng: false,
  ltcount: 0,
  qianbaoList: sessionStorage.getItem("qianbao")
    ? JSON.parse(sessionStorage.getItem("qianbao"))
    : [],
  // 音频记录
  audio: 0,
  // 生效对应的球号
  sxArr: [],
  ballArr: [],
 
  ballAllArr:[],
  ISOPEN: false,
  // 封盘标识
  closeCount: 0,
  // 开盘表示
  openCount: 0,
  // 账户信息
  accInfo: {},
  leftName: "",
  // 下注后左下边显示
  leftArr: [],
  wxcArr:[],
  // 开奖结果日期
  kjDate: "",
  //热长龙信息
  rclData: [],
  // 冷长龙信息
  lclData: [],
  // 赔率信息
  oddData: [],
  updateOdds: [],
  // 提交弹框 需要的值  bengin
  xzList: [],
  xzVisible: false,
  // 下注统一金额设置
  xzAmount: "",
  // 提交弹框 需要的值  end
  // 重置按钮  计数标识
  resetCount: 1,
  boolean: true,
  num: 123,
  token: "",
  // 预设金额
  amount: "",
  // 是否启用快选金额
  quick_b: Number(sessionStorage.getItem("quick_b")) || 0,
  // 是否启用快选金额 2
  quick_c: Number(sessionStorage.getItem("quick_c")) || 0,
  // 快选金额 数据
  quickSelectedData: sessionStorage.getItem("quickSelectedData")
    ? JSON.parse(sessionStorage.getItem("quickSelectedData"))
    : [
        { num: "10" },
        { num: "20" },
        { num: "50" },
        { num: "100" },
        { num: "500" },
        { num: "1000" },
      ],
  // 用户信息
  userInfo: {},
  // 当前选中的游戏
  currentGame: sessionStorage.getItem("currentGame")
    ? JSON.parse(sessionStorage.getItem("currentGame"))
    : {},
  // 三种背景颜色
  skin_home_class: "skin-blue",
  // 结果走势 显示与隐藏
  resultGoBool: false,
  // Pan
  pan: sessionStorage.getItem("pan") || "A",
  panArr: [],
  kk: 0,
  companyConfig: localStorage.getItem("companyConfig")
    ? JSON.parse(localStorage.getItem("companyConfig"))
    : {},
    urlConfig: localStorage.getItem("urlConfig")
    ? JSON.parse(localStorage.getItem("urlConfig"))
    : {},
    jspanOpen:false,
    hasScrollbar:false,
    hltjlislt:[],//货量统计列表  未结或已结
    hltjgame:{},//货量统计选中的当前游戏
    searchkjcount:0,
    qwer:'WEOROCBS',
    zl_zb:1,//资料 1   账变 2
    zbdate:''
};

let userInfo = sessionStorage.getItem("userInfo");
// console.log(userInfo);
if (userInfo) {
  state.userInfo = JSON.parse(userInfo);
}
// 修改数据的方式 this.$store.commit('changeBool')
const mutations = {
  SETZBDATE(state,str){
    state.zbdate=str
  },
  SETZLORZB(state,num){
    state.zl_zb=num
  },
  SETCURRENTQIANBAO(state,obj){
    state.currentQianbao=obj
  },
  SETSEARCHKJCOUNTJIA(state){
    state.searchkjcount++
  },
  SETHLTJGAME(state,obj){
    state.hltjgame=obj;
  },
  SETHLTJLIST(state,arr){
    state.hltjlislt=arr;
  },
  SETSCROLLBAR(state,b){
    state.hasScrollbar=b
  },
  SETJSPANOPEN(state,b){
    state.jspanOpen=b;
  },
  SETQBSGACTIVE(state, str) {
    state.qbsg = str;
  },
  SETWWEDACTIVE(state, str) {
    state.wwed = str;
  },
  SETBSGACTIVE(state, str) {
    state.bsg = str;
  },
  SETZMTACTIVE(state, str) {
    state.zmtActive = str;
  },
  SETZHIXUANACTIVE(state, obj) {
    state.zxActive = obj;
  },
  SETTMACTIVE(state, str) {
    state.tmActive = str;
  },
  SETLHACTIVE(state, str) {
    state.lhActive = str;
  },
  SETGAMEROUTER(state, arr) {
    state.gameRouters = arr;
  },
  SETROOMENG(state, obj) {
    state.currentroomid = obj.roomid;
    state.currentroomeng = obj.roomeng;
    state.currentGametype = obj.Gametype;
    state.currentFrequency = obj.frequency;
    state.currentName = obj.Gamename;
  },
  CUTZEZHAO(state) {
    state.quzezhaocount++;
  },
  SETZEZHAO(state) {
    state.zezhaocount++;
  },
  // 验证弹框
  SETYANZHENG(state, b) {
    state.yanzheng = b;
  },
  KKK(state) {
    state.kk++;
  },
  UPDATELUTU(state) {
    state.ltcount++;
  },
  SETCOMPANYCONFIG(state, obj) {
    state.companyConfig = obj;
    localStorage.setItem("companyConfig", JSON.stringify(obj));
  },
  SETURLCONFIG(state,obj){
    state.urlConfig=obj;
    localStorage.setItem('urlConfig',JSON.stringify(obj))
  },
  SETQianBao(state, arr) {
    state.qianbaoList = arr;
    sessionStorage.setItem("qianbao", JSON.stringify(arr));
  },
  SETISOPEN(state, b) {
    state.ISOPEN = b;
  },
  // 提醒刷新开奖号码
  emitAudio(state) {
    state.audio++;
  },
  // 设置生效Arr
  SETSXARR(state, arr) {
    state.sxArr = arr;
  },
  SETBALLARR(state, arr) {
    state.ballArr = arr;
  },
  // 开盘
  OPENPAN(state) {
    state.openCount++;
    state.ISOPEN = true;
  },
  // 封盘
  CLOSEPAN(state) {
    state.closeCount++;
    state.ISOPEN = false;
  },
  // 设置账户信息
  setAccInfo(state, obj) {
    state.accInfo = obj;
  },
  setLeftName(state, str) {
    state.leftName = str;
  },
  setclearwxcArr(state){
    state.wxcArr=[]
  },
  setwxcArr(state,arr){
    state.wxcArr=state.wxcArr.concat(arr);
  },
  setLeftArr(state, arr) {
    state.leftArr = arr;
  },
  // 修改开奖历史 日期
  SETKJDATE(state, str) {
    state.kjDate = str;
  },
  // 更新热长龙信息
  updateChangRLongData(state, arr) {
    state.rclData = arr;
  },
  // 更新冷场龙信息
  updateChangLLongData(state, arr) {
    state.lclData = arr;
  },
  setOdd(state, arr) {
    state.oddData = arr;
  },
  setupdateOdds(state, arr) {
    state.updateOdds = arr;
  },
  setPanArr(state, arr) {
    state.panArr = arr;
  },
  // 切换盘信息
  setPan(state, pan) {
    state.pan = pan;
    sessionStorage.setItem("pan", pan);
  },
  // 结果走势显示与隐藏
  resultGoBool(state, b) {
    state.resultGoBool = b;
  },
  // 修改三种欸经颜色
  setSkinColor(state, color) {
    state.skin_home_class = color;
  },
  // 修改当前游戏
  selectedCurrentGame(state, game) {
    state.currentGame = game;
    sessionStorage.setItem("currentGame", JSON.stringify(game));
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
  },

  //操作 下注弹框 begin
  setXZList(state, list) {
    state.xzList = [...list];
  },
  showOrHideXZTK(state, b) {
    state.xzVisible = b;
  },
  //操作 下注弹框 end
  // 修改统一金额设置 下注弹框里面的
  setXZAmount(state, val) {
    state.xzAmount = val;
  },
  resetCountAdd(state) {
    state.resetCount++;
  },
  // 修改 amount
  setAmount(state, num) {
    state.amount = num;
  },
  // 修改快选金额数据
  setQSData(state, arr) {
    state.quickSelectedData = arr;
    sessionStorage.setItem("quickSelectedData", JSON.stringify(arr));
  },
  setQuickC(state, b) {
    state.quick_c = b;

    sessionStorage.setItem("quick_c", b);
  },
  // 修改快选金额
  setQuickB(state, b) {
    state.quick_b = b;
    sessionStorage.setItem("quick_b", b);
  },
  changeBool(state) {
    state.boolean = !state.boolean;
  },
};
const actions = {
  // 获取球号对应的生肖
  getSXHM({ commit }) {
    service
      .post("/user/getshengxiaoballs", {
        // uuid: state.userInfo.Uuid,
        // sid: state.userInfo.Sid,
      })
      .then((res) => {
        if (res.Status) {
         
          let RESARR = JSON.parse(res.Msg);
          commit("SETSXARR", RESARR);
          // console.log(RESARR,334)
          let keyObj = {
            鼠: 0,
            牛: 1,
            虎: 2,
            兔: 3,
            龙: 4,
            蛇: 5,
            马: 6,
            羊: 7,
            猴: 8,
            鸡: 9,
            狗: 10,
            猪: 11,
          };
          let arr = [[], [], [], [], [], [], [], [], [], [], [], []];
          RESARR.forEach((item, i) => {
            // console.log(item.ball, keyObj[item.shengxiao]);
            arr[keyObj[item.shengxiao]].push(checkNum(item.ball));
          });
          arr.forEach((item) => {
            item.sort((a, b) => a - b);
          });
          // console.log(arr,357)
          commit("SETBALLARR", arr);
       
        } else {
          Message.error(res.Msg);
        }
      })
      .catch((err) => {});
  },
  // 热长隆信息
  getRCL({ commit }) {
    let obj = {
      uuid: state.userInfo.Uuid,
      sid: state.userInfo.Sid,
      roomeng: state.currentGame.roomeng,
      key: "re",
    };

    service.post("/comgame/getchanglong", obj).then((res) => {
      if (res.Status) {
        let arr = JSON.parse(res.Msg);
        let arr1=[]
        if(state.currentGame.roomeng=='twbingo'){
          let idArr=[5372,5373,5370,5371,5374,5375,5376,5377,21831,21832,21833,21834,5380,5381,5378,5379,5382,5383,5384,5385,21835,21836,21837,21838,
            5388,5389,5386,5387,5390,5391,5392,5393,21839,21840,21841,21842,5396,5397,5394,5395,5398,5399,5400,5401,21843,21844,21845,21846,
            5404,5405,5402,5403,5406,5407,5408,5409,21847,21848,21849,21850,5366,5367,5364,5365,5368,5369,5418,5419]
 
           arr1=arr.filter(item=>idArr.findIndex(eleId=>item.itemid==eleId)!=-1)
        }else{
          arr1=arr
        }
      
        commit("updateChangRLongData", arr1);
      } else {
        Message.error(res.Msg);
      }
    });
  },
    // 五星彩 热长隆信息
    getWXCRCL({ commit }) {
      let obj = {
        uuid: state.userInfo.Uuid,
        sid: state.userInfo.Sid,
        roomeng: state.currentGame.roomeng,
        key: "re",
      };
  
      service.post("/wxcgame/getchanglong", obj).then((res) => {
        if (res.Status) {
          let arr = JSON.parse(res.Msg);
          // console.log(arr, "长隆");
          commit("updateChangRLongData", arr);
        } else {
          Message.error(res.Msg);
        }
      });
    },
      // 五星彩冷长龙信息
  getWXCLCL({ commit }) {
    let obj = {
      uuid: state.userInfo.Uuid,
      sid: state.userInfo.Sid,
      roomeng: state.currentGame.roomeng,
      key: "leng",
    };

    service.post("/wxcgame/getchanglong", obj).then((res) => {
      if (res.Status) {
        let arr = JSON.parse(res.Msg);

        commit("updateChangLLongData", arr);
      } else {
        Message.error(res.Msg);
      }
    });
  },
  // 冷长龙信息
  getLCL({ commit }) {
    let obj = {
      uuid: state.userInfo.Uuid,
      sid: state.userInfo.Sid,
      roomeng: state.currentGame.roomeng,
      key: "leng",
    };

    service.post("/comgame/getchanglong", obj).then((res) => {
      if (res.Status) {
        let arr = JSON.parse(res.Msg);
        let arr1=[]
        if(state.currentGame.roomeng=='twbingo'){
          let idArr=[5372,5373,5370,5371,5374,5375,5376,5377,21831,21832,21833,21834,5380,5381,5378,5379,5382,5383,5384,5385,21835,21836,21837,21838,
            5388,5389,5386,5387,5390,5391,5392,5393,21839,21840,21841,21842,5396,5397,5394,5395,5398,5399,5400,5401,21843,21844,21845,21846,
            5404,5405,5402,5403,5406,5407,5408,5409,21847,21848,21849,21850,5366,5367,5364,5365,5368,5369,5418,5419]
 
           arr1=arr.filter(item=>idArr.findIndex(eleId=>item.itemid==eleId)!=-1)
        }else{
          arr1=arr
        }

        commit("updateChangLLongData", arr1);
      } else {
        Message.error(res.Msg);
      }
    });
  },
};
function checkNum(num) {
  if (num < 10) {
    num = "0" + num;
  }
  return num;
}
const store = new Vuex.Store({
  mutations,
  state,
  actions,
});

export default store;
